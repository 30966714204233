import React, { useContext } from "react";
/* Global state */
import { AuthenticationContext } from "../Context/AuthenticationContext";

const ACLWrapper = ({ children, requiredPermission = null }) => {

    const { permissions } = useContext(AuthenticationContext);

    if (permissions.includes(requiredPermission) || !requiredPermission) {
        return children;
    }
    else {
        return null;
    }
}

export default React.memo(ACLWrapper);