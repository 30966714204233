export const styles = {
    input: {
        display: 'none'
    },
    button: {
        padding: '2px 12px',
        width: 'fit-content',
    },
    typography: {
        marginTop: '6px'
    }
}