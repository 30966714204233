import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { withRouter, NavLink } from 'react-router-dom';
import 'moment-timezone';
/* Material UI */
import Tooltip from '@mui/material/Tooltip';
/* REACT-ICONS */
import { FiArrowRightCircle } from "react-icons/fi";
/* Global state */
import { OrderActionsContext } from '../../../Context/OrderContext';
import { UPDATE_ORDER } from '../../../Context/reducers/orderReducer';
import { UserContext } from '../../../Context/UserContext';
/* Components */
import ConnectionDialog from '../../dialogs/ConnectionDialog/ConnectionDialog';
import CustomButton from '../../lib/CustomButton/CustomButton';
/* Utils */
import { getTokenFromLocalStorage } from '../../../Utils/localStorage';

const customButtonStyle = {
    padding: '2px 12px', 
    width: 'fit-content'
};

const UnitRowButtons = ({ unit, expired }) => {
    
    const history = useHistory();
    const { t } = useTranslation();

    const [ dialogOpen, setDialogOpen ] = useState(false);

    const dispatchOrder = useContext(OrderActionsContext);
    const { user } = useContext(UserContext);

    /**
     * Handle connect in normal and powersave mode
     */
     const handleConnect = () => {
        
        UnitRowButtons.openWindow = null;
        
        /* Normal mode */
        if (unit.pwr_mode === "normal") {
            
            /* Open new tab with app for unit configuration */                       
            const url = `https://remote.bixion.com/?goto=${unit.serial_number}&token=${getTokenFromLocalStorage()}`;

            if (UnitRowButtons.openWindow !== null) {
                UnitRowButtons.openWindow.close();
            }

            let owindow = window.open("", 'Unit', null, false);
            if (owindow != null)
            {
                let html = "";
                html += "<html><head></head><body><form id='formid' method='post' action='" + url +"'>";
                html += "<input type='hidden' name='s' value='" + unit.secure_id + "'/>";
                html += "<input type='hidden' name='vg' value='" + unit.vpn_ip + "'/>";
                html += "</form><script type='text/javascript'>document.getElementById(\"formid\").submit()</script></body></html>";
                owindow.document.write(html);
            }

            UnitRowButtons.openWindow = owindow;
        }
        
        /* Powersave mode */
        else {
            setDialogOpen(true);
        }
    }

    /**
     * Handle order in case of expired unit
     */
     const handleOrder = () => {
        dispatchOrder({
            type: UPDATE_ORDER,
            category: "units",
            orderItem: {
                unit_id: unit.id,
                unit_name: unit.name,
                unit_serial: unit.serial_number,
                services: [
                    {
                        service_name: "Remote management",
                        months: 1,
                    }
                ] 
            }
        })
        history.push('/services/units');
    }

    //console.log("[UnitRowButtons]");

    return (
        <>
            {user.type !== "admin" ?
                <>
                {user.type !== "customer" ? (
                    <CustomButton
                        component={NavLink}
                        to={`unit/${unit.id}/logs`}
                        style={customButtonStyle}
                    >
                        {t('dashboard.unit.button.showLogs')}
                    </CustomButton>
                ) : null}
                &nbsp;
                <Tooltip title={!expired ? t('dashboard.unit.tooltip.connectToTheUnit') : t('dashboard.unit.tooltip.remoteManagementServiceExpiredExtendItByClickingOnTheButton')}>
                    <CustomButton
                        color={!expired ? 'primary' : 'secondary'}
                        endIcon={<FiArrowRightCircle style={{height: 'auto'}} />}
                        onClick={!expired ? handleConnect : handleOrder}
                        style={customButtonStyle}
                    >
                        {!expired ? t('dashboard.unit.button.connect') : t('dashboard.unit.button.order')}
                    </CustomButton>
                </Tooltip>
                </>
                :
                <>
                    {expired ?
                        <Tooltip title={t('dashboard.unit.tooltip.remoteManagementServiceExpiredExtendItByClickingOnTheButton')}>
                            <CustomButton
                                color="secondary"
                                endIcon={<FiArrowRightCircle style={{height: 'auto'}} />}
                                onClick={handleOrder}
                                style={customButtonStyle}
                            >
                                {t('dashboard.unit.button.order')}
                            </CustomButton>
                        </Tooltip>
                        : null
                    }
                    &nbsp;
                    <CustomButton
                        component={NavLink}
                        to={`unit/${unit.id}/logs`}
                        style={customButtonStyle}
                    >
                        {t('dashboard.unit.button.showLogs')}
                    </CustomButton>
                    &nbsp;
                    <Tooltip title={t('dashboard.unit.tooltip.connectToTheUnit')}>
                        <CustomButton
                            endIcon={<FiArrowRightCircle style={{height: 'auto'}} />}
                            onClick={handleConnect}
                            style={customButtonStyle}
                        >
                            {t('dashboard.unit.button.connect')}
                        </CustomButton>
                    </Tooltip>
                </>
            }

            {/* Connection dialog*/}
            <ConnectionDialog 
                open={dialogOpen} 
                handleClose={() => setDialogOpen(false)} 
                unit={{
                    id: unit.id,
                    name: unit.name,
                    secure_id: unit.secure_id,
                    vpn_ip: unit.vpn_ip,
                    serial_number: unit.serial_number,
                }} 
            />
        </>
    );
}

export default React.memo(withRouter(UnitRowButtons));