import React from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
/* Components */
import CustomColorPalette from '../lib/CustomColorPalette/CustomColorPalette';
import CustomTextField from '../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../lib/CustomSelectField/CustomSelectField';
import CustomDropzone from '../lib/CustomDropzone/CustomDropzone';

/* Styles */
const styles = {
    themeColorBox: {
        width: 'calc(100% - 240px)',
        '@media (max-width: 480px)': {
            width: '100%'
        }
    },
};

const CustomizationForm = ({state, setState}) => {

    const { t } = useTranslation();

    /**
     * Handle color change
     * @param {*} color 
     */
     const handleColorChange = (color) => {
        setState({...state, colorPrimary: color})
    }

    /**
     * Handle logo change
     * @param {*} file
     */
     const handleLogoChange = (file) => {
        if (file.length !== 0) {
            setState({...state, logo: file[0]})
        }
    }

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={4} lg={3} xl={2}>
                <CustomDropzone 
                    handleLogoChange={handleLogoChange}
                    handleDelete={() => setState({...state, logo: ""})}
                />
            </Grid>
            
            <Grid item xs={12} md={8} lg={9} xl={10}>
                <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12}>
                        {/* Title */}
                        <CustomTextField 
                            name="title"
                            value={state.title}
                            handleChange={handleChange}
                            label={t('customization.settings.label.title').toUpperCase()}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        {/* Preferred language */}
                        <CustomSelectField
                            name="language"
                            value={state.language}
                            handleChange={handleChange}
                            label={t('customization.settings.label.preferredLanguage').toUpperCase()}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="cs">Čeština</MenuItem>
                        </CustomSelectField>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        {/* Hide services */}
                        <CustomSelectField
                            name="hide_all"
                            value={state.hide_all}
                            handleChange={handleChange}
                            label={t('customization.settings.label.hideServices').toUpperCase()}
                        >
                            <MenuItem value={false}>{t('browsers.settings.label.no')}</MenuItem>
                            <MenuItem value={true}>{t('browsers.settings.label.yes')}</MenuItem>
                        </CustomSelectField>
                    </Grid>

                    <Grid item xs={12} lg={9}>
                        <Grid container alignItems="flex-end" spacing={2} >
                            <Grid item sx={styles.themeColorBox}>
                                {/* Primary color */}
                                <FormLabel style={{fontSize: '12px'}}>{t('customization.settings.label.primaryColor').toUpperCase()}</FormLabel>
                                <Container style={{padding: 0, maxWidth: '100%'}}>
                                    <Typography component="div" style={{ backgroundColor: `${state.colorPrimary}`, height: '56px', borderRadius: '4px', color: "black", fontWeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{state.colorPrimary}</Typography>
                                </Container>
                            </Grid>
                            <Grid item>
                                {/* Color palette */}
                                <CustomColorPalette handleColorChange={handleColorChange} />
                            </Grid>
                        </Grid>                  
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {/* Own color*/}
                        <CustomTextField 
                            name="colorPrimary"
                            value={state.colorPrimary}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.ownColor').toUpperCase()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomizationForm;