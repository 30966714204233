import React from 'react';
/* Material UI */
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const CustomSelectField = ({
    children,
    handleChange,
    label,
    name,
    value,
    ...otherProps
}) => (
    <FormControl
        fullWidth
        variant="outlined"
    >
        <InputLabel
            id={name}
            shrink
        >
            {label.toUpperCase()}
        </InputLabel>
        <Select
            displayEmpty
            id={name}
            label={label.toUpperCase()}
            labelId={name}
            name={name}
            notched
            onChange={handleChange}
            value={value}
            {...otherProps}
        >
            {children}
        </Select>
    </FormControl>
);

export default CustomSelectField;