import React from 'react';
import classNames from 'classnames';
/* Material UI */
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

/* Styles */
const styles = {
    iconWrapper: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '4px',
        marginRight: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'primary.main',
        borderRadius: '8px',
    },
    icon: {
        fontSize: '2.5rem',
        color: 'rgba(0,0,0,.75)'
    },
    titleContainer: {
        padding: 0,
        margin: 0
    },
    title: {
        fontWeight: 600,
        '@media (max-width: 480px)': {
            fontSize: '1.75rem'
        }
    },
};


const SummaryHeader = ({ children, title }) => {

    const addIconClassName = child => {
        const className = classNames(styles.icon);
        const props = {className};
        return React.cloneElement(child, props);
    }

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Box sx={styles.iconWrapper}>
                {React.Children.map(children, child => addIconClassName(child))}
            </Box>
            <Container sx={styles.titleContainer}>
                <Typography sx={styles.title} variant="h5" color="secondary">
                    {title}
                </Typography>
            </Container>
        </div>
    );

}

export default SummaryHeader;