import React, {useContext} from 'react'
/* Material UI */
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
/* Global state */
import { DrawerContext } from '../../../../Context/DrawerContext';
/* Styles */
import { styles } from './DrawerToggleButton.styles';

const DrawerToggleButton = () => {

    const { drawerState, toggleDrawer } = useContext(DrawerContext);

    return (
        <IconButton
            aria-label="menu"
            color="inherit"
            onClick={() => toggleDrawer(!drawerState)}
            sx={styles.menuButton}
        >
            <Icon>menu</Icon>
        </IconButton>
    );
};

export default React.memo(DrawerToggleButton);