import React, { useState, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router";
/* Material UI */
import Paper from '@mui/material/Paper';
/* Components */
import ACLWrapper from '../Containers/ACLWrapper';
import UserForm from '../components/Customers/Settings/UserForm';
import UserUnits from '../components/Customers/Settings/UserUnits';
import Buttons from '../components/Customers/Settings/Buttons';
import FormTitle from '../components/Forms/FormTitle';
import Spinner from "../components//lib/CustomSpinner/CustomSpinner";
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    }
};

const CustomerSettings = () => {

    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);
    const [ userState, setUserState ] = useState(null);
    const [ error, setError ] = useState("");
    const [ createNewPassword, setCreateNewPassword ] = useReducer(createNewPassword => !createNewPassword, false);
    let userId = location.pathname.split('/')[3];

    useEffect(() => {
        const onSuccess = (response) => {
            let customer = response.data;
            delete customer.password;
            let unitsId = [];
            
            for (let i = 0; i < customer.units.length; i++) {
                unitsId.push(customer.units[i].id)
            }

            setUserState({
                ...customer,
                units_id: unitsId,
                newPassword: "",
                newPasswordConfirmation: "",
            })
        }

        const onError = (error) => {
            console.log('error in /user/<user-id>', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/user/" + userId, null, onSuccess, onError);
    }, [])

    const handleChange = (event) => {
        setUserState({...userState, [event.target.name]: event.target.value});
    }

    /* Validation of first name, username, email and phone format */
    const validate = () => {
        if (userState.first_name === "") {
            setError("missingFirstName")
            return false;
        }
        
        if (userState.username === ""){
            setError("missingUsername")
            return false;
        }
        
        if (userState.email !== null && userState.email !== "") {
            if (!/\S+@\S+\.\S+/.test(userState.email)) {
                setError("invalidEmailAddress");
                return false;
            }
        }
        
        if (userState.phone !== null && userState.phone !== "") {
            if((!/^\+?[\d]{3}[1-9][0-9]{8}$/.test(userState.phone.replace(/\s+/g, '')))
                && (!/^[0-9]{9}$/.test(userState.phone.replace(/\s+/g, '')))) {
                setError("invalidPhoneNumber");
                return false;
            }
        }
        
        if (createNewPassword) {
            if (userState.newPassword === "") {
                setError("newPasswordMissing");
                return false;
            }
            else if (userState.newPasswordConfirmation === "") {
                setError("newPasswordConfirmationMissing");
                return false;
            }
            else {
                if (userState.newPassword !== userState.newPasswordConfirmation) {
                    setError("newPasswordsNotSame");
                    return false;
                }
            }
        }

        setError("");
        return true;
    }

    /**
     * Add unit to user units
     * @param {*} newUnit 
     */
     const addUnitToState = (newUnit) => {
        let userUnits = [...userState.units];
        let userUnitsIds = [...userState.units_id];
        userUnits.push({"id": newUnit.id, "name": newUnit.name, "serial": newUnit.serial_number});
        userUnitsIds.push(newUnit.id);
        // setSnack({ message: t('snackbar.message.unitAdded'), type: "info", open: true});
        setUserState({...userState, units: userUnits, units_id: userUnitsIds});
    }

    /**
     * Delete unit from user units
     * @param {*} unitId 
     */
    const deleteUnitFromState = (unitId) => {
        let userUnits = [...userState.units];
        let userUnitsIds = [...userState.units_id];
        let removeUnitIndex = userState.units.findIndex(unit => unit.id === unitId);
        let removeUnitIdIndex = userState.units_id.findIndex(unit => unit === unitId);  
        userUnits.splice(removeUnitIndex, 1);
        userUnitsIds.splice(removeUnitIdIndex, 1);
        // setSnack({ message: t('snackbar.message.unitRemoved'), type: "info", open: true});
        setUserState({...userState, units: userUnits, units_id: userUnitsIds});
    }

    /**
     * @param {*} event 
     * @returns 
     */
    const handleSubmit = (event) => {
        
        event.preventDefault();

        if (!validate()) return;  

        const data = {
            ...userState,
            password: createNewPassword ? userState.newPassword : userState.password
        }
    
        const onSuccess = (response) => {
            setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            //console.log("new password", response.data);
            window.history.back();
        }
        const onError = (error) => {
            console.log('error in /user/<user-id>');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/user/" + userState.id, data, onSuccess, onError);
    }

    if (userState !== null) {
        return (
            <ACLWrapper requiredPermission={'ACL_RESELLER_ADMIN'}>
                <form noValidate autoComplete="off">
                    <Paper sx={styles.paper} elevation={3}>
                        
                        {/* Title */}
                        <FormTitle icon="account_circle" title={userState.first_name + " " + userState.last_name} />
                        
                        {/* User form */}
                        <UserForm 
                            customer={userState} 
                            error={error} 
                            handleChange={handleChange}
                            createNewPassword={createNewPassword}
                            setCreateNewPassword={setCreateNewPassword}
                        />

                        <br /><br />

                        {/* User units */}
                        <UserUnits 
                            customer={userState} 
                            addUnit={addUnitToState}
                            deleteUnit={deleteUnitFromState}
                        />

                        {/* Buttons */}
                        <Buttons customer={userState} error={error} handleSubmit={handleSubmit} />

                    </Paper>
                </form>
            </ACLWrapper>
        );
    }
    else
        return <Spinner />;
}

export default CustomerSettings;