import React, { useState, useContext, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import Moment from 'react-moment';
import 'moment-timezone';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
/* Material UI */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
/* Global state */
import { serverTimeState, updateUnitState } from '../../../Context/globalState';
/* Utils */
import { calculateNextWakeupTime, getAccessMode, getTimeInTimezone } from '../../../Utils/PWRModeHandler';
import BackendRequest from '../../../Utils/BackendRequest';
import { UserContext } from '../../../Context/UserContext';

/* Styles */
const styles = {
    timeInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '18px',
        position: 'relative'
    },
    timeInfo: {
        width: '48%'
    },
    switchWrapper: {
        width: '48%',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        right: 0,
        top: '19px'
    },
    switchInnerWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    switch: {
        width: '50px', 
        height: '30px', 
        padding: '9px',
        '& > .MuiButtonBase-root': {
            padding: '5px'
        },
        '& .Mui-disabled+.MuiSwitch-track': {
            backgroundColor: 'black !important',
        },
        '& .Mui-disabled': {
            color: '#bdbdbd !important',
        },
        '& .Mui-checked.Mui-disabled': {
            color: '#bdbdbd !important',
        },
        '@media (max-width: 480px)': {
            width: '44px',
            height: '28px', 
            '& > .MuiButtonBase-root': {
                padding: '6px'
            },
            '& .MuiSwitch-thumb': {
                width: '16px',
                height: '16px',
            }
        }
    },
    typography: {
        fontSize: 14,
        '@media (max-width: 480px)': {
            fontSize: 13
        }
    },
    typographyCaption: {
        textAlign: 'right',
        '@media (max-width: 480px)': {
            fontSize: 11
        }
    }
};

const UnitCardTimeInfo = ({ unit, expired }) => {

    const history = useHistory();
    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    const serverTime = useRecoilValue(serverTimeState);
    const updateUnit = useSetRecoilState(updateUnitState);
    
    const [ switchState, setSwitchState ] = useState(expired ? false : unit.stay_online);
    const accessMode = getAccessMode({pwr_mode: unit.pwr_mode, online: unit.online});

    /**
     * Stay online switch
     * @param {*} event 
     */
    const handleSwitchChange = (event) => {
        
        let stayOnline = !switchState

        const onSuccess = (response) => {
            updateUnit({
                serial_number: unit.serial_number,
                data: {stay_online: stayOnline}
            })
            setSwitchState(stayOnline);
        }
        const onError = (error) => {
            console.log('error in /unit/<unit-id>');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/unit/" + unit.id, {stay_online: stayOnline.toString()}, onSuccess, onError);
    }

    /**
     * Switch state according to websocket change
     */
    useEffect(() => {
        setSwitchState(unit.stay_online); 
    }, [unit.stay_online])

    //console.log("[UnitCardTimeInfo]");

    return (
        <Box sx={styles.timeInfoWrapper}>
            <Box sx={styles.timeInfo}>
                <div>
                    
                    {/* Unit time */}
                    <Typography sx={styles.typography} gutterBottom>
                        {t('dashboard.unit.label.unitTime')}:
                        <br/>
                        {unit.version_hw >= "3.0" ? 
                            <Moment unix format=" DD-MM-YYYY HH:mm" tz={typeof unit.timezone === "undefined" || unit.timezone == null ? "" : unit.timezone.toString()}>
                                {serverTime}
                            </Moment>
                        :
                            getTimeInTimezone(unit.timestamp, unit.timezone) === "" ? t('dashboard.unit.label.notAvailable') : getTimeInTimezone(serverTime, unit.timezone)
                        }
                    </Typography>
                    
                    {/* Last update */}
                    <Typography sx={styles.typography} style={{marginBottom: 0}} gutterBottom>
                        <Box fontWeight="fontWeightBold" m={0}>
                            {t('dashboard.unit.label.lastUpdate')}:
                            <br/>
                            {unit.version_hw >= "3.0" ? 
                                <Moment unix format="DD-MM-YYYY HH:mm" tz={typeof unit.timezone === "undefined" || unit.timezone == null ? "" : unit.timezone.toString()}>
                                    {unit.timestamp}
                                </Moment>
                                :
                                getTimeInTimezone(unit.timestamp, unit.timezone) === "" ? t('dashboard.unit.label.notAvailable') : getTimeInTimezone(unit.timestamp, unit.timezone)
                            }
                        </Box>
                    </Typography>
                </div>
            </Box>

            {/* Switch */}
            {unit.pwr_mode === "powersave" ?
                <Box sx={styles.switchWrapper}>
                    <Box sx={styles.switchInnerWrapper}>
                        <Typography sx={styles.typography} style={{textAlign: 'right'}}>
                            <Box fontWeight="fontWeightBold" m={0}>
                                {t('dashboard.unit.label.stayOnline')}:
                            </Box>
                        </Typography>
                        <Switch
                            sx={styles.switch}
                            checked={switchState}
                            onChange={handleSwitchChange}
                            name="stayOnline"
                            color="primary"
                            disabled={user.type !== "admin" ? expired : false}
                        />
                    </Box>

                    {(switchState && (accessMode === "wakeUpAt" || accessMode === "waitingForWakeUp")) ? 
                        <Typography variant="caption" sx={styles.typographyCaption}>
                            {t(`dashboard.unit.label.${accessMode}`)}<br/>
                            {calculateNextWakeupTime(
                                    {timezone: unit.timezone, version_hw: unit.version_hw, wakeup_times: unit.wakeup_times}, 
                                    serverTime
                            )}
                        </Typography>
                        : null
                    }
                </Box>
                : null
            }

        </Box>
    );
}

export default React.memo(UnitCardTimeInfo);