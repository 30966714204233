export const navigationItems = [
    {
        title: 'navigation.title.dashboard',
        icon: 'dashboard',
        link: '/dashboard',
        component: "Dashboard",
        acl: "ACL_CUSTOMER"
    },
    {
        title: "navigation.title.browsers",
        icon: "theaters",
        link: "/browsers",
        component: "Browsers",
        acl: "ACL_CUSTOMER"
    },
    {
        title: 'navigation.title.services',
        icon: 'local_grocery_store',
        link: '/services',
        component: "Services",
        acl: "ACL_CUSTOMER",
        children: [
            {
                title: 'navigation.title.units',
                icon: 'photo_camera',
                link: '/services/units',
                component: "ServicesAllUnits",
                acl: "ACL_CUSTOMER",
            },
            {
                title: 'navigation.title.browsers',
                icon: 'theaters',
                link: '/services/browsers',
                component: "ServicesAllBrowsers",
                acl: "ACL_CUSTOMER",
            },
        ]
    },
    {
        title: "navigation.title.customers",
        icon: "people",
        link: "/customers",
        component: "Customers",
        acl: "ACL_RESELLER_ADMIN"
    },
    {
        title: "navigation.title.customization",
        icon: "label_important",
        link: "/customization",
        component: "Customization",
        acl: "ACL_RESELLER_ADMIN"
    },
    {
        title: "navigation.title.cloud",
        icon: "cloud",
        link: "/cloud",
        component: "Cloud",
        acl: "ACL_ADMIN"
    },
];