import React from 'react';
import PropTypes from 'prop-types';
/* Material UI */
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

/* Styles */
const styles = {
    tableHead: {
        backgroundColor: 'secondary.medium',
    },
    tableHeadCellTitle: {
        fontWeight: 600
    }
};

const AllItemsTableHead = ({ browser, headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) => {

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={styles.tableHead}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={browser}
                        indeterminate={browser ? false : numSelected > 0 && numSelected < rowCount}
                        checked={browser ? false : rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        >
                            <Typography 
                                variant="subtitle1" 
                                sx={styles.tableHeadCellTitle} 
                            >
                                {headCell.label}
                            </Typography>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default AllItemsTableHead;

AllItemsTableHead.propTypes = {
    headCells: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};