import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
/* Material UI */
import List from '@mui/material/List';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
import BrowserUnitItem from './_components/BrowserUnitItem';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';


const FolderShareModal = ({
    browserId,
    handleClose,
    open,
}) => {
  
    const history = useHistory();

    const [browser, setBrowser] = useState(null);

    const getBrowser = () => {
        const onSuccess = (response) => {
            setBrowser(response.data);
        }

        const onError = (error) => {
            console.log('error in /browser/<browser-id>', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }

        BackendRequest("get", "/browser/" + browserId, null, onSuccess, onError);
    }

    useEffect(() => {
        getBrowser();
    }, []);

    const content = (
        <List>
            {browser ? browser.units.map((brUnit) => <BrowserUnitItem brUnit={brUnit} />) : null}
        </List>
    );

    return (
        <CustomModal
            content={content}
            handleClose={handleClose}
            open={open}
            title={`Sharing folder ${browser ? browser.title : ""}`}
        />
    );
}

export default FolderShareModal;