import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
/* Global state */
import { UserContext } from '../Context/UserContext';
import { SnackbarContext } from '../Context/SnackbarContext';
import { addBriefBrowserState } from '../Context/globalState';
/* Components */
import BrowserForm from '../components/Browsers/Settings/BrowserForm';
import BrowserUnits from '../components/Browsers/Settings/BrowserUnits';
import BrowserButtons from '../components/Browsers/Settings/BrowserButtons';
import FormTitle from '../components/Forms/FormTitle';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';
import { Form, useBrowserForm } from '../Utils/useBrowserForm';


const INITIAL_BROWSER_STATE = {
    title: "",
    url_path: "",
    description: "",
    gen_video: false,
    config: {
        face_blur: false,
        custom_color: "rgba(131, 206, 34, 1)",
        flip: false
    },
    password: null,
    password_confirmation: null,
    logo: null,
    units: [],
    new_units: []
}

const BrowserCreate = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const { setSnack } = useContext(SnackbarContext);
    const unitsArrayForServer = [];
    const { user } = useContext(UserContext);
    const addBriefBrowser = useSetRecoilState(addBriefBrowserState);

    const {
        browserState,
        error,
        handleChange,
        handleColorChange,
        handleLogoChange,
        handleLogoDelete,
        addUnitToState,
        deleteUnitFromState,
        editUnitInState,
        validate,
        uploadLogo
    } = useBrowserForm(INITIAL_BROWSER_STATE, true); // true indicates that it is new browser

    /**
     * Add browser unit to the server and then to the global state
     * @param {*} new_unit 
     * @param {*} browserId 
     */
     const addBrowserUnit = (new_unit, browserId) => {
        
        let data = {
            unit_id: new_unit.unit_id,
            browser_id: browserId,
            timeshift: new_unit.timeshift,
            name: new_unit.browser_unit_name,
        }
        
        const onSuccess = (response) => {
            let dispatchData = {
                "id_browser_unit": parseInt(response.data.id),
                "unit_id": parseInt(new_unit.unit_id), 
                "unit_name": new_unit.unit_name, 
                "unit_serial": new_unit.unit_serial,
                "timeshift": 0
            }

            unitsArrayForServer.push(dispatchData);
        }
        const onError = (error) => {
            console.log('error in /browser/unit', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/browser/unit", data, onSuccess, onError);
    }

    /**
     * Check changes in browser units
     * @param {*} browserId 
     * @param {*} _callback 
     */
     const checkUnits = (browserId, _callback) => {      
             
        // Post/add new browser units to the server and then to the global state
        browserState.new_units.map(new_unit => {
            addBrowserUnit(new_unit, browserId);
        })

        _callback(); // callback call -> updateBrowser()
    }

    /**
     * Update browser
     * @param {*} browserId 
     * @param {*} _callback 
     */
    const updateBrowser = (browserId, _callback) => {
        const data = {
            units: unitsArrayForServer
        }
    
        const onSuccess = (response) => {
            _callback();
        }
        const onError = (error) => {
            console.log('error in /browser/<browser-id>', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/browser/" + browserId, data, onSuccess, onError);
    }

    /**
     * Save browser configuration
     * @param {*} filename 
     * @param {*} _callback 
     */
     const createBrowser = (filename, _callback) => {
        
        const data = {
            ...browserState,
            logo: filename !== null ? filename : browserState.logo,
            config: JSON.stringify(browserState.config),
            date_to: new Date(),
            price: typeof browserState.units == 'undefined' ? 0 : browserState.units.length === 0 ? 0 : browserState.units.length > 3 ? (user.service_price["Browser"].price + ((browserState.units.length-3) * 4)) : user.service_price["Browser"].price
        }
    
        const onSuccess = (response) => {
            _callback(response.data.id); // callback call -> checkUnits()
            addBriefBrowser({
                date_to: new Date().toISOString().slice(0, 10),
                id: response.data.id,
                title: browserState.title
            })
        }
        const onError = (error) => {
            if (error.status === 406)
                setSnack({ message: t('snackbar.message.urlAlreadyExists'), type: "error", open: true});
            console.log('error in /browser', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/browser", data, onSuccess, onError);
    }

    /**
     * Handle submit and create new browser
     * @param {*} event 
     * @returns 
     */
     const handleSubmit = async (event) => {
        event.preventDefault();

        /* Validation */
        if (!validate()) return; 

        let filename = null;
        if (browserState.logo !== null) {
            filename = await uploadLogo();
        }

        // Firstly, create browser (with logo, if exists)
        createBrowser(filename, (browserId) => {
            // Secondly, if user added some units to the browser, create browser-unit connections. Browser has to created firstly, because it returns browser_id of new browser, which is then used for browser-unit creation.
            if (browserState.new_units.length !== 0) {
                checkUnits(browserId, () => {
                    // Then update browser with added units
                    updateBrowser(browserId, () => {
                        setSnack({ message: t('snackbar.message.newBrowserAdded'), type: "info", open: true});
                        window.history.back();
                    })
                })
            }
            else {
                setSnack({ message: t('snackbar.message.newBrowserAdded'), type: "info", open: true});
                window.history.back();
            }
        })
    }

    return (
        <Form onSubmit={handleSubmit}>
                
            {/* Title*/}
            <FormTitle icon="insert_photo" title={t("browsers.settings.title.newBrowser")} />

            {/* Browser form */}
            <BrowserForm 
                newBrowser 
                browser={browserState} 
                error={error} 
                handleChange={handleChange} 
                handleColorChange={handleColorChange}
                handleLogoChange={handleLogoChange}
                handleLogoDelete={handleLogoDelete}
            />

            <br /><br />

            {/* Browser units */}
            <BrowserUnits 
                newBrowser 
                browser={browserState} 
                addUnit={addUnitToState} 
                deleteUnit={deleteUnitFromState} 
                editUnit={editUnitInState}
            />

            <br /><br />
            
            {/* Buttons */}
            <BrowserButtons
                newBrowser 
                error={error} 
                handleSubmit={handleSubmit} 
            />

        </Form>
    );
}

export default BrowserCreate;