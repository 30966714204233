import React, { useContext } from 'react';
import { NavLink, withRouter} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/* Material UI */
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
/* Global state */
import { DrawerContext } from '../../../../Context/DrawerContext';
/* Components */
import ACLWrapper from '../../../../Containers/ACLWrapper';
/* Styles */
import { styles } from './DrawerListItem.styles';

const DrawerListItem = ({ item }) => {

    const { t } = useTranslation();

    const { toggleDrawer } = useContext(DrawerContext);
    
    return (
        <ACLWrapper requiredPermission={item.acl}>
            <ListItem 
                button
                component={NavLink}
                onClick = {() => toggleDrawer(false)}
                style={item.link.startsWith("/services/") ? {paddingLeft: '32px'} : {}}
                sx={styles.active}
                to={item.link}
            >
                <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText>{t(item.title)}</ListItemText>
            </ListItem>
        </ACLWrapper>
    );
}

export default withRouter(React.memo(DrawerListItem));