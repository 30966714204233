import Axios from 'axios';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
/* Material UI */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
import CustomSpinner from '../../lib/CustomSpinner/CustomSpinner';
/* Global state */
import { AuthenticationContext } from '../../../Context/AuthenticationContext';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';
import { getTokenFromLocalStorage } from '../../../Utils/localStorage';
/* Styles */
import { styles } from './UnitsListModal.styles';


const UnitsListModal = ({
    addUnit,
    browser = null,
    handleClose,
    open,
    user = null,
}) => {
  
    const firstRender = useRef(true);
    const history = useHistory();
    const { t } = useTranslation();

    const [units, setUnits] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isInitiallyLoading, setIsInitiallyLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { setAuth } = useContext(AuthenticationContext);

    useEffect(() => {
        // Get all units initially.
        const onSuccess = (response) => {
            let unitsArray = Object.entries(response.data).map((e) => ( { [e[0]]: e[1] } ));
            setUnits(unitsArray);
            setIsInitiallyLoading(false);
        }
        const onError = (error) => {
            setIsInitiallyLoading(false);
            console.log(`error in /unit/all?page=0&page_size=9999&search=${searchQuery}`, error);
            if ((error.status === 401 || error.status === 403)) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        BackendRequest("get", `/unit/all?page=0&page_size=9999&search=${searchQuery}`, null, onSuccess, onError);
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsLoading(true);
        let cancel;

        Axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
            },
            url: `https://api.bixion.com/is/unit/all?page=0&page_size=9999&search=${searchQuery}`, //URL
            cancelToken: new Axios.CancelToken(c => cancel = c)
        }).then(response => {
            let unitsArray = Object.entries(response.data).map((e) => ( { [e[0]]: e[1] } ));
            setUnits(unitsArray);
            setIsLoading(false);
        }).catch(error => {
            /* Unauthorized/forbidden => Redirect to login and clear localStorage */
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem("token");
                setAuth(false);
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
            if (Axios.isCancel(error)) return;
            console.log('error', error);
        })
        return () => cancel(); // Closing last request
    }, [searchQuery])

    /**
     * Handle search input change
     * @param {*} event 
     */
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    let content, description;
    if (isInitiallyLoading || isLoading) {
        content = <CustomSpinner />;
    } else if (units.length === 0) {
        content = t('dashboard.label.noUnitsWereFound');
    } else {
        let atLeastOneUnusedUnit = false;
        content = (
            <List sx={styles.list}>
                {units.map((unit) => {
                    let transUnit = Object.values(unit)[0];
                    // Add serial number to object
                    transUnit.serial_number = Object.keys(unit)[0];

                    if (browser !== null) {
                        // If unit is already browser unit, do not list it
                        if (browser.units.some(el => el.unit_id === transUnit.id))
                            return null;

                        // Only for new units in BrowserSettings (updating browser) - If unit is already browser unit, do not list it
                        if (browser.new_units !== undefined && browser.new_units.some(el => el.unit_id === transUnit.id))
                            return null;
                    }
                    else if (user !== null) {
                        // If unit is already user unit, do not list it
                        if (user.units.some(el => el.id === transUnit.id))
                            return null;
                    }
                    
                    atLeastOneUnusedUnit = true;
    
                    return (
                        <ListItem sx={styles.listItem} onClick={() => addUnit(transUnit)}>
                            <ListItemText 
                                primary={
                                    <div>
                                        <Typography sx={styles.listItemTitle} color="primary">
                                            {transUnit.name.toUpperCase()}
                                        </Typography>
                                        <Typography variant="subtitle2" color="secondary">
                                            ({transUnit.serial_number})
                                        </Typography>
                                    </div>
                                }           
                            />
                        </ListItem>
                    );
                })}
            </List>
        );
        if (!atLeastOneUnusedUnit) {
            content = t('customers.dialog.label.unitsAlreadyUsed');
        } else {
            description = t('customers.dialog.label.addUnitByClicking');
        }
    }

    return (
        <CustomModal
            content={content}
            description={description}
            handleClose={handleClose}
            isInitiallyLoading={isInitiallyLoading}
            open={open}
            search={{
                handleInputChange,
                searchQuery
            }}
            title={t('customers.dialog.title.addUnit')}
        />
    );
}

export default UnitsListModal;