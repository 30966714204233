import React from 'react';
/* Material UI */
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
/* Components */
import UnitCardHeader from './UnitCardHeader';
import UnitCardInfoTable from './UnitCardInfoTable';
import UnitCardButtons from './UnitCardButtons';
import UnitCardFooter from './UnitCardFooter';
import UnitCardTimeInfo from './UnitCardTimeInfo';

/* Styles */
const styles = {
    paper: {
      padding: '16px',
      borderRadius: '8px',
      position: 'relative'
    },
    divider: {
        marginTop: '8px',
        marginBottom: '8px',
        width: 'calc(100% - 66px)'
    },
    expiredPaper: {
        padding: '16px',
        borderRadius: '8px',
        position: 'relative',
        backgroundColor: 'secondary.ultraLight'
    }
};

const UnitCard = ({ unit }) => {

    // Check of remote management service expiration
    let expired = (unit.services && unit.services.length > 0) ? new Date(unit.services[0].date_to) < new Date() : true;

    // No information about versions means there are no data at all and unit can be treated as expired
    // if (!expired) {
    //     if (unit.version_hw == null && unit.version_sw == null) {
    //         expired = true;
    //     }
    // }

    //console.log("[UnitCard]", unit);
    
    return (
        <Grid item xs={12} sm={12} md={6} xl={4} xxl={3} style={{padding: '6px', width: '100%'}}>
            <Paper sx={!expired ? styles.paper : styles.expiredPaper} elevation={3}>
                
                {/* Header */}
                <UnitCardHeader
                    unit={{
                        name: unit.name,
                        pwr_mode: unit.pwr_mode,
                        online: unit.online,
                        users: unit.users,
                        user_info: unit.user_info,
                        version_sw: unit.version_sw,
                        version_hw: unit.version_hw,
                        unit_state: unit.unit_state ? unit.unit_state : false
                    }}
                    expired={expired}
                />
                
                <Divider sx={styles.divider} />

                {/* Time info */}
                <UnitCardTimeInfo
                    unit={{
                        id: unit.id,
                        online: unit.online,
                        pwr_mode: unit.pwr_mode,
                        stay_online: unit.stay_online,
                        serial_number: unit.serial_number,
                        timezone: unit.timezone,
                        timestamp: unit.timestamp,
                        version_hw: unit.version_hw,
                        wakeup_times: unit.wakeup_times
                    }}
                    expired={expired} 
                />

                {/* Info table */}
                <UnitCardInfoTable 
                    unit={
                        /* Use sensor_data if SW verison is higher than 3.0 */
                        (unit.version_sw && parseInt(unit.version_sw.charAt(0)) >= 3)
                        ? 
                            { 
                                ...unit.sensor_data, 
                                version_sw: unit.version_sw
                            }
                        : 
                            {
                                fails_shot: unit.fails_shot,
                                fails_upload: unit.fails_upload,
                                files_from_cam: unit.files_from_cam,
                                files_uploaded: unit.files_uploaded,
                                temperature: unit.temperature,
                                uptime: unit.uptime,
                                voltage: unit.voltage,
                                version_sw: unit.version_sw
                            } 
                    }
                    expired={expired}
                />

                {/* Buttons */}
                <UnitCardButtons 
                    unit={{
                        id: unit.id,
                        name: unit.name,
                        online: unit.online,
                        pwr_mode: unit.pwr_mode,
                        serial_number: unit.serial_number,
                        secure_id: unit.secure_id,
                        vpn_ip: unit.vpn_ip
                    }}
                    expired={expired}
                />
                
                {/* Footer */}
                <UnitCardFooter 
                    unit={{
                        id: unit.id,
                        serial_number: unit.serial_number,
                        version_sw: unit.version_sw,
                        version_hw: unit.version_hw,
                        vpn_ip: unit.vpn_ip
                    }}
                />

            </Paper>
        </Grid>
    );
}

export default React.memo(UnitCard);