import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
/* Global state */
import { SnackbarContext } from './SnackbarContext';
import { updateServerTimeState, updateUnitState } from './globalState';

export const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {

    const [ socket, setSocket ] = useState(null);
    const { setSnack } = useContext(SnackbarContext);
    const updateServerTime = useSetRecoilState(updateServerTimeState);
    const updateUnit = useSetRecoilState(updateUnitState);
    const webSocketProviderValue = useMemo(() => ({socket, setSocket}), [socket, setSocket]);

    useEffect(() => {
        console.log("Setting socket", socket);
        
        if (socket !== null) {
            /* Connect */
            socket.on("connect", () => {
                console.log("SocketIO connect success");
            });
            /* Disconnect */
            socket.on("disconnect", () => {
                console.log('SocketIO disconnect');
            });
            /* Error */
            socket.on("error", (err) => {
                console.log('Socket IO error: ' + err);
            });
            /* Dashboard update - new values update dashboard */
            socket.on('dashboard_update', (data) => {
                console.log('Event "dashboard_update": ' + JSON.stringify(data));
                if (window.location.pathname === "/dashboard") {
                    updateServerTime();
                }
                updateUnit({
                    serial_number: Object.keys(data)[0],
                    data: Object.values(data)[0]
                })
            });
            /* Notification (3 types) */
            socket.on('notification', (notification) => {
                console.log('Event "notification": ' + JSON.stringify(notification));
                setSnack({ message: notification.msg, type: notification.severity, open: true});
            });
            /* Connection error */
            socket.on('connect_error', (err) => {
              console.log('SocketIO connect error: ' + err);
              // disconnect has to be done manually in case of error!
              // Bug https://github.com/miguelgrinberg/Flask-SocketIO/issues/1279
              if (err.message === 'unauthorized') {
                socket.disconnect()
              }
            });
        }
    }, [socket])

    return <WebSocketContext.Provider
        value={webSocketProviderValue}>
        {children}
    </WebSocketContext.Provider>
};

export default WebSocketProvider;