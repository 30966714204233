import React, { useContext } from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
/* Components */
import ACLWrapper from '../../../Containers/ACLWrapper';
/* Global state */
import { OrderContext } from '../../../Context/OrderContext';

/* Styles */
const styles = {
    tableHead: {
        backgroundColor: 'secondary.main',
    },
    tableHeadCellTitle: {
        fontWeight: 600
    }
};

const UnitsTableHead = () => {
  
    const { t } = useTranslation();
    const currentOrderState = useContext(OrderContext);

    return (
        <TableHead sx={styles.tableHead}>
            <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>
                    <Typography variant="subtitle1" sx={styles.tableHeadCellTitle}>
                        {t('services.unitsTable.title.unit').toUpperCase()}
                    </Typography>
                </TableCell>
                <TableCell align="center"> 
                    <Typography variant="subtitle1" sx={styles.tableHeadCellTitle}>
                        {t('services.unitsTable.title.remoteManagement').toUpperCase()}
                    </Typography>
                    {/* <Typography variant="subtitle2">
                        ({currentOrderState.units.price_per_one} EUR/{t('services.unitsTable.title.month')})
                    </Typography> */}
                </TableCell>
                {/*<TableCell align="center">
                    <Typography variant="subtitle1" className={classes.tableHeadCellTitle}>
                        {t('services.unitsTable.title.monitoring')}
                    </Typography>
                    <Typography variant="subtitle2">
                        (10 EUR/{t('services.unitsTable.title.month')})
                    </Typography>
                </TableCell>*/}
                <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                    <TableCell align="center"> 
                    
                    </TableCell>
                </ACLWrapper>
            </TableRow>
        </TableHead>
    );
}

export default UnitsTableHead;