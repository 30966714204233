import React, { useState, useContext } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
import StripeInput from '../../Services/StripeInput';
/* Global state */
import { UserContext } from '../../../Context/UserContext';

/* Styles */
const styles = {
    container: {
        display: 'flex',
        alignItems: 'end',
        padding: '0 0px',
        gap: '16px',
        '@media (max-width: 630px)': {
            alignItems: 'start',
        }
    },
    infoIcon: {
        color: 'secondary.main'
    },
    info: {
        fontWeight: 'bold',
        color: 'secondary.main'
    }
}

const TopUpCreditModal = ({ open, handleClose, onSuccess, onError }) => {

    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    // Stripe
    const elements = useElements();
    const stripe = useStripe();

    const MINIMAL_AMOUNT = user.type === 'admin' ? 1 : 200;

    const [ amount, setAmount ] = useState(MINIMAL_AMOUNT);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return
        }

        // Create payment intent on the server
        const { error: backendError, clientSecret } = await fetch('https://api.bixion.com/stripe/create-payment-intent', { //URL
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentMethodType: 'card',
                amount: amount * 100,
                currency: 'eur',
                metadata: {
                    user: `${user.username} (ID: ${user.id})`,
                    creditAfterwards: user.credit ? user.credit + amount : amount,
                }
            }),
        }).then(r => r.json());
        
        if (backendError) {
            console.log(backendError.message);
            onError(backendError.message);
            return;
        }

        // Confirm the payment on the client
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                },
            }
        )

        if (stripeError) {
            console.log(stripeError);
            onError(stripeError.message);
            return;
        }
        else {
            onSuccess(amount);
        }
    }
    
    return (
        <CustomModal
            buttons={[
                {
                    action: user.type === 'admin' ? () => onSuccess(amount) : handleSubmit,
                    label: "Top up",
                    disabled: amount < MINIMAL_AMOUNT || !amount,
                },
            ]}
            content={(
                <form onSubmit={user.type === 'admin' ? () => onSuccess(amount) : handleSubmit}>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} style={{marginTop: '8px'}}>
                      {/* Amount */}
                      <CustomTextField
                          error={amount < MINIMAL_AMOUNT || !amount}
                          value={amount}
                          handleChange={(event) => setAmount(parseInt(event.target.value))}
                          helperText={(amount < MINIMAL_AMOUNT || !amount) ? `Minimal amount to be topped up is ${MINIMAL_AMOUNT} €.` : null}
                          label="AMOUNT"
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment sx={{ mr: 1.5 }}>€</InputAdornment>,
                            inputProps: { min: MINIMAL_AMOUNT }
                        }}
                      />
                    </Grid>
                    {user.type !== 'admin' && (
                        <Grid item xs={12}>
                            {/* Credit card number */}
                            <TextField
                                id="card-element"
                                label={t('services.summary.label.creditCard').toUpperCase()}
                                name="ccnumber"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    inputComponent: StripeInput,
                                    inputProps: {
                                        component: CardElement
                                    },
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} style={{marginTop: '8px'}}>
                        <Box sx={styles.container}>
                            <InfoIcon sx={styles.infoIcon} />
                            <Typography variant="subtitle2" sx={styles.info}>If you wish to top up the credit by bank transfer, contact us via e-mail.</Typography>
                        </Box>
                    </Grid>
                  </Grid>
                </form>
            )}
            handleClose={handleClose}
            open={open}
            title="CREDIT TOP UP"
        />
    );
}

export default TopUpCreditModal;