export const FETCH_FEED_ITEMS = "FETCH_FEED_ITEMS";
export const FETCH_VERSIONS = "FETCH_VERSIONS";
export const ADD_FEED_ITEM = "ADD_FEED_ITEM";
export const ADD_VERSION = "ADD_VERSION";
export const UPDATE_FEED_ITEM = "UPDATE_FEED_ITEM";
export const UPDATE_VERSION = "UPDATE_VERSION";
export const DELETE_FEED_ITEM = "DELETE_FEED_ITEM";
export const DELETE_VERSION = "DELETE_VERSION";

export const feedReducer = (state, action) => {

    switch (action.type) {

        case FETCH_FEED_ITEMS:
            return {
                feedItems: [...action.feedItems],
                versions: state.versions,
            }

        case FETCH_VERSIONS:
            return {
                feedItems: state.feedItems,
                versions: [...action.versions],
            }

        case ADD_FEED_ITEM:
            return {
                feedItems: [ action.newFeedItem, ...state.feedItems],
                versions: state.versions,
            }

        case ADD_VERSION:
            return {
                feedItems: state.feedItems,
                versions: [ action.newVersion, ...state.versions],
            }
                
        case UPDATE_FEED_ITEM:
            
            const updatedFeedItemsArray = [...state.feedItems];
            const updatedFeedItemIndex = updatedFeedItemsArray.length > 0 ? updatedFeedItemsArray.findIndex(feedItem => feedItem.id === action.id) : -1;
            
            /* Update existing item */
            if (updatedFeedItemIndex !== -1) {
                updatedFeedItemsArray[updatedFeedItemIndex] = action.updatedFeedItem;
            }

            return {
                feedItems: updatedFeedItemsArray,
                versions: state.versions,
            }

        case UPDATE_VERSION:
        
            const updatedVersionsArray = [...state.versions];
            const updatedVersionIndex = updatedVersionsArray.length > 0 ? updatedVersionsArray.findIndex(version => version.id === action.id) : -1;
            
            /* Update existing item */
            if (updatedVersionIndex !== -1) {
                updatedVersionsArray[updatedVersionIndex] = action.updatedVersion;
            }

            return {
                feedItems: state.feedItems,
                versions: updatedVersionsArray,
            }

        case DELETE_FEED_ITEM:

            const feedItemsArray = [...state.feedItems];
            const feedItemIndex = feedItemsArray.length > 0 ? feedItemsArray.findIndex(feedItem => feedItem.id === action.id) : -1;
            feedItemsArray.splice(feedItemIndex, 1);  

            return {
                feedItems: feedItemsArray,
                versions: state.versions,
            }

        case DELETE_VERSION:

            const versionsArray = [...state.versions];
            const versionIndex = versionsArray.length > 0 ? versionsArray.findIndex(version => version.id === action.id) : -1;
            versionsArray.splice(versionIndex, 1);  

            return {
                feedItems: state.feedItems,
                versions: versionsArray,
            }
    
        default:
            return state;
    }
}