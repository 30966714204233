import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* Material UI */
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
/* Global state */
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { FeedActionsContext } from '../../../Context/FeedContext';
import { ADD_VERSION, UPDATE_VERSION } from '../../../Context/reducers/feedReducer';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';


const VersionModal = ({
    handleClose,
    open,
    version = null,
}) => {
    
    const { t } = useTranslation();
    const history = useHistory();
    
    const [ state, setState ] = useState(version ? {...version} : {
        version: "",
        description: ""
    });
    
    const { setSnack } = useContext(SnackbarContext);
    const dispatchFeed = useContext(FeedActionsContext);

    /**
     * Handle change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    /**
     * Handle Submit
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        
        event.preventDefault();

        const tzoffset = (new Date()).getTimezoneOffset() * 60000;
        let now = new Date(Date.now() - tzoffset);
        now = now.toISOString().slice(0, -1);
        now = now.substring(0, now.indexOf('T'))
    
        const onSuccess = (response) => {
            if (version) {
                dispatchFeed({
                    type: UPDATE_VERSION,
                    id: version.id,
                    updatedVersion: { ...state, ...response.data }
                });
                setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            }
            else {
                dispatchFeed({
                    type: ADD_VERSION,
                    newVersion: { ...state, release_date: now, ...response.data }
                });
                setState({
                    version: "",
                    description: ""
                });
                setSnack({ message: 'Version added.', type: "info", open: true});
            }
            handleClose();
        }
        const onError = (error) => {
            console.log('error in /news/versions');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        if (version) {
            BackendRequest("put", `/news/versions/${version.id}`, state, onSuccess, onError);
        }
        else {
            BackendRequest("post", '/news/versions', { ...state, release_date: now}, onSuccess, onError);
        }
    }

    const content = (
        <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Version */}
                <CustomTextField
                    autoFocus
                    name="version"
                    value={state.version}
                    handleChange={handleChange}
                    label="VERSION"
                    type="text"
                    InputProps={{
                        startAdornment: <InputAdornment />,
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Description */}
                <CustomTextField
                    multiline
                    maxRows={10}
                    minRows={6}
                    name="description"
                    label="DESCRIPTION"
                    type="text"
                    value={state.description}
                    onChange={handleChange}
                    placeholder="Add description..."
                    style={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}
                />
            </Grid>
        </Grid>
    );
    
    return (
        <CustomModal
            buttons={[
                {
                    action: handleSubmit,
                    label: t('button.save')
                },
            ]}
            content={content}
            handleClose={handleClose}
            open={open}
            title={version ? version.version : "Add new version"}
        />
    );
}

export default VersionModal;