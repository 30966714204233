import React from 'react';
/* Material UI */
import List from '@mui/material/List';
import Box from '@mui/material/Box';
/* Components */
import UnitRow from './UnitRow/UnitRow';

/* Styles */
const styles = {
    dashboardRowsContainer: {
        width: '100%', 
        overflow: 'auto'
    },
    list: {
        width: '100%',
        minWidth: '1000px'
    },
};

const DashboardRows = ({ units }) => {

    //console.log("[DashboardRows]");
    
    if (units.length > 0) {
        return (
            <Box sx={styles.dashboardRowsContainer}>
                <List sx={styles.list}>
                    {units.map(unit => {
                        return <UnitRow key={unit.serial_number} unit={unit} />
                    })}
                </List>
            </Box>
        );
    }
    else
        return null
}

export default DashboardRows;