import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import PaymentIcon from '@mui/icons-material/Payment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SummaryHeader from './SummaryHeader';
/* Global State */
import { OrderActionsContext, OrderContext } from '../../../Context/OrderContext';
import { UserContext } from '../../../Context/UserContext';
import { UPDATE_PAYMENT_METHOD } from '../../../Context/reducers/orderReducer';

/* Own */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    },
    divider: {
        marginTop: '8px',
        marginBottom: '24px',
        height: '2px'
    }
};

const SummaryPaymentMethod = ({ paymentMethod, setPaymentMethod, category }) => {

    const { t } = useTranslation();
    const currentOrderState = useContext(OrderContext);
    const { user } = useContext(UserContext);
    const dispatchOrder = useContext(OrderActionsContext);

    const handlePaymentMethodChange = (event) => {
        dispatchOrder({
            type: UPDATE_PAYMENT_METHOD,
            payment_method: event.target.value,
            category,
        })
        setPaymentMethod(event.target.value)
    }
    
    return (
        <Paper sx={styles.paper} elevation={3}>
            {/* Header */}
            <SummaryHeader title={t('services.summary.title.paymentMethod')}>
                <PaymentIcon />
            </SummaryHeader>
            {/* Divider */}
            <Divider sx={styles.divider} />
            {/* Options */}
            <FormControl component="fieldset" style={{padding: '0 12px'}}>
                <RadioGroup aria-label="payment-method" name="payment-method" value={paymentMethod} onChange={handlePaymentMethodChange}>
                    <FormControlLabel value="credit" control={<Radio />} label={`Credit (${user.credit != null ? (Math.round(user.credit * 100) / 100).toFixed(2) : '0.00'} €)`} disabled={user.credit == null || user.credit < parseInt(currentOrderState[category].price)} />
                    <FormControlLabel value="card" control={<Radio />} label={t('services.summary.label.creditCard')} />
                    <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                </RadioGroup>
            </FormControl>
        </Paper>
    );

}

export default SummaryPaymentMethod;