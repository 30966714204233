import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { withRouter} from "react-router-dom";
import { io } from "socket.io-client"
/* Material UI */
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
/* Global state */
import { WebSocketContext } from '../../Context/WebSocketContext';
/* Components */
import DrawerToggleButton from '../Drawer/_components/DrawerToggleButton/DrawerToggleButton';
import AppBarContent from './AppBarContent';
/* Utils */
import { getTokenFromLocalStorage } from '../../Utils/localStorage';

/* Styles */
const styles = {
    appBar: {
        zIndex: 1201,
        maxWidth: '100%',
        backgroundColor: 'secondary.main',
        padding: '0 !important'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
};

const CustomAppBar = () => {

    const drawerBreakpoint = useMediaQuery({
        query: `(min-width: 991px)`
    });
    const { socket, setSocket } = useContext(WebSocketContext);

    useEffect(() => {
        /* Setting WebSocket tunnel */
        if (socket === null) {
            setSocket(
                io('https://api.bixion.com', {
                    extraHeaders: {
                        "token": getTokenFromLocalStorage()
                    }
                })
            )
        }
    }, [])

    //console.log("[CustomAppBar]");

    return (
        <AppBar position="fixed" sx={styles.appBar}>
            <Toolbar sx={styles.header}>
                
                <AppBarContent />
                
                {/* Show burger menu button on small devices (screen width less 991px) */}
                {!drawerBreakpoint ? <DrawerToggleButton /> : null}
                
            </Toolbar>
        </AppBar>
    )
    
}

export default withRouter(React.memo(CustomAppBar));