export const styles = {
    button: {
        color: 'black', // for case of secondary background of Button
        fontWeight: 600,
        height: 'fit-content',
        whiteSpace: 'nowrap',
        '@media (max-width: 480px)': {
            fontSize: '12px'
        },
    }
};