import axios from 'axios';
import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from "react-i18next";
import { withRouter} from "react-router-dom";
/* Material UI */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
/* Components */
import AccountTableRow from '../components/cloud/AccountTableRow';
import ACLWrapper from '../Containers/ACLWrapper';
import CloudAccountModal from '../components/modals/CloudAccountModal/CloudAccountModal';
import CustomButton from '../components/lib/CustomButton/CustomButton';
import CustomSpinner from '../components/lib/CustomSpinner/CustomSpinner';
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import { getTokenFromLocalStorage } from '../Utils/localStorage';


const FIRST_COLUMN = 280;
const SECOND_COLUMN = 240;
const THIRD_COLUMN = 120;
const FOURTH_COLUMN = 80;
const FIFTH_COLUMN = 400;
const TABLE_WIDTH = FIRST_COLUMN + SECOND_COLUMN + THIRD_COLUMN + FOURTH_COLUMN + FIFTH_COLUMN;

/* Styles */
const styles = {
    paper: {
        width: `${TABLE_WIDTH}px`,
        maxWidth: '100%',
    },
    topBar: {
        display: 'flex', 
        marginBottom: '16px',
        justifyContent: 'space-between',
    },
    table: {
        width: `${TABLE_WIDTH}px`,
    },
    tableHead: {
        backgroundColor: 'secondary.main',
        '& .MuiTableCell-head': {
            fontWeight: 600,
        }
    },
    column: {
        paddingRight: '20px',
        wordBreak: 'break-all'
    },
    firstColumn: {
        width: `${FIRST_COLUMN}px`
    },
    secondColumn: {
        width: `${SECOND_COLUMN}px`
    },
    thirdColumn: {
        width: `${THIRD_COLUMN}px`
    },
    fourthColumn: {
        width: `${FOURTH_COLUMN}px`
    },
    fifthColumn: {
        width: `${FIFTH_COLUMN}px`
    },
};

/*const ACCOUNTS = [
    {
        id: 1,
        user_id: 291,
        username: 'ondra',
        first_name: 'Ondrej',
        last_name: 'Dacer',
        active: true,
        in_sync: true,
        quota: 2000,
        password: "123456789"
    },
    {
        id: 2,
        user_id: 58,
        username: 'HorusAdmin',
        first_name: 'Horus',
        last_name: 'Admin',
        active: true,
        in_sync: false,
        quota: 5000,
        password: "123456789"
    },
    {
        id: 3,
        user_id: 399,
        username: 'zakaznik',
        first_name: 'zakaznik',
        last_name: '',
        active: false,
        in_sync: true,
        quota: 1500,
        password: "123456789"
    },
];*/


const Cloud = () => {
     
    const [accounts, setAccounts] = useState([]);
    const [cloudAccountModalOpen, setCloudAccountModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);

    const propagateRemoveToState = (id) => {
        let accountsCopy = [...accounts]
        let accountIndex = accountsCopy.findIndex(account => account.id === id);
        accountsCopy.splice(accountIndex, 1);
        setAccounts(accountsCopy);
    }

    const propagateChangeToState = (account, isNewAccount) => {
        let accountsCopy = [...accounts];
        if (isNewAccount) {
            accountsCopy.push(account);
        } else {
            let accountIndex = accountsCopy.findIndex(acc => acc.id === account.id);
            accountsCopy[accountIndex] = account;
        }
        setAccounts(accountsCopy);
    }

    useEffect(() => {
        axios.get("https://api.bixion.com/bcloud/cloud", { headers: {
            'Accept': 'application/json', 
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`,    
            'Content-Type': 'application/json',
        }})
        .then(function (response) {
            setLoading(false);
            setAccounts(response.data);
        })
        .catch(function (error) {
            setLoading(false);
            setSnack({ message: t('snackbar.message.failedToLoadAccounts'), type: "error", open: true});
            console.log(error.response);
        });
    }, []);

    return (
        <ACLWrapper requiredPermission={'ACL_ADMIN'}>
            {/* Top bar */}
            <Box sx={styles.topBar}>
                <CustomButton
                    color="primary"
                    onClick={() => setCloudAccountModalOpen(true)}
                    style={{textTransform: 'none', marginRight: '8px'}}
                >
                    + {t('cloud.overview.button.addAccount')}
                </CustomButton>
            </Box>

            {(accounts.length === 0 && !loading) && t('cloud.overview.label.noAccountsWereFound')}
            {(accounts.length > 0 && !loading) && (
                <TableContainer
                    component={Paper}
                    sx={styles.paper}
                >
                    <Table sx={styles.table} size="small" aria-label="accounts table">
                        <TableHead sx={styles.tableHead}>
                            <TableRow>
                                <TableCell sx={[styles.column, styles.firstColumn]}>
                                    {t('cloud.overview.title.firstAndLastName')}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.secondColumn]}>
                                    {t('cloud.overview.title.username')}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.thirdColumn]}>
                                    {t('cloud.overview.title.quota')}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.fourthColumn]} align="center">
                                    {t('cloud.overview.title.active')}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.fifthColumn]} align="center">
                                    {t('cloud.overview.title.controls')}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {accounts.map((account) => (
                                <AccountTableRow
                                    account={account}
                                    key={account.id}
                                    parentStyles={styles}
                                    propagateChangeToState={propagateChangeToState}
                                    propagateRemoveToState={propagateRemoveToState}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Spinner */}
            <div>
                {loading ? <CustomSpinner /> : null}
            </div>

            {cloudAccountModalOpen &&
                <CloudAccountModal
                    handleClose={() => setCloudAccountModalOpen(false)}
                    open={cloudAccountModalOpen}
                    propagateChangeToState={propagateChangeToState}
                />
            }
        </ACLWrapper>
    );
}

export default withRouter(Cloud)