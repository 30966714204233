import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
/* Material UI */
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
/* Global state */
import { UserContext } from '../../../Context/UserContext';
/* Hooks */
import { getUnitInfo } from '../../../Utils/useUnitInfo';
import { colorToRGBAFormat } from '../../../theme';

/* Styles */
const styles = {
    infoTableCell: {
        padding: '6px',
        boxSizing: 'border-box',
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'secondary.medium',
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        color: '#848484',
        '@media (max-width: 480px)': {
            fontSize: '1.2rem'
        }
    },
    typography: {
        '@media (max-width: 480px)': {
            fontSize: 13
        }
    },
    expired: {
        backgroundColor: 'secondary.ultraLight'
    }
};

const UnitCardInfoTableItem = ({ unit, item, expired }) => {

    const { t } = useTranslation();
    
    const { title, icon, value, color } = getUnitInfo(unit, item);
    
    const { user } = useContext(UserContext);

    let styleIcon = {};
    let styleTypography = {}
    let stylePaper = {};
    if (color && (!expired || user.type === "admin")) {
        let clr = colorToRGBAFormat(color, .9)
        styleIcon = {
            color: clr,
        };
        styleTypography = {
            color: clr,
            fontWeight: 600
        };
        stylePaper = {
            border: `1px solid ${clr}`,
            backgroundColor: colorToRGBAFormat(color, .1),
        };
    }

    return (
        <div style={{width: '100%'}}>
            <Tooltip title={t(title)}>
                <Paper sx={ Object.assign({}, styles.infoTableCell, expired && styles.expired)} style={stylePaper} elevation={0}>
                    <Icon sx={styles.icon} style={styleIcon}>{icon}</Icon>
                    <Typography sx={styles.typography} style={styleTypography}>
                        {(!expired || user.type === "admin") ? value : "-"}
                    </Typography>
                </Paper>
            </Tooltip>
        </div>      
    );
}

export default React.memo(UnitCardInfoTableItem);