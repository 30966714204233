import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
/* Material UI */
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
/* Global state */
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { UserContext, UPDATE_USER } from '../../../Context/UserContext';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';

const items = [
    { name: "files_from_cam", label: "shotPictures" },
    { name: "files_uploaded", label: "uploadedPictures" },
    { name: "fails_shot", label: "failShotFailUploaded" },
    { name: "local_storage", label: "localStorage" },
    { name: "rssi", label: "rssi" },
    { name: "humidity", label: "humidity" },
    { name: "temperature", label: "temperature" },
    { name: "uptime", label: "uptime" },
    { name: "voltage", label: "voltage" },
]

const UnitInfoConfigModal = ({
    handleClose,
    open
}) => {

    const history = useHistory();
    const { t } = useTranslation();

    const { user, setUser } = useContext(UserContext)
    const { setSnack } = useContext(SnackbarContext);

    const config = user.config;

    const [ error, setError ] = useState(null);
    const [ state, setState ] = useState(config.sensor_data ? {
        "fails_shot": config.sensor_data.includes("fails_shot"),
        "files_from_cam": config.sensor_data.includes("files_from_cam"),
        "files_uploaded": config.sensor_data.includes("files_uploaded"),
        "humidity": config.sensor_data?.includes("humidity"),
        "local_storage": config.sensor_data?.includes("local_storage"),
        "rssi": (config.sensor_data?.includes("rssi") || config.sensor_data.includes("rssi_lte") || config.sensor_data.includes("rssi_wifi")),
        "temperature": config.sensor_data?.includes("temperature"),
        "uptime": config.sensor_data?.includes("uptime"),
        "voltage": config.sensor_data?.includes("voltage"),
    }: {
        "fails_shot": true,
        "files_from_cam": true,
        "files_uploaded": true,
        "humidity": false,
        "local_storage": false,
        "rssi": false,
        "temperature": true,
        "uptime": true,
        "voltage": true,
    });

    /**
     * Handle change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    /**
     * Handle submit
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        
        event.preventDefault();

        /* Validation */
        let unitItems = [];
        for (const key in state) {
            if (state[key]) {
                unitItems.push(key);
            }
        }

        if (unitItems.length > 6) {
            setError("Please select only 6 items.")
            return;
        }
        else if (unitItems.length < 6) {
            setError("Please select 6 items.")
            return;
        }
        else {
            setError(null);
        }
        
        const data = {
            ...user.config,
            sensor_data: unitItems
        }
        
        const onSuccess = (response) => {
            setUser({
                type: UPDATE_USER,
                user: { config: data }
            })
            setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            handleClose();

        }
        const onError = (error) => {
            console.log('error in /user/<user-id>');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/user/" + user.id, { config: JSON.stringify(data) }, onSuccess, onError);
    }

    let content = (
        <Grid container alignItems="flex-end" spacing={2}>
            {items.map(i => (
                <Grid item xs={12} md={6} key={i.name}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state[i.name]}
                                onChange={handleChange}
                                name={i.name}
                                color="primary"
                            />
                        }
                        label={t('dashboard.unit.tooltip.' + i.label)}
                    />
                </Grid>
            ))}   
        </Grid>
    );
    
    return (
        <CustomModal
            buttons={[
                {
                    action: handleSubmit,
                    label: t('button.save')
                },
            ]}
            content={content}
            description={t('dashboard.unit.dialog.label.selectSixItems')}
            error={error}
            handleClose={handleClose}
            open={open}
            title={t('dashboard.unit.dialog.title.unitInfoConfiguration').toUpperCase()}
            titleIcon="settings"
        />
    );
}

export default UnitInfoConfigModal;