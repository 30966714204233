import React, { useContext } from 'react';
/* Material UI */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
/* Global State */
import { OrderContext } from '../../../Context/OrderContext';

/* Own */
const styles = {
  paper: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: 'primary.light'
  },
  container: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0px',
      gap: '16px',
  },
  infoIcon: {
      color: 'primary.main'
  },
  info: {
      color: 'black'
  }
};

const SummaryPaymentMethodAdvice = ({ paymentMethod, setPaymentMethod, category }) => {

  const currentOrderState = useContext(OrderContext);

  /* Credit price in use */
  if (currentOrderState[category].used_price_per_one === 'credit_price_per_one') {
    return null;
  }

  /* Bulk price in use */
  if (currentOrderState[category].used_price_per_one === 'bulk_price_per_one') {
    if (currentOrderState[category].credit_price_per_one && currentOrderState[category].credit_price_per_one < currentOrderState[category].bulk_price_per_one) {
      return (
        <>
          <Paper sx={styles.paper} elevation={3}>
            <Box sx={styles.container}>
                <InfoIcon sx={styles.infoIcon} />
                <Typography variant="subtitle2" sx={styles.info}>
                  If you pay from credit, you can save {currentOrderState[category].price - (Math.round((currentOrderState[category].number_of_months * currentOrderState[category].credit_price_per_one) * 100) / 100).toFixed(2)}&nbsp;€ (The price per month then will be <strong>{currentOrderState[category].credit_price_per_one}&nbsp;€</strong>).
                  <br/>
                  The credit can be topped up on "My Account" page.
                </Typography>
            </Box>
          </Paper>
          <br/>
        </>
      );
    }
    return null;
  }

  /* Base/default price in use */
  if (currentOrderState[category].used_price_per_one === 'price_per_one') {
    /* TO DO */
    if (currentOrderState[category].bulk_price_per_one
      && currentOrderState[category].bulk_price_duration
      && currentOrderState[category].credit_price_per_one
      && (currentOrderState[category].bulk_price_per_one < currentOrderState[category].price_per_one
        || currentOrderState[category].credit_price_per_one < currentOrderState[category].price_per_one
      )
    ) {
      return (
        <>
          <Paper sx={styles.paper} elevation={3}>
            <Box sx={styles.container}>
                <InfoIcon sx={styles.infoIcon} />
                <Typography variant="subtitle2" sx={styles.info}>
                  If you buy the service for at least {currentOrderState[category].bulk_price_duration} months in total, the price per month will be <strong>{currentOrderState[category].bulk_price_per_one}&nbsp;€</strong>.
                  <br/>
                  The second option is to pay from credit, the price per month will {currentOrderState[category].credit_price_per_one === currentOrderState[category].bulk_price_per_one && "also"} be <strong>{currentOrderState[category].credit_price_per_one}&nbsp;€</strong>. The credit can be topped up on "My Account" page.
                </Typography>
            </Box>
          </Paper>
          <br/>
        </>
      );
    } else if (currentOrderState[category].bulk_price_per_one
      && currentOrderState[category].bulk_price_duration
      && currentOrderState[category].bulk_price_per_one < currentOrderState[category].price_per_one
    ) {
      return (
        <>
          <Paper sx={styles.paper} elevation={3}>
            <Box sx={styles.container}>
                <InfoIcon sx={styles.infoIcon} />
                <Typography variant="subtitle2" sx={styles.info}>If you buy the service for at least {currentOrderState[category].bulk_price_duration} months in total, the price per month will be <strong>{currentOrderState[category].bulk_price_per_one}&nbsp;€</strong>.</Typography>
            </Box>
          </Paper>
          <br/>
        </>
      );
    } else if (currentOrderState[category].credit_price_per_one
        && currentOrderState[category].credit_price_per_one < currentOrderState[category].price_per_one
      ) {
      return (
        <>
          <Paper sx={styles.paper} elevation={3}>
            <Box sx={styles.container}>
                <InfoIcon sx={styles.infoIcon} />
                <Typography variant="subtitle2" sx={styles.info}>
                  If you pay from credit, you can save {currentOrderState[category].price - (Math.round((currentOrderState[category].number_of_months * currentOrderState[category].credit_price_per_one) * 100) / 100).toFixed(2)}&nbsp;€ (The price per month then will be <strong>{currentOrderState[category].credit_price_per_one}&nbsp;€</strong>).
                  <br/>
                  The credit can be topped up on "My Account" page.
                </Typography>
            </Box>
          </Paper>
          <br/>
        </>
      );
    }
  }

  return null;
}

export default SummaryPaymentMethodAdvice;