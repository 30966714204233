import React from 'react';
import PropTypes from 'prop-types';
/* Material UI */
import Button from '@mui/material/Button';
/* Styles */
import { styles } from './CustomButton.styles';

const CustomButton = ({ 
    children,
    ...otherProps
}) => (
    <Button
        variant="contained"
        sx={styles.button}
        {...otherProps}
    >
        {children}
    </Button>
);

CustomButton.defaultProps = {
    color: 'primary',
    disabled: false,
    onClick: () => {}
};

CustomButton.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired, // string or <Icon /> in case of ↑ (scrollToTop) button
    onClick: PropTypes.func,
};


export default CustomButton;