import React from 'react';
/* Material UI */
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
/* Components */
import UnitRowHeader from './UnitRowHeader';
import UnitRowInfo from './UnitRowInfo';
import UnitRowSwitch from './UnitRowSwitch';

/* Styles */
const styles = {
    listItem: {
        width: 'fit-content',
        minWidth: '100%',
        display: 'list-item',
        padding: '8px',
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'secondary.medium',
        '&:nth-of-type(1)': {
            paddingTop: 0
        },
        '& > .MuiTypography-displayBlock': {
            display: 'flex'
        }
    },
    expired: {
        backgroundColor: 'secondary.ultraLight'
    }
};

const UnitRow = ({ unit }) => {

    // Check of remote management service expiration
    let expired = (unit.services && unit.services.length > 0) ? new Date(unit.services[0].date_to) < new Date() : true;

    // No information about versions means there are no data at all and unit can be treated as expired
    if (!expired) {
        if (unit.version_hw == null && unit.version_sw == null) {
            expired = true;
        }
    }

    return (
        <ListItem alignItems="flex-start" sx={ Object.assign({}, styles.listItem, expired && styles.expired)}>
            <Grid container style={{flexWrap: 'nowrap', width: '100%', minWidth: '1200px'}} xs={12}>
                
                {/* Header - first column */}
                <UnitRowHeader 
                    unit={unit} 
                    expired={expired}
                />

                {/* Info table */}
                <UnitRowInfo 
                    unit={
                        /* Use sensor_data if SW verison is higher than 3.0 */
                        (unit.version_sw && parseInt(unit.version_sw.charAt(0)) >= 3)
                        ? 
                            { ...unit.sensor_data, version_sw: unit.version_sw }
                        : 
                            {
                                fails_shot: unit.fails_shot,
                                fails_upload: unit.fails_upload,
                                files_from_cam: unit.files_from_cam,
                                files_uploaded: unit.files_uploaded,
                                temperature: unit.temperature,
                                uptime: unit.uptime,
                                voltage: unit.voltage,
                                version_sw: unit.version_sw
                            } 
                    }
                    expired={expired}
                />


                <Grid item xs={1}></Grid>

                {/* Switch */}
                <UnitRowSwitch 
                    unit={{
                        id: unit.id,
                        online: unit.online,
                        pwr_mode: unit.pwr_mode,
                        stay_online: unit.stay_online,
                        serial_number: unit.serial_number,
                        timezone: unit.timezone,
                        timestamp: unit.timestamp,
                        version_hw: unit.version_hw,
                        wakeup_times: unit.wakeup_times
                    }}
                    expired={expired} 
                />

            </Grid>

        </ListItem>
    );
}

export default UnitRow;