import React, { useState, useContext } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
/* Components */
import StripePaymentForm from '../../Services/StripePaymentForm';
import CustomModal from '../../lib/CustomModal/CustomModal';
/* Global state */
import { OrderContext } from '../../../Context/OrderContext';
import { UserContext } from '../../../Context/UserContext';

const StripeModal = ({ open, handleClose, onSuccess, onError, price, serviceType }) => {

    const { t } = useTranslation();
    // Stripe
    const elements = useElements();
    const stripe = useStripe();

    const [state, setState] = useState({
        cardNameHolder: "",
        email: "",
        error: ""
    });

    const { user } = useContext(UserContext);
    const currentOrderState = useContext(OrderContext);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return
        }
    
        // Create payment intent on the server
        const { error: backendError, clientSecret } = await fetch('https://api.bixion.com/stripe/create-payment-intent', { //URL
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentMethodType: 'card',
                amount: price,
                currency: 'eur',
                metadata: {
                    user: `${user.username} (ID: ${user.id})`,
                    order: `${JSON.stringify(currentOrderState[serviceType].order)}`
                }
            }),
        }).then(r => r.json());
        
        if (backendError) {
            console.log(backendError.message);
            onError(backendError.message);
            return;
        }

        // Confirm the payment on the client
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                },
            }
        )

        if (stripeError) {
            console.log(stripeError);
            onError(stripeError.message);
            return;
        }
        else {
            onSuccess(paymentIntent);
        }
        
    }
    
    return (
        <CustomModal
            buttons={[
                {
                    action: handleSubmit,
                    label: t('button.save')
                },
            ]}
            content={(
                <form onSubmit={handleSubmit}>
                    <StripePaymentForm state={state} setState={setState} />
                </form>
            )}
            handleClose={handleClose}
            open={open}
            title={t('services.summary.title.checkout').toUpperCase()}
        />
    );
}

export default StripeModal;