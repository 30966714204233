import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
/* Material UI */
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
/* Components */
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../../lib/CustomSelectField/CustomSelectField';
import CustomModal from '../../lib/CustomModal/CustomModal';
/* Styles */
import { styles } from './TimelapseIntervalModal.styles';
import moment from 'moment';

const wdaysList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const sortDays = (a,b) => {
    return wdaysList.indexOf(a) - wdaysList.indexOf(b);
}


const TimelapseIntervalModal = ({
    handleClose,
    open,
    state,
    setState
}) => {

    const { t } = useTranslation();
    const [copiedState, setCopiedState] = useState({...state});
    const [from, setFrom] = useState(moment(`2022-01-01T${copiedState.ifrom}:00.000Z`));
    const [to, setTo] = useState(moment(`2022-01-01T${copiedState.ito}:00.000Z`));
    const [error, setError] = useState(null);

    /**
     * Handle change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setCopiedState({...copiedState, [event.target.name]: event.target.value})
    }

    /**
     * Handle submit
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        
        event.preventDefault();

        const ifrom = parseInt(from.toISOString().substring(11, 16).slice(0,2)) * 3600 + parseInt(from.toISOString().substring(11, 16).slice(3,5)) / 60 * 3600;
        const ito = parseInt(to.toISOString().substring(11, 16).slice(0,2)) * 3600 + parseInt(to.toISOString().substring(11, 16).slice(3,5)) / 60 * 3600

        if (ito <= ifrom) {
            setError("The interval cannot span over midnight.");
            return;
        }
        else {
            setError(null);
        }
        
        setState((state.hasOwnProperty("suffix") && state.hasOwnProperty("upload")) ? {
            ifrom: ifrom,
            ito: ito,
            period: copiedState.timeUnit === "mins" ?  parseInt(copiedState.period) * 60 : parseInt(copiedState.period),
            wdays: copiedState.wdays,
            suffix: copiedState.suffix,
            upload: copiedState.upload
        } :
        {
            ifrom: ifrom,
            ito: ito,
            period: copiedState.timeUnit === "mins" ?  parseInt(copiedState.period) * 60 : parseInt(copiedState.period),
            wdays: copiedState.wdays,
        }
        );
        
        handleClose();
    }

    const content = (
        <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={6} style={{marginTop: '8px'}}>
                {/* From */}
                <MobileTimePicker
                    label="FROM"
                    name="from"
                    ampm={false}
                    value={from}
                    onChange={setFrom}
                    renderInput={(params) => <TextField sx={styles.formControl} {...params} />}
                    DialogProps={{
                        PaperProps: {
                            sx: { "& *:focus": { outline: "none" } }
                        }
                    }}
                />
            </Grid>

            <Grid item xs={6} style={{marginTop: '8px'}}>
                {/* To */}
                <MobileTimePicker
                    label="TO"
                    name="to"
                    ampm={false}
                    value={to}
                    onChange={setTo}
                    renderInput={(params) => <TextField sx={styles.formControl} {...params} />}
                    DialogProps={{
                        PaperProps: {
                            sx: { "& *:focus": { outline: "none" } }
                        }
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                {/* Period */}
                <CustomTextField 
                    name="period"
                    value={copiedState.period}
                    handleChange={handleChange}
                    label="PERIOD"
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment />,
                        inputProps: { min: 0 }
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                {/* Time unit */}
                <CustomSelectField
                    name="timeUnit"
                    value={copiedState.timeUnit}
                    handleChange={handleChange}
                    label="TIME UNIT"
                >
                    <MenuItem value="mins">Minutes</MenuItem>
                    <MenuItem value="secs">Seconds</MenuItem>
                </CustomSelectField>
            </Grid>

            <Grid item xs={12} style={{margin: '8px 0'}}>
                <FormControl sx={styles.formControl}>
                    <InputLabel htmlFor="select-multiple-chip">WEEKDAYS</InputLabel>
                    <Select
                        multiple
                        value={copiedState.wdays}
                        onChange={ (event) => {
                            setCopiedState({ ...copiedState, wdays: event.target.value });
                        }}
                        MenuProps={{
                            getContentAnchorEl: () => null,
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                            <Box sx={styles.chipsList}>
                                {selected.sort(sortDays).map(value => (
                                    <Chip key={value} label={value} sx={styles.chip} />
                                ))}
                            </Box>
                        )}
                    >
                        {wdaysList.map(day => {
                            if (day === "Mon")
                                return <MenuItem key={day} value={day}>Monday</MenuItem>
                            else if (day === "Tue")
                                return <MenuItem key={day} value={day}>Tuesday</MenuItem>
                            else if (day === "Wed")
                                return <MenuItem key={day} value={day}>Wednesday</MenuItem>
                            else if (day === "Thu")
                                return <MenuItem key={day} value={day}>Thursday</MenuItem>
                            else if (day === "Fri")
                                return <MenuItem key={day} value={day}>Friday</MenuItem>
                            else if (day === "Sat")
                                return <MenuItem key={day} value={day}>Saturday</MenuItem>
                            else
                                return <MenuItem key={day} value={day}>Sunday</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            {state.hasOwnProperty("suffix") ? 
                <Grid item xs={6}>
                    {/* Interval name (suffix) */}
                    <CustomSelectField
                        name="suffix"
                        value={copiedState.suffix}
                        handleChange={handleChange}
                        label="INTERVAL NAME"
                    >
                        <MenuItem value={""}>Standard</MenuItem>
                        <MenuItem value="-rush">Rush</MenuItem>
                    </CustomSelectField>
                </Grid> : null
            }

            {state.hasOwnProperty("upload") ? 
                <Grid item xs={6}>
                    <CustomSelectField
                        name="upload"
                        value={copiedState.upload}
                        handleChange={handleChange}
                        label="UPLOAD"
                    >
                        <MenuItem value={false}>Skip</MenuItem>
                        <MenuItem value={true}>Upload</MenuItem>
                    </CustomSelectField>
                </Grid> : null
            }

        </Grid>
    );
    
    return (
        <CustomModal
            buttons={[
                {
                    action: handleSubmit,
                    label: t('button.save')
                },
            ]}
            content={content}
            error={error}
            handleClose={handleClose}
            open={open}
            title={t('remoteConnection.title.timelapseInterval').toUpperCase()}
            titleIcon="timer"
        />
    );
}

export default TimelapseIntervalModal ;