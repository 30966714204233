import { atom, selector } from 'recoil';

/**
 * Server time
 */
export const serverTimeState = atom({
    key: "serverTime",
    default: new Date().getTime() / 1000
});

export const updateServerTimeState = selector({
    key: "updateServerTime",
    get: ({ get }) => { },
    set: ({ set }) => {
        set(serverTimeState, new Date().getTime() / 1000);
    }
})


/**
 * Brief units
 */
export const briefUnitsState = atom({
    key: "briefUnits",
    default: []
})

export const updateBriefUnitState = selector({
    key: "updateUnitBrowser",
    get: ({ get }) => { },
    set: ({ get, set }, data ) =>  {
        let briefUnitsCopy = [...get(briefUnitsState)];
        let briefUnitIndex = briefUnitsCopy.findIndex(unit => unit.id === data.unit_id);

        /* Check if there is a record in services */
        let formerDateTo = briefUnitsCopy[briefUnitIndex].services.length > 0 ? new Date(briefUnitsCopy[briefUnitIndex].services[0].date_to) : new Date();
            
        /* Unit not expired yet */
        if (formerDateTo >= new Date()) {
            formerDateTo.setDate(formerDateTo.getDate() + data.days);
        }
        /* Unit already expired */
        else {
            let today = new Date();
            formerDateTo = today;
            formerDateTo.setDate(today.getDate() + data.days);
        }
        
        briefUnitsCopy[briefUnitIndex] = {
            ...briefUnitsCopy[briefUnitIndex],
            date_to: formerDateTo.toISOString().slice(0, 10)
        };

        set(briefUnitsState, briefUnitsCopy);
    },
})


/**
 * Brief browsers
 */
export const briefBrowsersState = atom({
    key: "briefBrowsers",
    default: []
});

export const addBriefBrowserState = selector({
    key: "addBriefBrowser",
    get: ({ get }) => { },
    set: ({ get, set }, newBrowser ) =>  {
        let briefBrowsersCopy = [...get(briefBrowsersState)];
        set(briefBrowsersState, [...briefBrowsersCopy, newBrowser])
    },
})

export const updateBriefBrowserState = selector({
    key: "updateBriefBrowser",
    get: ({ get }) => { },
    set: ({ get, set }, data ) =>  {
        let briefBrowsersCopy = [...get(briefBrowsersState)];
        let briefBrowserIndex = briefBrowsersCopy.findIndex(browser => browser.id === data.browser_id);

        /* Check if there is a record in services */
        let formerDateTo = briefBrowsersCopy[briefBrowserIndex].services.length > 0 ? new Date(briefBrowsersCopy[briefBrowserIndex].services[0].date_to) : new Date();
            
        /* Browser not expired yet */
        if (formerDateTo >= new Date()) {
            formerDateTo.setDate(formerDateTo.getDate() + data.days);
        }
        /* Browser already expired */
        else {
            let today = new Date();
            formerDateTo = today;
            formerDateTo.setDate(today.getDate() + data.days);
        }
        //console.log("briefBrowserIndex", briefBrowserIndex, data);
        
        briefBrowsersCopy[briefBrowserIndex] = {
            ...briefBrowsersCopy[briefBrowserIndex],
            date_to: formerDateTo.toISOString().slice(0, 10)
        };
        //console.log("briefBrowsersCopy", briefBrowsersCopy);

        set(briefBrowsersState, briefBrowsersCopy);
    },
})


/**
 * Units
 */
export const unitsState = atom({
    key: "units",
    default: []
});

export const updateUnitState = selector({
    key: "updateUnit",
    get: ({ get }) => { },
    set: ({ get, set }, data ) =>  {
        let unitsCopy = [...get(unitsState)];
        //console.log("unitsCopy", unitsCopy);
        let unitIndex = unitsCopy.findIndex(unit => unit.serial_number === data.serial_number);
        

        if (unitIndex === -1) {
            //console.log("updateUnitState -1");
            return;
        }

        //console.log("unitIndex not -1", unitIndex, data);

        if (data.data["sensor_data"] !== undefined) {

            unitsCopy[unitIndex] = {
                ...unitsCopy[unitIndex],
                sensor_data: {
                    ...unitsCopy[unitIndex].sensor_data,
                    ...data.data.sensor_data
                }
            }

            delete data.data.sensor_data;
        }

        if (data.data["online"] !== undefined) {
            if (typeof data.data.online === "object") {
                //console.log("object")
                unitsCopy[unitIndex] = {
                    ...unitsCopy[unitIndex],
                    online: {
                        ...data.data.online
                    }
                }
            }
            else {
                //console.log("string")
                unitsCopy[unitIndex] = {
                    ...unitsCopy[unitIndex],
                    online: data.data.online
                }
            }

            delete data.data.online;
        }
        
        unitsCopy[unitIndex] = {
            ...unitsCopy[unitIndex],
            ...data.data
        };

        set(unitsState, unitsCopy);
    },
})