import React from 'react';
/* Material UI */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    },
    container: {
        display: 'flex',
        alignItems: 'end',
        padding: '0 0px',
        gap: '16px',
        '@media (max-width: 1192px)': {
            alignItems: 'start',
        }
    },
    infoIcon: {
        color: 'secondary.main'
    },
    info: {
        fontWeight: 'bold',
        color: 'secondary.main'
    }
}

const PaymentComplaintInfo = () => {

    return (
        <Paper sx={styles.paper} elevation={3}>
            <Box sx={styles.container}>
                <InfoIcon sx={styles.infoIcon} />
                <Typography variant="subtitle2" sx={styles.info}>In case of any discrepancies, contact us within 7 days of payment. Subsequent complaints will not be taken into account.</Typography>
            </Box>
        </Paper>
    );
}

export default PaymentComplaintInfo;