import Axios from "axios";
import { getTokenFromLocalStorage } from './localStorage';

const BackendRequest = (method, actionURL, data, onSuccess, onError) => {

    const ApiUrlBase = "https://api.bixion.com/is"; //URL
    data = {...data};

    //console.log("BackendRequest", method, actionURL, data);

    Axios({
        method: method,
        url: ApiUrlBase + actionURL,
        data: data,
        headers: {
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
        },
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        if (error.response !== undefined) {
            if (error.response.status === 401 || error.response.status === 403)
                console.log("401 UNAUTHORIZED OR 403 FORBIDDEN");
            onError(error.response);
        }
    });

}

export default BackendRequest;