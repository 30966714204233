import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { withRouter} from "react-router-dom";
/* Material UI */
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
/* Components */
import CustomButton from '../lib/CustomButton/CustomButton';
/* Global state */
import { SnackbarContext } from '../../Context/SnackbarContext';
/* Utils */
import axios from 'axios';
import { getTokenFromLocalStorage } from '../../Utils/localStorage';
import CloudAccountModal from '../modals/CloudAccountModal/CloudAccountModal';
import CustomDialog from '../lib/CustomDialog/CustomDialog';

/* Styles */
const styles = {
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(133,133,133,.05)',
        },
    }
};


const AccountTableRow = ({
    account,
    parentStyles,
    propagateChangeToState,
    propagateRemoveToState,
}) => {
    const [isSyncing, setIsSyncing] = useState(false);
    const [cloudAccountModalOpen, setCloudAccountModalOpen] = useState(false);
    const [removeAccountDialog, setRemoveAccountDialog] = useState(null);
    const history = useHistory();
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);

    /**
     * Remove account
     * @param {*} iid 
     */
    const handleRemove = (iid) => {
        axios.delete(`https://api.bixion.com/bcloud/account/${iid}`, { headers: {
            'Accept': 'application/json', 
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`,    
            'Content-Type': 'application/json',
        }})
        .then((response) => {
            setRemoveAccountDialog(null);
            propagateRemoveToState(iid);
            setSnack({ message: t('snackbar.message.accountRemoved'), type: "info", open: true});
        })
        .catch((error) => {
            setSnack({ message: t('snackbar.message.failedToRemoveAccount'), type: "error", open: true});
            console.log(error.response);
        });
    };

    /**
     * Sync account
     * @param {*} iid 
     */
    const sync = (iid) => {
        setIsSyncing(true);
        axios.post(`https://api.bixion.com/bcloud/cloud/sync/${iid}`, null ,{ headers: {
            'Accept': 'application/json', 
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`,    
            'Content-Type': 'application/json',
        }})
        .then((response) => {
            propagateChangeToState({
                ...account,
                in_sync: true,
            }, false);
            setIsSyncing(false);
            setSnack({ message: t('snackbar.message.accountSynced'), type: "info", open: true});
        })
        .catch((error) => {
            setIsSyncing(false);
            setSnack({ message: t('snackbar.message.failedToSync'), type: "error", open: true});
            console.log(error.response);
        });
    }

    return (
        <TableRow sx={styles.row}>
            <TableCell sx={[parentStyles.column, parentStyles.firstColumn]}>
                {account.first_name + " " + account.last_name}
            </TableCell>
            <TableCell sx={[parentStyles.column, parentStyles.secondColumn]}>
                {account.username}
            </TableCell>
            <TableCell sx={[parentStyles.column, parentStyles.thirdColumn]}>
                {account.quota}
            </TableCell>
            <TableCell sx={[parentStyles.column, parentStyles.fourthColumn]} align="center">
                {account.active ? <CheckCircleRoundedIcon color="success" /> : <CancelRoundedIcon color="error" />}
            </TableCell>
            <TableCell 
                align="right"
                sx={[parentStyles.fifthColumn, { display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: '6px' }]}
            >
                {!account.in_sync && (
                    <CustomButton
                        disabled={isSyncing}
                        onClick={() => sync(account.id)}
                        startIcon={<SyncIcon />}
                    >
                        Sync
                    </CustomButton>
                )}
                <CustomButton
                    color="secondary"
                    onClick={() => setCloudAccountModalOpen(true)}
                    startIcon={<EditIcon />}
                >
                    Edit
                </CustomButton>
                <CustomButton
                    color="secondary"
                    onClick={() => setRemoveAccountDialog(account)}
                    startIcon={<DeleteIcon />}
                >
                    Remove
                </CustomButton>
            </TableCell>

            {cloudAccountModalOpen &&
                <CloudAccountModal
                    account={account}
                    handleClose={() => setCloudAccountModalOpen(false)}
                    open={cloudAccountModalOpen}
                    propagateChangeToState={propagateChangeToState}
                />
            }

            {removeAccountDialog != null ? <CustomDialog
                buttons={[
                    {
                        action: () => handleRemove(removeAccountDialog.id),
                        label: t('button.remove')
                    },
                ]}
                content={t('cloud.dialog.label.doYouReallyWantToRemoveThisAccount')}
                handleClose={() => setRemoveAccountDialog(null)}
                open={removeAccountDialog != null}
                title={t(
                    'cloud.dialog.title.removeAccount',
                    { account: removeAccountDialog.first_name + " " + removeAccountDialog.last_name }
                )}
            /> : null}
        </TableRow>
    );
}

export default withRouter(AccountTableRow)