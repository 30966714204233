import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
/* Material UI */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
/* Global state */
import { SnackbarContext } from '../../../../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../../../../Utils/BackendRequest';
import EmailListItem from './EmailListItem';
import Spinner from '../../../lib/CustomSpinner/CustomSpinner';

/* Styles */
const styles = {
    unitItem: {
        display: 'block',
        padding: '12px 0'
    },
    unitItemTitle: {
        color: 'primary.main',
        fontSize: 20,
        fontWeight: 700
    },
    permissionItem: {
        backgroundColor: 'secondary.ultraLight',
        marginBottom: '4px',
        borderRadius: '4px'
    },
    permissionItemInside: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    permissionItemName: {
        color: 'black',
        fontSize: 18,
        fontWeight: 600
    },
    iconButton: {
        padding: '8px'
    }
};

const BrowserUnitItem = ({ brUnit }) => {
  
    const history = useHistory();
    const { setSnack } = useContext(SnackbarContext);
    const { t } = useTranslation();
    const [ permissions, setPermissions ] = useState(null);

    const getPermissions = () => {
        const onSuccess = (res) => {
            setPermissions(res.data.permissions);
        }
        const onError = (error) => {
            console.log(`error in /bdrive/${brUnit.unit_id}`, error);
            if ((error.status === 401 || error.status === 403)) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        BackendRequest("get", `/bdrive/${brUnit.unit_id}`, null, onSuccess, onError);
    }

    const removePermission = (permissionId) => {
        const onSuccess = (res) => {
            setSnack({ message: "Permission removed.", type: "info", open: true});
            getPermissions();
        }
        const onError = (error) => {
            console.log(`error in /bdrive/${brUnit.unit_id}`, error);
            if ((error.status === 401 || error.status === 403)) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        BackendRequest("post", `/bdrive/${brUnit.unit_id}/remove`, { permisson_id: permissionId }, onSuccess, onError);
    }

    useEffect(() => {
        getPermissions();
    }, [])

    return (
        <ListItem key={brUnit.unit_id} sx={styles.unitItem}>
            <ListItemText primary={
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <Typography sx={styles.unitItemTitle}>
                        {brUnit.browser_unit_name.toUpperCase()}
                    </Typography>
                    &nbsp;
                    <Typography>
                        ({brUnit.unit_serial})
                    </Typography>
                </Box>
            }
            />
            {permissions ?
                <List>
                    {permissions ? permissions.map((permission) => {    
                        return (
                            <ListItem key={permission.id} sx={styles.permissionItem}>
                                <ListItemText 
                                    primary={
                                        <Box sx={styles.permissionItemInside}>
                                            <Box>
                                                <Typography sx={styles.permissionItemName}>
                                                    {permission.name}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    ({permission.email})
                                                </Typography>
                                            </Box>
                                            {permission.role !== "owner" ?
                                                <Tooltip title={t('customers.overview.tooltip.delete')}>
                                                    <IconButton sx={styles.iconButton} aria-label="delete" onClick={() => removePermission(permission.id)}>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Tooltip> : null}
                                        </Box>
                                    }           
                                />
                            </ListItem>
                        );
                    }) : null }
                    <EmailListItem 
                        unitId={brUnit.unit_id}
                        updatePermissions={getPermissions}
                    />
                </List> : <Spinner style={{marginTop: '16px'}} />}
        </ListItem>
                             
    );
}

export default BrowserUnitItem;