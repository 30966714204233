import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
/* Material UI */
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { Box, Divider } from '@mui/material';
/* Components */
import { countries } from '../../Config/countries';
import CustomButton from '../lib/CustomButton/CustomButton';
import CustomTextField from '../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../lib/CustomSelectField/CustomSelectField';
import TopUpCreditModal from '../modals/TopUpCreditModal/TopUpCreditModal';
/* Global state */
import { UserContext } from '../../Context/UserContext';
import { UPDATE_USER } from '../../Context/UserContext';
import { SnackbarContext } from '../../Context/SnackbarContext';
import { OrderActionsContext } from '../../Context/OrderContext';
import { UPDATE_CREDIT } from '../../Context/reducers/orderReducer';
/* Utils */
import BackendRequest from '../../Utils/BackendRequest';

/* Styles */
const styles = {
    bottom: {
        paddingBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-end'
        }
    },
    smallerDivider: {
        marginTop: '8px',
        marginBottom: '8px',
        height: '2px'
    },
    typography: {
        fontWeight: 600,
        '@media (max-width: 767px)': {
            padding: '16px 0'
        }
    }
};

const stripePromise = loadStripe("pk_live_gsnXMJ0Nv8h8QlUh9jDXt1uo00zf2paHpp");
// const stripePromise = loadStripe("pk_test_HFg1FnXLTeRJ1eb7yEAXD6b700W2aWGyrV");

const UserForm = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);
    const { user, setUser } = useContext(UserContext);
    const dispatchOrder = useContext(OrderActionsContext);
    const [isTopUpCreditModalOpen, setIsTopUpCreditModalOpen] = useState(false);
    const [state, setState] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
        login: user.username,
        email: user.email,
        phone: user.phone,
        vat_identificator: user.vat_identificator,
        vat_state: user.vat_state,
        password: "",
        new_password: "",
        new_password_again: "",
        news_mail_subscription: user.news_mail_subscription
    })
    const [ error, setError ] = useState("");

    /**
     * Handle state change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    /**
     * Validation of email and phone format
     * @returns 
     */
    const validate = () => {
        if (state.email !== null && state.email !== "") {
            if (!/\S+@\S+\.\S+/.test(state.email)) {
                setError("invalidEmailAddress");
                return false;
            }
        }

        if (state.phone !== null && state.phone !== "") {
            if((!/^\+?[\d]{3}[1-9][0-9]{8}$/.test(state.phone.replace(/\s+/g, '')))
                && (!/^[0-9]{9}$/.test(state.phone.replace(/\s+/g, '')))) {
                setError("invalidPhoneNumber");
                return false;
            }
        }
        
        setError("");
        return true;
    }

    /**
     * Save user changes with password
     */
    const changePassword = () => {

        const data = {
            id: user.id,
            first_name: state.first_name,
            last_name: state.last_name,
            login: state.login,
            email: state.email,
            phone: state.phone,
            vat_identificator: state.vat_identificator,
            vat_state: state.vat_state,
            password: state.new_password,
            news_mail_subscription: state.news_mail_subscription
        }

        const onUserSuccess = (response) => {
            setUser({
                type: UPDATE_USER,
                user: data
            })
            setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            window.history.back();
        }

        const onUserError = (error) => {
            console.log('error in /user/<user-id>');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }

        BackendRequest("post", "/user/" + user.id, data, onUserSuccess, onUserError);
    }

    /**
     * Save user changes with password
     * @param {*} event 
     * @returns 
     */
    const submit = (event) => {
        
        event.preventDefault();

        /* Email and phone format validation */
        if (!validate()) return;

        /* Passwords validation */
        if (state.password !== ""){
            
            if (state.new_password === "") {
                setError("newPasswordMissing");
                return;
            }
            else if (state.new_password_again === "") {
                setError("newPasswordConfirmationMissing");
                return;
            }
            else if (state.new_password !== state.new_password_again){
                setError("newPasswordsNotSame");
                return;
            }
            else {
                setError("");
                
                const onSuccess = (response) => {
                    changePassword();
                }
                const onError = (error) => {
                    console.log('error in /login');
                    if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem("token");
                        history.push({ pathname: '/login', state: { showSnack: true }});
                    }
                    setError("wrongLoginData");
                }
                
                BackendRequest("post", "/login", {username: user.username, password: state.password}, onSuccess, onError);
            }
        }

        else {
            const data = {
                id: user.id,
                first_name: state.first_name,
                last_name: state.last_name,
                login: state.login,
                email: state.email,
                phone: state.phone,
                vat_identificator: state.vat_identificator,
                vat_state: state.vat_state,
                news_mail_subscription: state.news_mail_subscription
            }
            
            const onSuccess = (response) => {
                setUser({
                    type: UPDATE_USER,
                    user: data
                })
                setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
                window.location.replace("/");
            }
            const onError = (error) => {
                console.log('error in /user/<user-id>');
                if (error.status === 401 || error.status === 403) {
                    localStorage.removeItem("token");
                    history.push({ pathname: '/login', state: { showSnack: true }});
                }
            }
            
            BackendRequest("post", "/user/" + user.id, data, onSuccess, onError);
        }
    }

    const handleCreditTopUp = (amount) => {
        const onSuccess = (response) => {
            setUser({
                type: UPDATE_USER,
                user: { credit: user.credit ? user.credit + amount : amount }
            })
            dispatchOrder({
                type: UPDATE_CREDIT,
                credit: user.credit ? user.credit + amount : amount,
            })
            setSnack({ message: t('snackbar.message.creditHasBeenToppedUp'), type: "info", open: true});
            setIsTopUpCreditModalOpen(false);
        }
        const onError = (error) => {
            console.log('error in /pays/credit-topup');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/pays/credit-topup", { value: amount }, onSuccess, onError);
    }

    return (
        <form onSubmit={submit}>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* First name */}
                    <CustomTextField 
                        name="first_name"
                        value={state.first_name}
                        handleChange={handleChange}
                        label={t('customers.settings.label.firstName').toUpperCase()}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Last name */}
                    <CustomTextField 
                        name="last_name"
                        value={state.last_name}
                        handleChange={handleChange}
                        label={t('customers.settings.label.lastName').toUpperCase()}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Email */}
                    <CustomTextField 
                        name="email"
                        value={state.email}
                        error={error==="invalidEmailAddress"}
                        handleChange={handleChange}
                        label="EMAIL"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Phone */}
                    <CustomTextField 
                        name="phone"
                        value={state.phone}
                        error={error==="invalidPhoneNumber"}
                        handleChange={handleChange}
                        label={t('customers.settings.label.phone').toUpperCase()}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    {/* Country */}
                    <CustomSelectField
                        name="vat_state"
                        value={state.vat_state}
                        handleChange={handleChange}
                        label={t('customers.settings.label.country').toUpperCase()}
                    >
                        {countries.map((country, index) => {
                            return <MenuItem key={index} selected={country.label === state.vat_state ? true : false} value={country.label}>{country.label}</MenuItem>
                        })}
                        <MenuItem value={"NOT SET"}></MenuItem>
                    </CustomSelectField>
                </Grid>

                <Grid item xs={12} md={6}>
                    {/* VAT ID */}
                    <CustomTextField 
                        name="vat_identificator"
                        value={state.vat_identificator}
                        handleChange={handleChange}
                        label={t('customers.settings.label.vatId')} 
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    {/* Login */}
                    <CustomTextField 
                        name="login"
                        value={state.login}
                        error={error === "wrongLoginData"}
                        handleChange={handleChange}
                        label="LOGIN"
                        required
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Current password */}
                    <CustomTextField 
                        name="password"
                        value={state.password}
                        error={error === "wrongLoginData"}
                        handleChange={handleChange}
                        label={t('customers.settings.label.currentPassword').toUpperCase()} 
                        type="password"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* New password */}
                    <CustomTextField 
                        name="new_password"
                        value={state.new_password}
                        error={error === "newPasswordMissing" || error === "newPasswordsNotSame"}
                        handleChange={handleChange}
                        label={t('customers.settings.label.newPassword').toUpperCase()}
                        type="password"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* New password confirmation */}
                    <CustomTextField 
                        name="new_password_again"
                        value={state.new_password_again}
                        error={error === "newPasswordConfirmationMissing" || error === "newPasswordsNotSame"}
                        handleChange={handleChange}
                        label={t('customers.settings.label.newPasswordConfirmation').toUpperCase()}
                        type="password"
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: '16px' }}>
                    <Typography variant="subtitle1" color="secondary"  sx={{ fontSize: '20px', fontWeight: 600, lineHeight: 1 }}>
                        Subscriptions
                    </Typography>
                    <Divider sx={styles.smallerDivider} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                            Subscribe for email notifications
                        </Typography>
                        <Switch
                            //sx={styles.switch}
                            checked={state.news_mail_subscription}
                            onChange={() => {
                                setState({
                                    ...state,
                                    news_mail_subscription: !state.news_mail_subscription
                                })
                            }}
                            name="subscription"
                            color="primary"
                        />
                    </Box>
                </Grid>
                
                {(user.type !== 'admin' && user.service_price['Remote management'].price !== 0 && user.service_price['Browser'].price !== 0) && (
                    <>
                        <Grid item xs={12} sx={{ mt: '16px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
                                <Typography variant="subtitle1" color="secondary"  sx={{ fontSize: '20px', fontWeight: 600, lineHeight: 1 }}>
                                    Credit
                                </Typography>
                                <CustomButton color="primary" onClick={() => setIsTopUpCreditModalOpen(true)} style={{textTransform: 'none', padding: '2px 12px'}}>
                                    + Top up credit
                                </CustomButton>
                            </Box>
                            <Divider sx={styles.smallerDivider} />
                            <Typography variant="subtitle1">
                                Current credit:&nbsp;<strong>{user.credit != null ? (Math.round(user.credit * 100) / 100).toFixed(2) : '0.00'}&nbsp;€</strong>
                            </Typography>
                        </Grid>
                        {isTopUpCreditModalOpen && (
                            <Elements stripe={stripePromise}>
                                <TopUpCreditModal
                                    open={isTopUpCreditModalOpen}
                                    handleClose={() => setIsTopUpCreditModalOpen(false)}
                                    onSuccess={handleCreditTopUp}
                                    onError={() => console.log('error')}
                                />
                            </Elements>
                            
                        )}
                    </>
                 )}
            </Grid>

            <br/>
            
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sx={styles.bottom}>
                    
                    <Typography sx={styles.typography} variant="subtitle1" color="error">
                        {error !== "" && t('error.' + error)}
                    </Typography>
                    
                    <div>
                        <CustomButton color="secondary" onClick={() => window.history.back()} style={{marginRight: '8px'}}>
                            {t('profile.button.back')}
                        </CustomButton>
                        <CustomButton color="primary" onClick={e => {e.preventDefault(); submit(e)}} type="submit">
                            {t('profile.button.saveChanges')}
                        </CustomButton>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

export default UserForm;