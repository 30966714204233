import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
/* Material UI */
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
/* Components */
import CustomTextField from '../../../lib/CustomTextField/CustomTextField';
import CustomButton from '../../../lib/CustomButton/CustomButton';
/* Utils */
import BackendRequest from '../../../../Utils/BackendRequest';
/* Global state */
import { SnackbarContext } from '../../../../Context/SnackbarContext';

/* Styles */
const styles = {
    permissionItem: {
        marginBottom: '4px',
        borderRadius: '4px',
        padding: 0,
        paddingTop: '8px'
    },
    permissionItemInside: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
};

const EmailListItem = ({ updatePermissions, unitId }) => {
  
    const history = useHistory();
    const { setSnack } = useContext(SnackbarContext);
    const [ email, setEmail ] = useState("");
    const [ disabled, setDisabled ] = useState(false);
    const [ error, setError ] = useState(false);

    const validateEmail = () => {
        if (email === "") {
            setError(true);
            return false;
        }
        else if (!/\S+@\S+\.\S+/.test(email)) {
            setError(true);
            return false;
        }
        else {
            setError(false);
            return true;
        }
    }

    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    const handleShare = () => {    

        if (!validateEmail()) return;

        setDisabled(true);
        
        const onSuccess = (response) => {
            setSnack({ message: "Permission granted.", type: "info", open: true});
            updatePermissions();
            setDisabled(false);
            setEmail("");
        }
        const onError = (error) => {
            console.log(`error in /bdrive/${unitId}`, error);
            if ((error.status === 401 || error.status === 403)) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", `/bdrive/${unitId}`, { email: email }, onSuccess, onError);
    }

    return (
        <ListItem sx={styles.permissionItem}>
            <ListItemText 
                primary={
                    <Box sx={styles.permissionItemInside}>
                        <CustomTextField 
                            name="email"
                            disabled={disabled}
                            error={error}
                            value={email}
                            handleChange={handleChange}
                            label={"EMAIL"}
                            placeholder={""}
                            style={{maxWidth: '300px'}}
                        />
                        <CustomButton color="primary" onClick={handleShare} style={{marginLeft: '8px'}}>
                            SHARE
                        </CustomButton>
                    </Box>
                }           
            />
        </ListItem>                          
    );
}

export default EmailListItem;