import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
/* Material UI */
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
/* Global state */
import { OrderActionsContext } from '../../../Context/OrderContext';
import { UPDATE_ORDER } from '../../../Context/reducers/orderReducer';
/* Components */
import FolderShareModal from '../../modals/FolderShareModal/FolderShareModal';
import browserBackground from "../../../assets/browser_background.jpg";


/* Styles */
const styles = {
    paper: {
        borderRadius: '8px',
        paddingBottom: '56.25%',
        position: 'relative',
        backgroundPosition: 'center !important', 
        backgroundSize: 'cover !important', 
        backgroundRepeat: 'no-repeat !important',
        backgroundBlendMode: 'darken !important',
        transition: 'background-size .5s ease',
        '& .iconWrapper': {
            visibility: 'hidden'
        },
        '&:hover .iconWrapper': {
            visibility: 'visible'
        },
        '@media (max-width:992px)': {
            '& .iconWrapper': {
                visibility: 'visible'
            }
        }
    },
    inside: {
        padding: '12px',
        display: 'flex', 
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '8px',
        '-webkit-line-clamp': '2',
        height: '3em',
        '@media (max-width: 480px)': {
            fontSize: 18,
            lineHeight: 1.35,
            height: '2.7em'
        }
    },
    icon: {
        fontSize: '2rem',
        color: 'rgba(0,0,0,.75)',
        '@media (max-width: 767px)': {
            fontSize: '1.75rem',
        },
        '@media (max-width: 480px)': {
            fontSize: '1.5rem',
        }
    },
    activeIconWrapper: {
        backgroundColor: 'primary.main',
        width: 'fit-content',
        padding: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginBottom: '8px',
        
        '&:hover': {
            cursor: 'pointer'
        }
    },
    expiredIconWrapper: {
        backgroundColor: 'secondary.main',
        width: 'fit-content',
        padding: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginBottom: '8px',
        
        '&:hover': {
            cursor: 'pointer'
        }
    },
};

const BrowserCard = ({ browser }) => {
     
    const { t } = useTranslation();
    const history = useHistory();

    const [folderShareModalOpen, setFolderShareModalOpen] = useState(false);

    const dispatchOrder = useContext(OrderActionsContext);

    //console.log("[BrowserCard] browser", browser);
    const expired = new Date(browser.date_to) < new Date();

    /**
     * On click open dialog window with all units to choose from
     */
    const handleClickDialogOpen = () => {
        setFolderShareModalOpen(true);
    };

    /**
     * On click close dialog window
     */
    const handleDialogClose = () => {
        setFolderShareModalOpen(false);
    };

    /**
     * Update value in OrderContext
     * @param {*} browser
     */
    const dispatchValue = (browser) => {
        dispatchOrder({
            type: UPDATE_ORDER,
            category: "browsers",
            orderItem: {
                browser_id: browser.id,
                browser_name: browser.title,
                date_to: browser.date_to,
                services: [
                    {
                        service_name: "Browser",
                        months: 1,
                    }
                ] 
            }
        })
    }
    
    return (
        <Grid item xs={12} sm={12} md={6} xl={4} xxl={3} style={{padding: '6px', overflow: 'hidden'}}>
            <Paper 
                sx={styles.paper} 
                style={!expired ? {background: `url(${/*browser.preview*/browserBackground}), linear-gradient(360deg, transparent 0%, rgba(30,30,30,.8) 100%)`} : {background: `url(${/*browser.preview*/browserBackground}), linear-gradient(360deg, transparent 0%, rgba(42,42,42,.9) 0%)`}}
                elevation={3}
            >
                <div></div>
                <Box sx={styles.inside}>
                    <Typography sx={styles.title} color={!expired ? "primary" : "secondary"} gutterBottom>
                        {browser.title.toUpperCase()}
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Tooltip className={"iconWrapper"} disableInteractive title={t('browsers.overview.tooltip.settings')}>
                            <Box
                                sx={!expired ? styles.activeIconWrapper : styles.expiredIconWrapper}  
                                onClick={() => {
                                    if (!expired) {
                                        history.push(`/browsers/edit/${browser.id}`);
                                    } 
                                    else {
                                        dispatchValue(browser);
                                        history.push(`/services/browsers`);
                                    }
                                }}
                            >
                                <Icon sx={styles.icon}>settings</Icon>
                            </Box>
                        </Tooltip>
                        <Tooltip className={"iconWrapper"} disableInteractive title={t('browsers.overview.tooltip.photos')}>
                            <Box
                                sx={!expired ? styles.activeIconWrapper : styles.expiredIconWrapper}    
                                onClick={()=> {
                                    if(!expired) {
                                        window.open(`https://time.bixion.com/${browser.url_path}`, "_blank")
                                    }
                                    else {
                                        dispatchValue(browser);
                                        history.push(`/services/browsers`);
                                    }
                                }}
                            >
                                <Icon sx={styles.icon}>theaters</Icon>
                            </Box>
                        </Tooltip>
                        <Tooltip className={"iconWrapper"} disableInteractive title={'Folder sharing'}>
                            <Box
                                sx={!expired ? styles.activeIconWrapper : styles.expiredIconWrapper}    
                                onClick={()=> {
                                    if(!expired) {
                                        handleClickDialogOpen();
                                    }
                                    else {
                                        dispatchValue(browser);
                                        history.push(`/services/browsers`);
                                    }
                                }}
                            >
                                <Icon sx={styles.icon}>folder_shared</Icon>
                            </Box>
                        </Tooltip>
                    </div>
                </Box>
            </Paper>

            {folderShareModalOpen ? <FolderShareModal
                open={folderShareModalOpen} 
                handleClose={handleDialogClose} 
                browserId={browser.id}
            /> : null}
        </Grid>
    );
}

export default BrowserCard;