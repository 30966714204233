export const styles = {
    drawer: {
        width: '240px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: '240px',
        }
    },
    drawerContainer: {
        overflow: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
};
