import { createContext, useState } from "react";
import i18n from 'i18next';
import moment from 'moment';

export const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {

    const [ lang, setLang ] = useState("en");

    const changeLang = (lang) => {
        moment.locale(lang);
        i18n.changeLanguage(lang);
        setLang(lang);
    }

    return <LanguageContext.Provider  value={{lang, changeLang}}>
        {children}
    </LanguageContext.Provider>
};

export default LanguageProvider;