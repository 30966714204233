import React, { useState } from 'react';
import CustomButton from '../lib/CustomButton/CustomButton';
/* Material UI */
import Typography from '@mui/material/Typography';
/* Components */
import Version from './Version/Version';
import VersionModal from '../modals/VersionModal/VersionModal';
import ACLWrapper from '../../Containers/ACLWrapper';

const VersionsOverview = ({ versions }) => {

    const [versionModalOpen, setVersionModalOpen] = useState(false);
    
    return (
        <>
            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <CustomButton
                    color="primary"
                    onClick={() => setVersionModalOpen(!versionModalOpen)}
                    style={{textTransform: 'none', marginBottom: '16px'}}
                >
                    + Add new version
                </CustomButton>
            </ACLWrapper>
            {(versions && versions.length !== 0) 
                ? versions.map(item => <Version {...item} />)
                : <Typography>There is no information about versions yet.</Typography>
            }
            {versionModalOpen &&
                <VersionModal
                    handleClose={() => setVersionModalOpen(false)}
                    open={versionModalOpen}
                />
            }
        </>
    )
}

export default VersionsOverview;