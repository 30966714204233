export const styles = {
    container: {
        padding: 0,
        margin: 0,
        border: '2.25px',
        borderStyle: 'dashed',
        borderColor: 'secondary.medium',
        borderRadius: '4px',
        '@media (max-width:992px)': {
            paddingTop: 0
        },
        '& .MuiDropzoneArea-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '364px',
            border: 'none',
            '@media (max-width: 766px)': {
                minHeight: '180px',
            }
        },
        '& .MuiDropzoneArea-textContainer': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            '&:focus': {
                border: `2.5px solid red` 
            }
        },
        '& .MuiTypography-root': {
            color: 'secondary.main',
            padding: '0 16px',
            margin: '16px 0',
            fontSize: '18px',
            '@media (max-width: 767px)': {
                padding: '0 24px',
                fontSize: '16px',
            },
            '@media (max-width: 480px)': {
                padding: '0 32px',
                fontSize: '14px',
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'secondary.main'
        },
        '& .MuiDropzonePreviewList-root': {
            justifyContent: 'center'
        },
        '& .MuiDropzonePreviewList-imageContainer': {
            maxWidth: '100%',
            '& > div': {
                maxWidth: '100%'
            }
        }
    },
};