import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';
/* Material UI */
import { createTheme } from '@mui/material/styles'
/* Contexts and global state */
import { LanguageContext } from '../Context/LanguageContext';
import { FETCH_USER, UserContext } from '../Context/UserContext';
import { briefUnitsState, briefBrowsersState } from '../Context/globalState';
/* Theme */
import { defaultTheme, colorToRGBAFormat } from '../theme';
/* Utils */
import BackendRequest from './BackendRequest';
import { getTokenFromLocalStorage } from './localStorage';

const useUserFetch = (auth, setAuth, setPermissions) => {

    /* Custom Theme */
    const [theme, setTheme] = useState(createTheme({
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: Cookies.get('colorPrimary') !== undefined ? {
            main: colorToRGBAFormat(Cookies.get('colorPrimary'), 1),
            medium: colorToRGBAFormat(Cookies.get('colorPrimary'), 0.35),
            light: colorToRGBAFormat(Cookies.get('colorPrimary'), 0.2)
          } : {
            main: 'rgb(131, 206, 34)',
            medium: 'rgba(131, 206, 34, 0.35)',
            light: 'rgba(131, 206, 34, 0.2)'
          }
        }
      }))

    const { user, setUser } = useContext(UserContext);
    const { lang, changeLang } = useContext(LanguageContext);

    const setBriefUnits = useSetRecoilState(briefUnitsState)
    const setBriefBrowsers = useSetRecoilState(briefBrowsersState)

    const history = useHistory();

    moment.locale(i18n.language); 
    if (Cookies.get('language') !== undefined) {
        if (Cookies.get('language') !== lang)
        changeLang(Cookies.get('language'));
    }

    /**
     * Fetch user
     */
    const fetchUser = () => {
        /**
         * On Success
         * @param {*} response 
         */
        const onSuccess = (response) => {   
    
            //console.log("[APP] user", response.data);
            const userConfig = response.data.config;
            const parsedUserConfig = JSON.parse(userConfig);
    
            /**
             * Set Cookies if user has configuration
             */
            if (userConfig !== "" && Object.entries(parsedUserConfig).length !== 0) { 
                /* Title */
                if (parsedUserConfig.title)
                    Cookies.set('title', parsedUserConfig.title, { expires: 365 });
                else
                    document.cookie = "title=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
                /* Primary color */
                if (parsedUserConfig.colorPrimary)
                    Cookies.set('colorPrimary', parsedUserConfig.colorPrimary, { expires: 365 });
                else
                    document.cookie = "colorPrimary=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
                /* Language */
                if (parsedUserConfig.language)
                    Cookies.set('language', parsedUserConfig.language, { expires: 365 });
                else
                    document.cookie = "language=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
            }
            else {
                document.cookie = "title=; expires=Thu, 01 Jan 1970 00:00:00 UTC"; // TITLE
                document.cookie = "colorPrimary=; expires=Thu, 01 Jan 1970 00:00:00 UTC"; // PRIMARY COLOR
                document.cookie = "language=; expires=Thu, 01 Jan 1970 00:00:00 UTC"; // LANGUAGE
            }
            
            /** 
             * Fetch user to context
             */
            setUser({
                type: FETCH_USER,
                user: {
                    ...response.data,
                    config: userConfig === "" ? false : parsedUserConfig
                }
            });
    
            /**
             * Set language 
             */
            if (userConfig !== "" && Object.entries(parsedUserConfig).length !== 0 && parsedUserConfig.language) {
                if (parsedUserConfig.language !== lang)
                changeLang(parsedUserConfig.language);
            }
            
            /**
             * Set user permissions 
             */
            if (response.data.type === "admin") 
                setPermissions(['ACL_ADMIN', 'ACL_RESELLER_ADMIN', 'ACL_CUSTOMER'])
            else if (response.data.type === "reseller" || response.data.type === "resellerAdmin") 
                setPermissions(['ACL_RESELLER_ADMIN', 'ACL_CUSTOMER'])
            else
                setPermissions(['ACL_CUSTOMER'])
    
            /**
             * Set custom theme 
             */
            if (userConfig !== "" && Object.entries(parsedUserConfig).length !== 0 && parsedUserConfig.colorPrimary) {
                setTheme(createTheme({
                    ...defaultTheme,
                    palette: {
                        ...defaultTheme.palette,
                        primary: {
                        main: colorToRGBAFormat(parsedUserConfig.colorPrimary, 1),
                        medium: colorToRGBAFormat(parsedUserConfig.colorPrimary, 0.35),
                        light: colorToRGBAFormat(parsedUserConfig.colorPrimary, 0.2)
                        }
                    }
                }))
            }
        } 
        
        /**
         * onError
         * @param {*} errorResponse 
         */
        const onError = (errorResponse) => {
            if (errorResponse.status === 401 || errorResponse.status === 403) {
                localStorage.removeItem("token");
                setAuth(false);
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
            console.log('[APP] error in /user', errorResponse);
        }
        
        /**
         * Api call
         */
        BackendRequest("get", "/user", null, onSuccess, onError);
    }
    

    /**
     * Fetch all units (with reduced data)
     */
    const fetchAllUnits = () => {
        /**
         * On Success
         * @param {*} response 
         */
        const onSuccess = (response) => {
            let unitsArray = Object.entries(response.data).map((unit) => ( 
                { 
                    ...unit[1],
                    name: unit[1].name.toUpperCase(),
                    serial_number: unit[0]
                }
            ));
            setBriefUnits(unitsArray);
        }
        /**
         * onError
         * @param {*} errorResponse 
         */
        const onError = (error) => {
            console.log(`error in /unit/all?page=0&page_size=9999&search=`, error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                setAuth(false);
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
    
        /**
         * Api call
         */
        BackendRequest("get", `/unit/all?page=0&page_size=9999&search=`, null, onSuccess, onError);
    }
    
    
    /**
     * Fetch all browsers (with reduced data)
     */
    const fetchAllBrowsers = () => {
        /**
         * On Success
         * @param {*} response 
         */
        const onSuccess = (response) => {
            let browsersArray = response.data.map((browser) => ({...browser}));
            setBriefBrowsers(browsersArray);
        }
        /**
         * onError
         * @param {*} errorResponse 
         */
        const onError = (error) => {
            console.log(`error in /browser-list-brief?page=0&page_size=9999&search=`, error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                setAuth(false);
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
    
        /**
         * Api call
         */
        BackendRequest("get", `/browser-list-brief?page=0&page_size=9999&search=`, null, onSuccess, onError);
    }


    useEffect(() => {

        if (auth === null) {
            /* If token in local storage, user already authenticated */
            if (getTokenFromLocalStorage() !== null)
                setAuth(true);
            else
                setAuth(false);
        }
    
        /* If authenticated user, fetch all necessary information */
        if (auth) {
            fetchUser();
            fetchAllUnits();
            fetchAllBrowsers();
        }
        
    }, [auth])


    return { user, theme };
}

export default useUserFetch;