import React from 'react';
import {useTranslation} from "react-i18next";
import { CardElement } from '@stripe/react-stripe-js';
/* Material UI */
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
/* Compoennts */
import StripeInput from "./StripeInput";
import CustomTextField from '../lib/CustomTextField/CustomTextField';

const StripePaymentForm = ({ state, setState }) => {

    const { t } = useTranslation();

    return (
        <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Card holder name */}
                <CustomTextField 
                    name="cardHolderName"
                    value={state.cardHolderName}
                    error={state.error === "missingCardHolderName"}
                    handleChange={(event) => setState({ ...state, cardHolderName: event.target.value})}
                    label={t('services.summary.label.cardholderName').toUpperCase()}
                />
            </Grid>
            <Grid item xs={12}>
                {/* Email */}
                <CustomTextField 
                    name="email"
                    value={state.email}
                    error={state.error === "missingEmail"}
                    handleChange={(event) => setState({ ...state, email: event.target.value})}
                    label="EMAIL"
                />
            </Grid>

            <Grid item xs={12}>
                {/* Credit card number */}
                <TextField
                    label={t('services.summary.label.creditCard').toUpperCase()}
                    name="ccnumber"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardElement
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default StripePaymentForm;