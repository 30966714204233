import React from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
/* Components */
import CustomSelectField from '../../lib/CustomSelectField/CustomSelectField';
import CustomFileInput from '../../lib/CustomFileInput/CustomFileInput';


/* Styles */
const styles = {
    listItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
        paddingTop: '16px',
        backgroundColor: 'secondary.ultraLight',
        borderRadius: '4px',
        marginBottom: '8px',
        '& .MuiGrid-container': {
            padding: 0,
        },
        '& .MuiGrid-item': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '100%',
            '@media (max-width: 1319px)': {
                padding: '8px 4px',
                '&:first-of-type': {
                    padding: 0
                },
            }
        },
    },
    deleteButtonWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center',
        '@media (max-width: 1319px)': {
            justifyContent: 'center',
            padding: '0 !important'
        }
    },
    title: {
        marginBottom: 0,
        '@media (max-width: 1319px)': {
            marginBottom: '16px'
        }
    }
};

const BrowserUnitItem = ({ unit, deleteUnit, editUnit }) => {
 
    const { t } = useTranslation();

    return (
        <ListItem sx={styles.listItem}>
            <Grid container>
                <Grid item xs={12} md={11}> {/* xl={11} */}
                    <ListItemText 
                        primary={
                            <Grid container alignItems="flex-start" spacing={2} style={{maxWidth: '100%', margin: 0}}>
                                <Grid item xs={12} md={4} sx={styles.title}>
                                    <Typography variant="h6" color="primary" style={{fontWeight: 700}}>
                                            {unit.unit_name.toUpperCase()}
                                        </Typography>
                                        <Typography variant="subtitle2" color="secondary">
                                            ({unit.unit_serial})
                                        </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField 
                                        style={{width: '100%'}}
                                        name="browser_unit_name"
                                        value={unit.browser_unit_name.toUpperCase()} 
                                        onChange={(event) => editUnit(unit.unit_id, event)}
                                        label={t('browsers.settings.label.unitName').toUpperCase()}
                                        InputProps={{
                                            startAdornment: <InputAdornment />,
                                        }}
                                        variant="outlined" 
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField 
                                        style={{width: '100%'}}
                                        name="timeshift"
                                        value={unit.timeshift}
                                        onChange={(event) => editUnit(unit.unit_id, event)}
                                        label={t('browsers.settings.label.timeshift').toUpperCase() + " (HH:MM)"}
                                        InputProps={{
                                            startAdornment: <InputAdornment />,
                                        }}
                                        variant="outlined" 
                                    />
                                </Grid>
                                {/*
                                    <Grid item xs={12} md={6} xl={2}>
                                        <CustomSelectField
                                            name="flip"
                                            value={false}
                                            onChange={(event) => editUnit(unit.unit_id, event)}
                                            label={t('browsers.settings.label.imageFlip').toUpperCase()}
                                            InputProps={{
                                                startAdornment: <InputAdornment />,
                                            }}
                                            variant="outlined" 
                                        >
                                            <MenuItem value={true}>{t('browsers.settings.label.yes')}</MenuItem>
                                            <MenuItem value={false}>{t('browsers.settings.label.no')}</MenuItem>
                                        </CustomSelectField>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={2}>
                                        <CustomFileInput />
                                    </Grid>
                                */}
                            </Grid>
                        }           
                    />
                </Grid>
                <Grid item xs={12} md={1} sx={styles.deleteButtonWrapper}> {/* xl={1} */}
                    <IconButton aria-label="delete" onClick={() => deleteUnit(unit.unit_id)}>
                        <Icon style={{fontSize: '32px'}}>delete</Icon>
                    </IconButton>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default BrowserUnitItem;