import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/* Material UI */
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
/* Components */
import TimelapseIntervalModal from '../../modals/TimelapseIntervalModal/TimelapseIntervalModal';
/* Styles */
import { styles } from './IntervalItem.styles';

const zfill = (num, len) => {return (Array(len).join("0") + parseInt(num)).slice(-len)}

const IntervalItem = ({ unitSWVersion, interval, index, removeInterval, submitInterval }) => {

    const { t } = useTranslation();

    const splittedVersionSW = unitSWVersion ? unitSWVersion.split(".") : "1.2.19".split("."); //in case unitSWVersion is null

    const [timelapseIntervalModalOpen, setTimelapseIntervalModalOpen] = useState(false);
    const [state, setState] = useState(null);

    /**
     * Open modal
     */
    const handleModalOpen = () => {
        setTimelapseIntervalModalOpen(true);
    };

    /**
     * Close modal
     */
    const handleModalClose = () => {
        setTimelapseIntervalModalOpen(false);
    };

    /**
     * Handle interval change
     * @param {*} newState 
     */
    const handleIntervalChange = (newState) => {
        submitInterval(newState, index);
    }

    const compareFunction = ( x, y ) => {
        var myArray = {'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6, 'SUN': 7};
        
        var xNumber = myArray[x.toUpperCase()];
        var yNumber = myArray[y.toUpperCase()];

        return xNumber < yNumber ? -1 : 1;
    };

    useEffect(() => {
        setState(parseInt(splittedVersionSW[0]) >= 3 && parseInt(splittedVersionSW[2]) >= 92 ?
        {
            ifrom: interval ? zfill(interval.ifrom / 3600, 2) + ":" + zfill((interval.ifrom % 3600) / 60, 2) : "06:00",
            ito: interval ? zfill(interval.ito / 3600, 2) + ":" + zfill((interval.ito % 3600) / 60, 2) : "18:00",
            period: interval ? interval.period % 60 === 0 ? interval.period / 60 : interval.period : "10",
            timeUnit: interval ? interval.period % 60 === 0 ? "mins" : "secs" : "mins",
            wdays: interval ? interval.wdays : [],
            suffix: (interval && interval.suffix) ? interval.suffix : "",
            upload: (interval && interval.upload) ? interval.upload : false,
        } : {
            ifrom: interval ? zfill(interval.ifrom / 3600, 2) + ":" + zfill((interval.ifrom % 3600) / 60, 2) : "06:00",
            ito: interval ? zfill(interval.ito / 3600, 2) + ":" + zfill((interval.ito % 3600) / 60, 2) : "18:00",
            period: interval ? interval.period % 60 === 0 ? interval.period / 60 : interval.period : "10",
            timeUnit: interval ? interval.period % 60 === 0 ? "mins" : "secs" : "mins",
            wdays: interval ? interval.wdays : [],
        })
    }, [interval])

    if (state === null)
        return null;

    return (
        <>
            <ListItem sx={styles.listItem}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Icon>timer</Icon>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    <Tooltip title="Interval">
                        <Chip 
                            label={state.ifrom + " - " + state.ito} 
                            variant="outlined"
                            sx={styles.chip}
                        />
                    </Tooltip>
                    
                    &nbsp;&nbsp;

                    <Tooltip title="Period">
                        <Chip 
                            label={state.period + " " + state.timeUnit} 
                            variant="outlined"
                            sx={styles.chip}
                        />
                    </Tooltip>

                    &nbsp;&nbsp;

                    <Tooltip title="Weekdays">
                        <Chip 
                            label={state.wdays.sort(compareFunction).join(', ')}
                            variant="outlined"
                            sx={styles.chip}
                        />
                    </Tooltip>

                    &nbsp;&nbsp;

                    {parseInt(splittedVersionSW[0]) >= 3 && parseInt(splittedVersionSW[2]) >= 92 ? 
                        <>
                            <Tooltip title="Interval name">
                                <Chip 
                                    label={state.suffix === "" ? "Standard" : "Rush"}
                                    variant="outlined"
                                    sx={styles.chip}
                                />
                            </Tooltip>

                            &nbsp;&nbsp;

                            <Tooltip title="Upload">
                                <Chip 
                                    label={state.upload ? "Upload" : "Skip"}
                                    icon={<CloudUploadIcon style={{color: 'grey'}} />}
                                    variant="outlined"
                                    sx={[styles.chip, styles.uploadChip]}
                                />
                            </Tooltip>
                        </> : null
                    }
                </div>

                &nbsp;&nbsp;
                
                <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                    <Tooltip title={t('customers.overview.tooltip.edit')}>
                        <IconButton 
                            sx={styles.iconButton} 
                            aria-label="edit"
                            onClick={() => handleModalOpen(index)}
                        >
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('customers.overview.tooltip.delete')}>
                        <IconButton sx={styles.iconButton} aria-label="delete" onClick={() => removeInterval(index)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            </ListItem>

            {interval && <TimelapseIntervalModal
                open={timelapseIntervalModalOpen} 
                handleClose={handleModalClose} 
                state={state} 
                setState={handleIntervalChange}
            />}
        </>
    );
}

export default IntervalItem;