import React, { useState } from 'react';
/* Material UI */
import Typography from '@mui/material/Typography';
/* Components */
import CustomButton from '../lib/CustomButton/CustomButton';
import FeedItemModal from '../modals/FeedItemModal/FeedItemModal';
import FeedItem from './FeedItem/FeedItem';
import ACLWrapper from '../../Containers/ACLWrapper';

const FeedOverview = ({ feed }) => {

    const [feedItemModalOpen, setFeedItemModalOpen] = useState(false);
    
    return (
        <>
            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <CustomButton
                    color="primary"
                    onClick={() => setFeedItemModalOpen(!feedItemModalOpen)}
                    style={{textTransform: 'none', marginBottom: '16px'}}
                >
                    + Add news feed item
                </CustomButton>
            </ACLWrapper>
            {(feed && feed.length !== 0) 
                ? feed.map(item => <FeedItem {...item} />)
                : <Typography>There are no news yet.</Typography>
            }
            {feedItemModalOpen &&
                <FeedItemModal
                    handleClose={() => setFeedItemModalOpen(false)}
                    open={feedItemModalOpen}
                />
            }
        </>
    );
}

export default FeedOverview;