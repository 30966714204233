import React, { useState, useContext, useRef } from 'react';
/* Material UI */
import Popover from '@mui/material/Popover';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
/* Global state */
import { UserContext } from '../../../Context/UserContext';
/* Components */
import UnitCardInfoTableItem from './UnitCardInfoTableItem';

/* Styles */
const styles = {
    infoTable: {
        marginTop: '16px',
        marginBottom: '12px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(140px,1fr))',
        gridGap: '2px',
        position: 'relative',
        '@media (max-width: 766px) and (min-width: 658px)': {
            gridTemplateColumns: '1fr 1fr 1fr',
        }
    },
    popover: {
        padding: '8px',
        minWidth: '360px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px,1fr))',
        gridGap: '2px',
        pointerEvents: 'none',
        '@media (max-width: 480px)': {
            minWidth: '200px',
        }
    },
    popoverInside: {
        zIndex: 1301,
        padding: '8px',
        minWidth: '360px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px,1fr))',
        gridGap: '2px',
        '@media (max-width: 480px)': {
            minWidth: '200px',
        }
    },
    paper: {
        padding: '8px',
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'secondary.medium',
        pointerEvents: 'auto',
    },
};

const UnitCardInfoTable = ({ unit, expired }) => {

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);
    const { user } = useContext(UserContext);

    const defaultUnitItems = ["files_from_cam", "files_uploaded", "fails_shot", "uptime", "temperature", "voltage"];
    const allUnitItems = ["files_from_cam", "files_uploaded", "fails_shot", "uptime", "temperature", "voltage", "humidity", "local_storage", "rssi"];

    const popoverEnter = () => {
        //console.log("popoverEnter");
        setOpenedPopover(true);
    };

    const popoverLeave = () => {
        //console.log("popoverLeave");
        setOpenedPopover(false);
    };

    //console.log("[UnitCardInfoTable]");

    if (!unit.version_sw || parseInt(unit.version_sw.charAt(0)) < 3) {
        return (
            <Box sx={styles.infoTable}>
                {defaultUnitItems.map(item => <UnitCardInfoTableItem key={item} unit={unit} item={item} expired={expired} />)}
            </Box>
        );
    }
    else {
        return ( 
            <Box sx={styles.infoTable}>
                
                {/* Info icon with Popover containing all unit info - only for unit with SW higher than 3.0 */}
                <div style={{position: 'absolute', top: '-24px', left: 'calc(50% - 12px)'}}>
                    {(!expired || (expired && user.type === "admin")) &&
                        <Icon
                            ref={popoverAnchor}
                            aria-owns={'mouse-over-popover'}
                            aria-haspopup="true"
                            onMouseEnter={popoverEnter}
                            onMouseLeave={popoverLeave}
                            color="secondary"
                        >
                            info
                        </Icon>
                    }
                    <Popover
                        id="mouse-over-popover"
                        sx={styles.popover}
                        classes={{
                            paper: styles.paper,
                        }}
                        open={openedPopover}
                        anchorEl={popoverAnchor.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        disableRestoreFocus
                        PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
                    >
                        <Box style={styles.popoverInside}>
                            {allUnitItems.map(item => <UnitCardInfoTableItem key={item} unit={unit} item={item} expired={expired} />)}
                        </Box>
                    </Popover>
                </div>

                {/* Table content - selected or default sensor data */}
                {user.config.sensor_data
                    ? user.config.sensor_data.map(item =>
                        <UnitCardInfoTableItem key={item} unit={unit} item={item} expired={expired} />)
                    : defaultUnitItems.map(item =>
                        <UnitCardInfoTableItem key={item} unit={unit} item={item} expired={expired} />)
                }
            </Box>
        );
    }
}

export default React.memo(UnitCardInfoTable);