import React, { useContext } from 'react'
import { PayPalButton } from "react-paypal-button-v2";
/* Own */
import { OrderContext } from '../../Context/OrderContext';
import { UserContext } from '../../Context/UserContext';

const PayPal = ({ serviceType, onPaymentSuccess, onPaymentError, onPaymentCancel }) => {
    
    const currentOrderState = useContext(OrderContext);
    const { user } = useContext(UserContext);
    
    return (
        <PayPalButton
            shippingPreference="NO_SHIPPING"
            amount={currentOrderState[serviceType].price}
            currency="EUR"
            createOrder={(data, actions) => {

                let items = [];
                for (let i = 0; i < currentOrderState[serviceType].order.length; i++) {
                    if (serviceType === "browsers" && currentOrderState.browsers.order[i].services[0].months !== 0){
                        items.push({
                            name: JSON.stringify({
                                user: user.id,
                                browser_id: currentOrderState.browsers.order[i].browser_id,
                                date_to: currentOrderState.browsers.order[i].date_to,
                                months: currentOrderState.browsers.order[i].services[0].months,
                            }),
                            unit_amount: {
                                currency_code: "EUR",
                                value: currentOrderState.browsers[currentOrderState.browsers.used_price_per_one]
                            },
                            quantity: currentOrderState.browsers.order[i].services[0].months,
                        })
                    }
                    else if (serviceType === "units" && currentOrderState.units.order[i].services[0].months !== 0) {
                        items.push({
                            name: JSON.stringify({
                                user: user.id,
                                unit_id: currentOrderState.units.order[i].unit_id,
                                unit_serial: currentOrderState.units.order[i].unit_serial,
                                months: currentOrderState.units.order[i].services[0].months,
                            }),
                            unit_amount: {
                                currency_code: "EUR",
                                value: currentOrderState.units[currentOrderState.units.used_price_per_one]
                            },
                            quantity: currentOrderState.units.order[i].services[0].months,
                        })
                    }
                }

                //console.log("items", items);

                return actions.order.create({
                    purchase_units: [{
                        items: items,
                        amount: {
                            currency_code: "EUR",
                            value: currentOrderState[serviceType].price,
                            breakdown: {
                                item_total: {
                                    currency_code: "EUR",
                                    value: currentOrderState[serviceType].price,
                                }
                            }
                        }
                    }],
                });
            }}
            options={{
                clientId: "AaH3-UHpDHnyJ_JjtVjG9vMibfrT3vBU_gb9AZpZXBKUnXA3br8LEFfgMmAeLysVt_Y69yzWA9Hm_Nbm",
                currency: "EUR"
            }}
            onSuccess={onPaymentSuccess} 
            onError={onPaymentError} 
            onCancel={onPaymentCancel}
        />
    )
}

export default PayPal;
