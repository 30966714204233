import React, { useRef, useImperativeHandle } from 'react'

const StripeInput = ({ component: Component, inputRef, ...props }) => {
     
    const elementRef = useRef();
     useImperativeHandle(inputRef, () => ({
          focus: () => elementRef.current.focus
     }));

     const iframeStyles = {
        base: {
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: 'Montserrat, Consolas, sans-serif',
          fontSize: "18px",
          "::placeholder": {
            color: "rgba(133, 133, 133, 1)"
          }
        },
        invalid: {
          iconColor: 'rgba(240, 52, 52, 1)',
          color: 'rgba(240, 52, 52, 1)'
        },
        complete: {
          iconColor: 'rgba(131, 206, 34, 1)'
        }
      };
    
    const cardElementOptions = {
        iconStyle: "solid",
        style: iframeStyles,
        hidePostalCode: true
    };

     return (
          <Component
               onReady={element => (elementRef.current = element)}   
               options={cardElementOptions}  
               {...props}
          />
     )
}
export default StripeInput