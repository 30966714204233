import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Components */
import AllUnitsTableHead from '../components/Services/Common/AllItemsTableHead';
import AllUnitsTableRow from '../components/Services/AllUnits/AllUnitsTableRow';
import SearchBox from '../components//lib/CustomSearchField/CustomSearchField';
import SummaryBottomLine from '../components/Services/Common/SummaryBottomLine';
/* Global state */
import { OrderContext } from '../Context/OrderContext';
/* Utils */
import useOrder from '../Utils/useOrder';

/* Styles */
const styles = {
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: '80px',
      maxWidth: '1000px',
      '@media (max-width: 480px)': {
        marginBottom: '100px',
      }
    },
    table: {
      minWidth: 750,
    },
};

const ServicesAllUnits = () => {

  //console.log("[ServiceAllUnits]");

  const { t } = useTranslation();
  const orderedUnitRef = useRef(null);
  const [ searchQuery, setSearchQuery ] = useState("");

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: t("services.unitsTable.title.unit").toUpperCase(), align: 'left' },
    { id: 'remote_management', numeric: false, disablePadding: false, label: t("services.unitsTable.title.remoteManagementService").toUpperCase(), align: 'center' },
  ];

  const { 
    items,
    order, 
    orderBy, 
    selected, 
    setSelected,
    isSelected, 
    getComparator, 
    stableSort, 
    handleSelectAllClick, 
    handleClick, 
    handleRequestSort } = useOrder("units", searchQuery);

  const currentOrderState = useContext(OrderContext);

  useEffect(() => {
    if (orderedUnitRef.current){
        orderedUnitRef.current.scrollIntoView({block: "center"});
    }  
  }, [])

  return (
    <Box sx={styles.root}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h5" gutterBottom>
            Select units you would like to order.
        </Typography>
        <SearchBox 
            searchQuery={searchQuery} 
            handleInputChange={(event) => {setSearchQuery(event.target.value);}}
            style={{height: '32px'}}
        />
      </div>

      <br />

      {Object.entries(items).length !== 0 ?
        <Paper sx={styles.paper}>
          <TableContainer>
            
            <Table sx={styles.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
              
              {/* Head */}
              <AllUnitsTableHead
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={items.length}
              />

              {/* Body */}
              <TableBody>
                {stableSort(items, getComparator(order, orderBy))
                  .map((unit, index) => {
                    const isItemSelected = isSelected(unit.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <AllUnitsTableRow 
                        handleClick={handleClick}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        unit={unit}
                        innerRef={currentOrderState.units.order.find(orderItem => orderItem.unit_serial === unit.serial_number) ? orderedUnitRef : null}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper> : t('dashboard.label.noUnitsWereFound')
      }

      {selected.length > 0 ? 
        <SummaryBottomLine 
          selectedLength={selected.length} 
          setSelected={setSelected} 
          label={t('services.summary.label.numberOfSelectedUnits')}
          continueURL={"/services/units/order"}
        /> : null
      }
    </Box>
  );
}

export default ServicesAllUnits;