import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { withRouter, NavLink } from "react-router-dom";
/* Material UI */
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
/* Components */
import BrowserCard from '../components/Browsers/Overview/BrowserCard';
import SearchBox from '../components//lib/CustomSearchField/CustomSearchField';
import Spinner from '../components//lib/CustomSpinner/CustomSpinner';
import CustomButton from '../components/lib/CustomButton/CustomButton';
/* Utils */
import useBrowsersSearch from '../Utils/useBrowsersSearch';

/* Styles */
const styles = {
    topBar: {
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: '16px',
        padding: '0 6px'
    }
};

const Browsers = () => {
 
    const { t } = useTranslation();
    const [ searchQuery, setSearchQuery ] = useState("");
    const [ pageNumber, setPageNumber ] = useState(0);
    
    const { loading, error, browsers, hasMore } = useBrowsersSearch(searchQuery, pageNumber);

    /**
     * Handle search, on change of search query is called custom hook useBrowsersSearch
     * @param {*} event 
     */
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
        setPageNumber(0);
    }

    /* Observer - When observer is reached, new request is created */
    /* https://github.com/WebDevSimplified/React-Infinite-Scrolling/blob/master/src/App.js */
    const observer = useRef();
    const lastBrowserRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        }, {rootMargin: "600px"})
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (
        <div>
            {/* Top bar */}
            <Box sx={styles.topBar}>
                <CustomButton color="primary" style={{textTransform: 'none'}} component={NavLink} to={'browsers/add'}>
                    + {t('browsers.overview.button.addBrowser')}
                </CustomButton>

                <SearchBox 
                    searchQuery={searchQuery} 
                    handleInputChange={handleInputChange} 
                />
            </Box>
            
            {/* Browsers grid */}
            <Grid container>
                {browsers.map(browser => {
                    return <BrowserCard browser={browser} />
                })}
                {(browsers.length === 0 && !loading) ? t('browsers.overview.label.noBrowsersWereFound') : null}
            </Grid>

            {/* Reference of observer - Spinner */}
            <div ref={lastBrowserRef}>
                {hasMore && <Spinner />}
            </div>

            {error && t('error.somethingWentWrong')}
        </div>
    );
}

export default withRouter(Browsers);