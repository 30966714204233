import React, { useContext } from 'react';
/* Material UI */
import Typography from '@mui/material/Typography';
/* Global state  */
import { UserContext } from '../../Context/UserContext';


const AppBarContent = () => {

    const { user } = useContext(UserContext);

    //console.log("[AppBarContent] user", user)

    /* If custom configuration exists */
    if(user && Object.entries(user.config).length !== 0) {
        
        /* No logo and no title */
        if((user.config.logo === "" && user.config.title === "") || (user.config.logo === undefined && user.config.title === undefined)) {
            return (
                <Typography variant="h5" noWrap>
                    BIXION BOARD
                </Typography>
            )
        }

        /* Both logo and title */
        if(user.config.logo !== "" && user.config.title !== "" && user.config.logo !== undefined && user.config.title !== undefined) {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img 
                        style={{maxHeight: '36px', maxWidth: 'auto'}} 
                        src={"https://api.bixion.com/is/files/" + user.config.logo + "?" + new Date().getTime()} //URL
                        alt="logo" 
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant="h5" noWrap>
                        {user.config.title}
                    </Typography>
                </div>
            );
        }

        /* Only logo */
        else if(user.config.logo !== "") {
            return (
                <img 
                    style={{maxHeight: '50px', maxWidth: 'auto'}} 
                    src={"https://api.bixion.com/is/files/" + user.config.logo + "?" + new Date().getTime()} //URL
                    alt="logo" 
                />
            );
        }

        /* Only title */
        else {
            return (
                <Typography variant="h5" noWrap>
                    {user.config.title}
                </Typography>
            );
        }
    }
    else {
        return (
            <Typography variant="h5" noWrap>
                BIXION BOARD
            </Typography>
        );
    }
    
}

export default React.memo(AppBarContent);