import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
/* Components */
import ServicesSummary from '../components/Services/Summary/ServicesSummary';
/* Global state */
import { OrderContext, OrderActionsContext } from '../Context/OrderContext';
import { updateBriefUnitState } from '../Context/globalState';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';

const ServicesUnitsSummary = () => {

    const history = useHistory();
    const currentOrderState = useContext(OrderContext);
    const updateBriefUnit = useSetRecoilState(updateBriefUnitState);

    /**
     * Process the order when payment succeeds
     * @param {*} payment 
     */
    const processOrder = payment => {
        currentOrderState.units.order.map(orderItem => {
            const data = {
                days: orderItem.services[0].months*31, 
                service: orderItem.services[0].service_name
            }

            const onSuccess = (response) => {
                /* Update brief units */
                updateBriefUnit({ 
                    unit_id: orderItem.unit_id,
                    days: orderItem.services[0].months*31
                })
            }
            
            const onError = (error) => {
                console.log('error in /services/' + orderItem.unit_id, error);
                if (error.status === 401 || error.status === 403) {
                    localStorage.removeItem("token");
                    history.push({ pathname: '/login', state: { showSnack: true }});
                }
            }
            
            BackendRequest("post", "/services/" + orderItem.unit_id, data, onSuccess, onError);
        })
    }


    if (currentOrderState.units.order.length !== 0) {
        return <ServicesSummary category="units" processOrder={processOrder} />
    }
    else {
        return <Redirect to="/services/units" />
    }
}

export default ServicesUnitsSummary;