import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
/* Components */
import UnitsListModal from '../../modals/UnitsListModal/UnitsListModal';
import BrowserUnitItem from './BrowserUnitItem';
import CustomButton from '../../lib/CustomButton/CustomButton';

/* Styles */
const styles = {
    smallerDivider: {
        marginTop: '8px',
        marginBottom: '8px',
        height: '2px'
    },
    list: {
        width: '100%',
    }
};

const BrowserUnits = ({ browser, deleteUnit, addUnit, editUnit }) => {

    const { t } = useTranslation();
    const [unitsListModalOpen, setUnitsListModalOpen] = useState(false);

    /**
     * On click open dialog window with all units to choose from
     */
    const handleClickDialogOpen = () => {
        setUnitsListModalOpen(true);
    };

    /**
     * On click close dialog window
     */
    const handleDialogClose = () => {
        setUnitsListModalOpen(false);
    };

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            
            {/* Subtitle*/}
            <Grid item xs={12} lg={10} xl={8} style={{paddingBottom: 0}}>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                    <Typography variant="subtitle1" color="secondary" style={{marginRight: '16px', fontSize: '20px', fontWeight: 600, lineHeight: 1}}>
                        {t('browsers.settings.title.browserUnits')}
                    </Typography>

                    <CustomButton color="primary" onClick={handleClickDialogOpen} style={{textTransform: 'none', padding: '2px 12px'}}>
                        + {t('browsers.settings.button.addUnit')}
                    </CustomButton>
                </div>
                {/* Divider */}
                <Divider sx={styles.smallerDivider} />  
            </Grid>
            
            {/* Units list */}
            <Grid item xs={12} lg={10} xl={8} style={{paddingTop: 0}}>
                <List sx={styles.list}>
                    
                    {browser !== null && browser.units.map((unit) => {
                        return <BrowserUnitItem unit={unit} deleteUnit={deleteUnit} editUnit={editUnit} />;
                    })}

                    {/* Only for adding new units in BrowserSettings (updating browser) */}
                    {browser !== null && browser.new_units.map((unit) => {
                        return <BrowserUnitItem unit={unit} deleteUnit={deleteUnit} editUnit={editUnit} />;
                    })}

                </List>
            </Grid>

            {/* Units list dialog for adding new units to browser */}
            <UnitsListModal 
                open={unitsListModalOpen} 
                handleClose={handleDialogClose} 
                browser={browser}
                addUnit={addUnit}
            />
        </Grid>
    );

}

export default BrowserUnits;