import React, { createContext, useReducer } from 'react';
import { feedReducer } from "./reducers/feedReducer";

export const FeedContext = createContext();
export const FeedActionsContext = createContext();

const FeedProvider = ({ children }) => {

    const feedStructure = {
        feedItems: [],
        versions: []
    };

    const [feed, dispatchFeed] = useReducer(feedReducer, feedStructure);

    return (
        <FeedContext.Provider value={feed}>
            <FeedActionsContext.Provider value={dispatchFeed}>
                {children}
            </FeedActionsContext.Provider>
        </FeedContext.Provider>
    );

}

export default FeedProvider;