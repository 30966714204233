import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedLogin = ({ auth = true, component:Component, ...rest}) => {

    //console.log("[ProtectedLogin]");

    return(
        <Route {...rest} render = {() => !auth 
            ? <Component/> 
            : <Redirect to="/dashboard" />}
        />
    )
}

export default withRouter(React.memo(ProtectedLogin));