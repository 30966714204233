import React, {
    useState,
    useContext
} from 'react';
import { useHistory } from 'react-router';
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSetRecoilState } from 'recoil';
/* Material UI */
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Box from '@mui/material/Box';
/* Components */
import CustomButton from '../../lib/CustomButton/CustomButton';
import SummaryHeader from './SummaryHeader';
import UnitsSummaryRow from '../Units/UnitsSummaryRow';
import SummaryPaymentMethod from '../Summary/SummaryPaymentMethod';
import SummaryPaymentMethodAdvice from '../Summary/SummaryPaymentMethodAdvice';
import StripeModal from '../../modals/StripeModal/StripeModal';
import { default as PayPalButton } from '../PayPal';
import PaymentComplaintInfo from '../Summary/PaymentComplaintInfo';
import BrowsersSummaryRow from '../Browsers/BrowsersSummaryRow';
/* Global state */
import { OrderActionsContext, OrderContext } from '../../../Context/OrderContext';
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { UPDATE_USER, UserContext } from '../../../Context/UserContext';
import { DELETE_ORDER, UPDATE_CREDIT } from '../../../Context/reducers/orderReducer';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';
import { briefBrowsersState } from '../../../Context/globalState';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    },
    divider: {
        marginTop: '8px',
        marginBottom: '24px',
        height: '2px'
    },
    bottomLine: { // div
        display: 'flex', 
        justifyContent: 'flex-end'
    },
};

const stripePromise = loadStripe("pk_live_gsnXMJ0Nv8h8QlUh9jDXt1uo00zf2paHpp");
// const stripePromise = loadStripe("pk_test_HFg1FnXLTeRJ1eb7yEAXD6b700W2aWGyrV");

const ServicesSummary = ({ category, processOrder }) => {

    const { t } = useTranslation();
    const history = useHistory();

    // services/units or /services/browser
    const redirectUrl = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')); 

    const currentOrderState = useContext(OrderContext);
    const dispatchOrder = useContext(OrderActionsContext);
    const { user, setUser } = useContext(UserContext);
    const { setSnack } = useContext(SnackbarContext);
    const setBriefBrowsers = useSetRecoilState(briefBrowsersState)

    const [ paymentMethod, setPaymentMethod ] = useState((user.credit && user.credit >= parseInt(currentOrderState[category].price)) ? 'credit' : 'paypal');
    const [ stripeModalOpen, setStripeModalOpen ] = useState(false);

    const onCreditDebit = () => {
        const onSuccess = (response) => {
            processOrder();
            dispatchOrder({type: DELETE_ORDER});
            setUser({
                type: UPDATE_USER,
                user: { credit: user.credit - parseInt(currentOrderState[category].price) }
            });
            dispatchOrder({
                type: UPDATE_CREDIT,
                credit: user.credit - parseInt(currentOrderState[category].price),
            })
            setSnack({ message: t('snackbar.message.creditHasBeenDebited'), type: "info", open: true});
            history.push('/dashboard');
        }
        const onError = (error) => {
            console.log('error in /pays/credit-debit');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/pays/credit-debit", { value: parseInt(currentOrderState[category].price) }, onSuccess, onError);
    }
    
    const onPaymentSuccess = payment => {
        const orderProcessed = new Promise((resolve, reject) => {
            resolve(processOrder());
        })
        
        orderProcessed.then(() => {
            dispatchOrder({type: DELETE_ORDER});
            setStripeModalOpen(false);
            setSnack({ message: t('snackbar.message.thePaymentWasAccepted'), type: "info", open: true});
    
            /**
             * On Success
             * @param {*} response 
             */
            const onSuccess = (response) => {
                let browsersArray = response.data.map((browser) => ({...browser}));
                setBriefBrowsers(browsersArray);
            }
        
            /**
             * Api call
             */
            BackendRequest("get", `/browser-list-brief?page=0&page_size=9999&search=`, null, onSuccess, null);
    
            history.push('/dashboard');
        })
    }

    /**
     * Payment cancel
     * @param {*} data 
     */
    const onPaymentCancel = data => {
        console.log('The payment was cancelled.', data);
    }

    /**
     * Payment error
     * @param {*} err 
     */
    const onPaymentError = err => {
        console.log("Payment error.", err);
        setSnack({ message: t('snackbar.message.thePaymentFailed'), type: "error", open: true});
    }
    

    if (currentOrderState[category].order.length !== 0) {

        let CategorySummaryRow;
        if (category === "units") {
            CategorySummaryRow = UnitsSummaryRow;
        } else {
            CategorySummaryRow = BrowsersSummaryRow;
        }

        let primaryActionButton;
        if (user.type === "admin") {
            primaryActionButton = (
                <CustomButton color="primary" onClick={onPaymentSuccess}>
                    {t('services.summary.button.save')}
                </CustomButton>
            );
        } else {
            switch(paymentMethod) {
                case "credit":
                    primaryActionButton = (
                        <CustomButton color="primary" onClick={onCreditDebit}>
                            {t('services.summary.button.proceedToCheckout')}
                        </CustomButton>
                    );
                    break;
                case "card":
                    primaryActionButton = (
                        <CustomButton color="primary" onClick={() => setStripeModalOpen(true)}>
                            {t('services.summary.button.proceedToCheckout')}
                        </CustomButton>
                    );
                    break;
                case "paypal":
                    primaryActionButton = (
                        <PayPalButton 
                            onPaymentCancel={onPaymentCancel}
                            onPaymentError={onPaymentError}
                            onPaymentSuccess={onPaymentSuccess}
                            serviceType={category}
                        />
                    );
                    break;
                default:
                    break;
            }
        } 

        return (
            <Elements stripe={stripePromise}>
                <Paper sx={styles.paper} elevation={3}>

                    <SummaryHeader title={t('services.summary.title.summary')}>
                        <ReceiptIcon/>
                    </SummaryHeader>

                    <Divider sx={styles.divider} />
                    
                    {currentOrderState[category].order.map((orderItem, index) => {
                        if (orderItem.services[0].months !== 0) {
                            return <CategorySummaryRow key={index} orderItem={orderItem} />
                        }
                        return null;
                    })}

                    <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', padding: '0px 12px'}}>
                        <Typography variant="h6">
                            {t('services.summary.label.totalPrice')}:&nbsp;<strong>{currentOrderState[category].price} €</strong>
                        </Typography>
                    </div>
                </Paper>

                <br/>

                {user.type !== "admin" ? (
                    <>
                        <SummaryPaymentMethodAdvice paymentMethod={paymentMethod} category={category} />
                    </>
                ) : null}

                {user.type !== "admin" ? (
                    <>
                        <SummaryPaymentMethod paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} category={category} />
                        <br/>
                    </>
                ) : null}

                {user.type !== "admin" ? (
                    <>
                        <PaymentComplaintInfo />
                        <br/>
                    </>
                ) : null}
    
                <Box sx={styles.bottomLine}>
                    <CustomButton color="secondary" onClick={() => window.history.back()} style={{marginRight: '8px'}} >
                        {t('services.summary.button.back')}
                    </CustomButton>
                    {primaryActionButton}
                </Box>
    
                <StripeModal 
                    handleClose={() => setStripeModalOpen(false)}
                    onError={onPaymentError} 
                    onSuccess={onPaymentSuccess}
                    open={stripeModalOpen} 
                    price={parseInt(currentOrderState[category].price * 100)}
                    serviceType={category}
                />
            </Elements>
        );
    }
    else {
        return <Redirect to={redirectUrl} />
    }
}

export default ServicesSummary;