import React from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

/* Styles */
const styles = {
    expired: {
      color: 'common.red',
    },
    active: {
      color: 'common.green',
    },
    beforeExpiration: {
        color: 'common.orange',
    },
    row: {
        '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
        }
    },
    selectedRow: {
      backgroundColor: 'primary.light',
      '&:hover': {
        cursor: 'pointer',
    }
    },
};

const AllBrowsersTableRow = ({handleClick, isItemSelected, labelId, browser, innerRef}) => {

    const { t } = useTranslation();

    const dateTo = new Date(browser.date_to);
    const dateToYear = dateTo.getFullYear();

    const sevenDaysFromToday = new Date();
    sevenDaysFromToday.setDate(sevenDaysFromToday.getDate() + 7);

    const orderDisabled = dateToYear > 2024 || (dateToYear === 2024 && (dateTo.getMonth() + 1) === 12);

    return (
        <TableRow
            onClick={(event) => !orderDisabled ? handleClick(event, browser) : null}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={browser.id}
            sx={Object.assign({}, isItemSelected ? styles.selectedRow : styles.row)}
            ref={innerRef}
        >
            {/* Checkbox */}
            <TableCell padding="checkbox">
                <Checkbox
                    disabled={orderDisabled}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </TableCell>
            
            {/* Browser title */}
            <TableCell>
                <Typography color="primary" gutterBottom>
                    <Box fontWeight="fontWeightBold">
                        {browser.title}
                    </Box>
                </Typography>
            </TableCell>

            {/* State */}
            <TableCell align='center'>
                {new Date() > new Date(browser.date_to) ? 
                    <Typography sx={styles.expired} gutterBottom>
                        <Box fontWeight="fontWeightBold">
                            {t("services.common.label.expired")}
                            <br />
                            ({t("services.common.label.since")} {browser.date_to})
                        </Box>
                    </Typography>
                    : 
                    <Typography
                        sx={new Date(browser.date_to) > sevenDaysFromToday ? styles.active : styles.beforeExpiration}
                        gutterBottom
                    >
                        <Box fontWeight="fontWeightBold">
                            {t("services.common.label.active")}
                            <br />
                            ({t("services.common.label.until")} {browser.date_to})
                        </Box>
                    </Typography>
                }
            </TableCell>
        </TableRow>              
    );
}

export default React.memo(AllBrowsersTableRow);