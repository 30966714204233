import React from 'react';
import { Route, withRouter } from 'react-router-dom';

const PrivateRoute = ({ component:Component, ...rest }) => {

    //console.log("[PrivateRoute]", rest);
    
    return(
        <Route 
            {...rest} 
            render = {(props) => <Component  {...props} {...rest} />}
        />
    )
}

export default withRouter(React.memo(PrivateRoute));