export const styles = {
    list: {
        width: '100%',
    },
    listItem: {
        width: '100%',
        padding: 1,
        backgroundColor: 'secondary.ultraLight',
        borderRadius: .5,
        marginBottom: .5,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'secondary.lightMedium',
        }
    },
    listItemTitle: {
        fontSize: 20,
        fontWeight: 700,
        wordBreak: 'break-all'
    }
};