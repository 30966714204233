import React, { useState, useRef, useContext } from 'react';
import 'moment-timezone';
/* Material UI */
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
/* Components */
import UnitRowInfoItem from './UnitRowInfoItem';
import UnitCardInfoTableItem from '../UnitCard/UnitCardInfoTableItem';
/* Global state */
import { UserContext } from '../../../Context/UserContext';

/* Styles */
const styles = {
    popover: {
        padding: '8px',
        minWidth: '360px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px,1fr))',
        gridGap: '2px',
        pointerEvents: 'none',
        '@media (max-width: 480px)': {
            minWidth: '200px',
        }
    },
    popoverInside: {
        padding: '8px',
        minWidth: '360px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px,1fr))',
        gridGap: '2px',
        '@media (max-width: 480px)': {
            minWidth: '200px',
        }
    },
    paper: {
        padding: '8px',
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'secondary.medium',
        pointerEvents: 'auto',
    }
};
    

const UnitRowInfo = ({ unit, expired }) => {
    
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);
    const { user } = useContext(UserContext);

    const popoverEnter = () => {
        setOpenedPopover(true)
    };
    
    const popoverLeave = () => {
        setOpenedPopover(false)
    };

    const defaultUnitItems = ["files_from_cam", "files_uploaded", "fails_shot", "uptime", "temperature", "voltage"];
    const allUnitItems = ["files_from_cam", "files_uploaded", "fails_shot", "uptime", "temperature", "voltage", "humidity", "local_storage", "rssi"];

    if (!unit.version_sw || parseInt(unit.version_sw.charAt(0)) < 3) {
        return (
            <>
                <Grid item xs={1} style={{marginRight: '8px'}}>
                    <UnitRowInfoItem unit={unit} item={defaultUnitItems[0]} expired={expired} />
                    <UnitRowInfoItem unit={unit} item={defaultUnitItems[1]} expired={expired} />
                </Grid>

                <Grid item xs={1} style={{marginRight: '8px'}}>
                    <UnitRowInfoItem unit={unit} item={defaultUnitItems[2]} expired={expired} />
                    <UnitRowInfoItem unit={unit} item={defaultUnitItems[3]} expired={expired} />
                </Grid>

                <Grid item xs={1} style={{marginRight: '8px'}}>
                    <UnitRowInfoItem unit={unit} item={defaultUnitItems[4]} expired={expired} />
                    <UnitRowInfoItem unit={unit} item={defaultUnitItems[5]} expired={expired} />
                </Grid>
            </>
        );
    }
    else {
        return (
            <>
                {user.config.sensor_data 
                    ?
                    <>
                        <Grid item xs={1} style={{marginRight: '8px'}}>
                            <UnitRowInfoItem unit={unit} item={user.config.sensor_data[0]} expired={expired} />
                            <UnitRowInfoItem unit={unit} item={user.config.sensor_data[1]} expired={expired} />
                        </Grid>

                        <Grid item xs={1} style={{marginRight: '8px'}}>
                            <UnitRowInfoItem unit={unit} item={user.config.sensor_data[2]} expired={expired} />
                            <UnitRowInfoItem unit={unit} item={user.config.sensor_data[3]} expired={expired} />
                        </Grid>

                        <Grid item xs={1} style={{marginRight: '8px'}}>
                            <UnitRowInfoItem unit={unit} item={user.config.sensor_data[4]} expired={expired} />
                            <UnitRowInfoItem unit={unit} item={user.config.sensor_data[5]} expired={expired} />
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={1} style={{marginRight: '8px'}}>
                            <UnitRowInfoItem unit={unit} item={defaultUnitItems[0]} expired={expired} />
                            <UnitRowInfoItem unit={unit} item={defaultUnitItems[1]} expired={expired} />
                        </Grid>

                        <Grid item xs={1} style={{marginRight: '8px'}}>
                            <UnitRowInfoItem unit={unit} item={defaultUnitItems[2]} expired={expired} />
                            <UnitRowInfoItem unit={unit} item={defaultUnitItems[3]} expired={expired} />
                        </Grid>

                        <Grid item xs={1} style={{marginRight: '8px'}}>
                            <UnitRowInfoItem unit={unit} item={defaultUnitItems[4]} expired={expired} />
                            <UnitRowInfoItem unit={unit} item={defaultUnitItems[5]} expired={expired} />
                        </Grid>
                    </>
                }

                

                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: '8px ', left: '0'}}>
                        {(!expired || user.type === "admin") &&
                            <Icon
                                ref={popoverAnchor}
                                aria-owns={'mouse-over-popover'}
                                aria-haspopup="true"
                                onMouseEnter={popoverEnter}
                                onMouseLeave={popoverLeave}
                                color="secondary"
                            >
                                info
                            </Icon>
                        }
                        <Popover
                            id="mouse-over-popover"
                            sx={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={openedPopover}
                            anchorEl={popoverAnchor.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            disableRestoreFocus
                            PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
                        >
                            <Box sx={styles.popoverInside}>
                                {allUnitItems.map(item => <UnitCardInfoTableItem unit={unit} item={item} expired={expired}/>)}
                            </Box>
                        </Popover>
                    </div>
                </div>
            </>
        );
    }
}

export default UnitRowInfo;