import React from 'react';
import { useTranslation } from 'react-i18next';
/* Material UI */
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Icon from '@mui/material/Icon';
/* Styles */
import { styles } from './CustomSearchField.styles';


const SearchBox = ({
    autoFocus,
    handleInputChange,
    searchQuery,
    ...other
}) => {
    const { t } = useTranslation();

    return (
        <Paper component="form" sx={styles.paper} elevation={3} {...other}>
            <InputBase
                autoFocus={autoFocus}
                data-testid="search-box"
                sx={styles.inputBase}
                placeholder={t('browsers.overview.label.search') + "..."}
                inputProps={{ 'aria-label': t('browsers.overview.label.search') }}
                value={searchQuery}
                onChange={handleInputChange}
                name="search-box"
                type="text"
            />
            <Icon>search</Icon>
        </Paper>
    );
}

export default SearchBox;