import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
/* Components */
import UnitsListModal from '../../modals/UnitsListModal/UnitsListModal';
import CustomButton from '../../lib/CustomButton/CustomButton';

/* Styles */
const styles = {
    smallerDivider: {
        marginTop: '8px',
        marginBottom: '8px',
        height: '2px'
    },
    list: {
        width: '100%',
    },
    listItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
        backgroundColor: 'secondary.ultraLight',
        borderRadius: '4px',
        marginBottom: '8px'
    },
    listItemTitle: {
        fontSize: 20,
        fontWeight: 600
    },
};

const UserUnits = ({ customer, deleteUnit, addUnit }) => {
 
    const { t } = useTranslation();
    const [ unitsListModalOpen, setUnitsListModalOpen ] = useState(false);

    /**
     * OPEN DIALOG
     */
    const handleClickDialogOpen = () => {
        setUnitsListModalOpen(true);
    };

    /**
     * CLOSE DIALOG
     */
    const handleDialogClose = () => {
        setUnitsListModalOpen(false);
    };

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            
            {/* SUBTITLE */}
            <Grid item xs={12} style={{paddingBottom: 0}}>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                    <Typography variant="subtitle1" color="secondary" style={{marginRight: '16px', fontSize: '20px', fontWeight: 600, lineHeight: 1}}>
                        {t('customers.settings.title.userUnits')}
                    </Typography>
                    
                    <CustomButton color="primary" onClick={handleClickDialogOpen} style={{textTransform: 'none', padding: '2px 12px'}}>
                        + {t('customers.settings.button.addUnit')}
                    </CustomButton>
                </div>
                {/* DIVIDER */}
                <Divider sx={styles.smallerDivider} />  
            </Grid>
            
            {/* UNITS LIST */}
            <Grid item xs={12} style={{paddingTop: 0}}>
                <List sx={styles.list}>
                    {customer !== null && customer.units.map((unit) => {
                        
                        return (
                            <ListItem key={unit.id} sx={styles.listItem}>
                                <ListItemText 
                                    primary={
                                        <div>
                                            <Typography sx={styles.listItemTitle} color="primary">
                                                {unit.name.toUpperCase()}
                                            </Typography>
                                            <Typography variant="subtitle2" color="secondary">
                                                ({unit.serial})
                                            </Typography>
                                        </div>
                                    }           
                                />
                                <IconButton aria-label="delete" onClick={() => deleteUnit(unit.id)}>
                                    <Icon style={{fontSize: '32px'}}>delete</Icon>
                                </IconButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>

            {/* UNITS LIST DIALOG FOR ADDING NEW UNITS TO USER */}
            <UnitsListModal
                open={unitsListModalOpen} 
                handleClose={handleDialogClose} 
                user={customer}
                addUnit={addUnit}
            />
        </Grid>
    );

}

export default UserUnits;