import React, { useContext } from 'react';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
/* Material UI */
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
/* Utils */
import { getUnitInfo } from '../../../Utils/useUnitInfo';
/* Global state */
import { UserContext } from '../../../Context/UserContext';
import { colorToRGBAFormat } from '../../../theme';

/* Styles */
const styles = {
    listItemText: {
        margin: '8px 0',
        width: 'max-content',
        '& > .MuiTypography-body1': {
            lineHeight: 1.25
        }
    },
    typography: {
        color: 'black',
        fontSize: 15,
        '@media (max-width: 480px)': {
            fontSize: 13
        }
    },
    icon: {
        color: '#848484',
        '@media (max-width: 480px)': {
            fontSize: '1.2rem'
        }
    },
};

const UnitRowInfoItem = ({ unit, item, expired }) => {
    
    const { title, icon, value, color } = getUnitInfo(unit, item);
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    let styleIcon = {};
    let styleTypography = {}
    if (color && (!expired || user.type === "admin")) {
        let clr = colorToRGBAFormat(color, .9)
        styleIcon = {
            color: clr,
        };
        styleTypography = {
            color: clr,
            fontWeight: 600
        };
    }

    return (
        <ListItemText 
            sx={styles.listItemText}
            primary={         
                <div style={{display: 'flex', alignItems: "flex-end"}}>                 
                    <Tooltip title={t(title)}>
                        <div style={{display: 'flex'}}>
                            <Icon sx={styles.icon} style={styleIcon}>{icon}</Icon>
                            &nbsp;
                            <Typography sx={styles.typography} style={styleTypography}>
                                {(!expired || user.type === "admin") ? value : "-"}
                            </Typography>
                        </div>
                    </Tooltip>
                </div>
            }
        />
    );  
}

export default UnitRowInfoItem;