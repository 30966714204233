import React from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
/* Components */
import CustomColorPalette from '../../lib/CustomColorPalette/CustomColorPalette';
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../../lib/CustomSelectField/CustomSelectField';
import CustomDropzone from '../../lib/CustomDropzone/CustomDropzone';

/* Styles */
const styles = {
    themeColorBox: {
        width: 'calc(100% - 240px)',
        '@media (max-width: 480px)': {
            width: '100%'
        }
    },
    customLabel: {
        position: 'absolute',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        padding: '2px 8px',
        transform: 'translate(10px, -9px) scale(0.75)',
        borderRadius: '4px',
        whiteSpace: 'noWrap'
    }
};

const BrowserForm = ({
    browser,
    error,
    handleChange,
    handleColorChange,
    handleLogoChange,
    handleLogoDelete,
    newBrowser = false,
}) => {
 
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3} xl={2}>
                <CustomDropzone 
                    handleLogoChange={handleLogoChange}
                    handleDelete={handleLogoDelete}
                />
            </Grid>
            
            <Grid item xs={12} md={8} lg={9} xl={10}>
                <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {/* Browser name */}
                         <CustomTextField 
                            name="title"
                            error={error === "missingBrowserName"}
                            value={browser.title}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.browserName').toUpperCase()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* URL path */}
                        <CustomTextField 
                            name="url_path"
                            error={error === "missingURLPath"}
                            value={browser.url_path}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.URLPath').toUpperCase()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* Description */}
                        <CustomTextField 
                            name="description"
                            value={browser.description}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.description').toUpperCase()}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        {/* Generate video */}
                        <CustomSelectField
                            name="gen_video"
                            value={browser.gen_video}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.generateVideo').toUpperCase()}
                        >
                            <MenuItem value={true}>{t('browsers.settings.label.yes')}</MenuItem>
                            <MenuItem value={false}>{t('browsers.settings.label.no')}</MenuItem>
                        </CustomSelectField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* Face blur */}
                        <CustomSelectField
                            name="face_blur"
                            value={browser.config.face_blur}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.faceBlur').toUpperCase()}
                        >
                            <MenuItem value={true}>{t('browsers.settings.label.yes')}</MenuItem>
                            <MenuItem value={false}>{t('browsers.settings.label.no')}</MenuItem>
                        </CustomSelectField>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        {/* Password */}
                        <CustomTextField
                            disabled={browser.password_remove}
                            name="password"
                            value={browser.password}
                            handleChange={handleChange}
                            label={newBrowser ? t('browsers.settings.label.password').toUpperCase() : t('browsers.settings.label.newPassword').toUpperCase()}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {/* Password confirmation */}
                        <CustomTextField
                            disabled={browser.password_remove}
                            name="password_confirmation"
                            value={browser.password_confirmation}
                            handleChange={handleChange}
                            label={newBrowser ? t('browsers.settings.label.passwordConfirmation').toUpperCase() : t('browsers.settings.label.newPasswordConfirmation').toUpperCase()}
                            type="password"
                        />
                    </Grid>

                    <Grid item xs={12} lg={9}>
                        <Grid container alignItems="flex-end" spacing={2}>
                            <Grid item sx={styles.themeColorBox}>
                                {/* Theme color */}
                                <FormControl style={{width: '100%'}}>
                                    <FormLabel sx={styles.customLabel}>{t('browsers.settings.label.themeColor').toUpperCase()}</FormLabel>
                                    <Container style={{padding: 0, maxWidth: '100%'}}>
                                        <Typography component="div" style={{ backgroundColor: `${browser.config.custom_color}`, height: '56px', borderRadius: '4px', color: "black", fontWeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '6px', whiteSpace: 'nowrap', fontSize: '.9rem' }}>{browser.config.custom_color}</Typography>
                                    </Container>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                {/* Color palette */}
                                <CustomColorPalette handleColorChange={handleColorChange} />
                            </Grid>
                        </Grid>                  
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {/* Own */}
                        <CustomTextField 
                            name="custom_color"
                            value={browser.config.custom_color}
                            handleChange={handleChange}
                            label={t('browsers.settings.label.ownColor').toUpperCase()}
                        />
                    </Grid>

                    {browser.password_remove !== undefined &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={browser.password_remove}
                                        onChange={handleChange}
                                        name="password_remove"
                                        color="secondary"
                                    />
                                }
                                label="Remove current password and use browser without it."
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );

}

export default BrowserForm;