export const styles = {
    formControl: {
        width: '100%'
    },
    chipsList: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: '2px', //
        backgroundColor: 'primary.medium'
    },
};