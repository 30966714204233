export const styles = {
    active: {
        '&.active': {
            background: 'lightgrey',
            '& .MuiIcon-root': {
                color: 'black',
            },
            '& .MuiTypography-body1': {
                color: 'black',
                fontWeight: 900
            }
        }
    },
};