import React, { useState, useContext, useEffect } from 'react';
/* Material UI */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
/* Components */
import BrowsersTableRow from './BrowsersTableRow';
import BrowsersTableHead from './BrowsersTableHead';
/* Utils */
import { OrderContext } from '../../../Context/OrderContext';

/* Styles */
const styles = {
    table: {
        minWidth: 700,
        '& .MuiTableCell-root': {
            padding: '4px'
        }
    }
};

const BrowsersTable = ({ browsers }) => {

    const [ counter, setCounter ] = useState(0); 

    const currentOrderState = useContext(OrderContext);

    /**
     * CHECK FOR DISMISS ORDER
     */
    useEffect(() => {
        if (currentOrderState.browsers.price === 0){
            setCounter(0);
        }
    }, [currentOrderState.browsers]);

    return (
        <TableContainer component={Paper}>
            <Table sx={styles.table} aria-label="browsers table">
                
                {/* TABLE HEAD */}
                <BrowsersTableHead />
                
                {/* TABLE BODY */}
                <TableBody>

                    {/* BROWSERS ROWS */}
                    {browsers.map(browser => {
                        return <BrowsersTableRow 
                            key={browser.browser_id}
                            browser={browser} 
                            value={counter} 
                        />
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BrowsersTable;