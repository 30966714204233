import React from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Paper from '@mui/material/Paper';
/* Components */
import UserForm from '../components/MyAccount/UserForm';
import FormTitle from '../components/Forms/FormTitle';
import { Divider, Typography } from '@mui/material';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    }
};

const MyAccount = () => {
  
    const { t } = useTranslation();

    return (
        <form noValidate autoComplete="off">
            <Paper sx={styles.paper} elevation={3}>
                
                {/* Title */}
                <FormTitle icon="account_circle" title={t("profile.title.myAccount")} />
                
                {/* User form */}
                <UserForm />

            </Paper>
        </form>

    );

}

export default MyAccount;