import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import Cookies from 'js-cookie'
/* Material UI */
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
/* Components */
import CustomButton from '../components/lib/CustomButton/CustomButton';
import CustomTextField from '../components/lib/CustomTextField/CustomTextField';
/* Global state */
import { AuthenticationContext } from '../Context/AuthenticationContext';
import { WebSocketContext } from '../Context/WebSocketContext';
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import { setToken } from '../Utils/localStorage';
import BackendRequest from '../Utils/BackendRequest';

/* Styles */
const styles = {
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'secondary.light'
    },
    paper: {
        padding: '32px',
        borderRadius: '8px',
        maxWidth: '500px',
        boxSizing: 'border-box',
        margin: 'auto',
        '@media (max-width: 480px)': {
            maxWidth: '95%',
            padding: '24px 16px',
            '& .MuiOutlinedInput-input': {
                padding: '16px 0px'
            }
        }
    },
    title: {
        fontWeight: 600,
        '@media (max-width: 480px)': {
            fontSize: '28px'
        }
    },
    rememberMe: {
        color: "rgba(0, 0, 0, 0.54)",
        "& .MuiTypography-body1": {
            fontSize: "14px !important",
        }
    }
};

const Login = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const { setAuth } = useContext(AuthenticationContext);
    const { socket } = useContext(WebSocketContext);
    const { setSnack } = useContext(SnackbarContext);
    const [state, setState] = useState({
        username: "",
        password: "",
        rememberMe: false,
        error: "",
    })

    /**
     * Handle form change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    useEffect(() => {
        console.log(history.location)
        if (socket !== null) {
            //console.log("[Login] Socket disconnecting...");
            socket.disconnect();
        }
        if (history.location.state !== undefined) {
            if (history.location.state.showSnack)
                setSnack({message: "You have been automatically logged out.", type: "info", open: true})
        }
    }, [])
    
    /**
     * Handle log in
     * @param {*} event 
     * @returns 
     */
    const login = (event) => {

        event.preventDefault();

        /* Validation of username and password */
        if (state.username === ""){
            setState({...state, error: "username"})
            return;
        }
        else if (state.password === ""){
            setState({...state, error: "password"})
            return;
        }
        
        /**
         * 
         * @param {*} response 
         */
        const onSuccess = (response) => {
            setToken(response.data.token, state.rememberMe);
            setAuth(true);
            setState({...state, error: ""})
            /* Set authentication */
            history.push("/dashboard");
        }
        /**
         * 
         * @param {*} error 
         */
        const onError = (error) => {
            console.log('error in /login', error);
            if (error.status === 401) {
                setState({...state, error: t('login.error.wrongUsernameOrPassword')})
            }
            else {
                setState({...state, error: "Error."})
            }
        }
        
        BackendRequest("post", "/login", {"username": state.username, "password": state.password, "remember_me": state.rememberMe}, onSuccess, onError);
    };

    return (
        <Box sx={styles.root}>
            <form onSubmit={login}>
                <Paper sx={styles.paper} elevation={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant="h4" color="primary" sx={styles.title}>
                                {(Cookies.get('title') !== undefined && Cookies.get('title') !== "")? Cookies.get('title') : "BIXION BOARD"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant="subtitle1" color="error" style={{fontWeight: 600}}>
                                {(state.error !== "" && state.error !== "username" && state.error !== "password") && state.error}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField 
                                name="username"
                                value={state.username}
                                error={state.error === "username"}
                                handleChange={handleChange}
                                label={t('login.label.username').toUpperCase()} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField 
                                name="password"
                                value={state.password}
                                error={state.error === "password"}
                                handleChange={handleChange}
                                label={t('login.label.password').toUpperCase()}  
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.rememberMe}
                                        onChange={() => setState({...state, rememberMe: !state.rememberMe})}
                                        name="rememberMe"
                                        color="primary"
                                        style={{paddingRight: '5px'}}
                                        size="small"
                                    />
                                }
                                sx={styles.rememberMe}
                                label="REMEMBER ME"
                            />
                            <CustomButton color="primary" type="submit">
                                {t('login.button.logIn')}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Box>
    );
}

export default Login;