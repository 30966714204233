import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
/* Material UI */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Own */
import CustomButton from '../../lib/CustomButton/CustomButton';
import { OrderActionsContext } from '../../../Context/OrderContext';
import { DELETE_ORDER } from '../../../Context/reducers/orderReducer';

/* Styles */
const styles = {
    typography: {
      fontSize: '16px', 
      marginRight: '16px',
      '@media (max-width: 480px)': {
          marginRight: 0
      },
    },
    bottomLine: {
      width: '100%', 
      height: '70px',
      position: 'fixed', 
      bottom: 0, 
      left: 0, 
      display: 'flex', 
      justifyContent: 'flex-end', 
      alignItems: 'center', 
      backgroundColor: '#E0E0E0', 
      zIndex: 1199, 
      padding: '16px', 
      borderTop: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'secondary.main',
      '@media (max-width: 480px)': {
          height: 'fit-content',
          flexWrap: 'wrap'
      },
    }
};

const SummaryBottomLine = ({ selectedLength, setSelected, label, continueURL }) => {

    const history = useHistory();
    const dispatchOrder = useContext(OrderActionsContext);
    const { t } = useTranslation();

    return(
        <Box sx={styles.bottomLine}>
            <Typography sx={styles.typography}>
                {label}:&nbsp;<strong>{selectedLength}</strong>
            </Typography>
            <div style={{display: 'flex', marginLeft: '12px'}}>
                <CustomButton 
                    color="secondary" 
                    style={{marginRight: '8px'}} 
                    onClick={() => {
                        dispatchOrder({
                            type: DELETE_ORDER
                        });
                        setSelected([]);
                    }}
                >
                    {t('services.summary.button.dismissOrder')}
                </CustomButton>
                <CustomButton color="primary" onClick={() => history.push(continueURL)}>
                    {t('services.summary.button.continue')}
                </CustomButton>
            </div>
        </Box>
    );
}

export default SummaryBottomLine;

