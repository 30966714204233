import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from 'react-router-dom';
/* Material UI */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Components */
import UnitsTable from '../components/Services/Units/UnitsTable';
import CustomButton from '../components/lib/CustomButton/CustomButton';
/* Global state */
import { OrderContext, OrderActionsContext } from '../Context/OrderContext';
import { DELETE_ORDER } from '../Context/reducers/orderReducer';

/* Styles */
const styles = {
    typography: {
        fontSize: '16px', 
        marginRight: '16px',
        '@media (max-width: 480px)': {
            marginRight: 0
        },
    },
    bottomLine: {
        width: '100%', 
        height: '70px',
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        backgroundColor: '#E0E0E0', 
        zIndex: 1199, 
        padding: '16px', 
        borderTop: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'secondary.main',
        '@media (max-width: 767px)': {
            height: 'fit-content',
            flexWrap: 'wrap'
        },
        '& button': {
            '@media (max-width: 767px)': {
                margin: '2px 0'
            },
        }
    }
};

const ServicesSelectedUnits = () => {

    const history = useHistory();
    const { t } = useTranslation();

    const currentOrderState = useContext(OrderContext);
    const dispatchOrder = useContext(OrderActionsContext);

    //console.log("[ServicesSelectedUnits]", currentOrderState);

    return (
        <div style={{position: 'relative', marginBottom: '70px'}}>
            
            {/* Units table */}
            {currentOrderState.units.order.length !== 0 ? <UnitsTable units={currentOrderState.units.order} /> : <Redirect to="/services/units" />}

            <br />

            {/* Summary */}
            <Box sx={styles.bottomLine}>
                <Typography sx={styles.typography}>
                    {t('services.summary.label.totalPrice')}:&nbsp;<strong>{currentOrderState.units.price} €</strong>
                </Typography>
                <div style={{display: 'flex', marginLeft: '12px', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                    <CustomButton color="secondary" onClick={() => window.history.back()}>
                        {t('services.summary.button.back')}
                    </CustomButton>
                    <CustomButton color="secondary" onClick={() => dispatchOrder({type: DELETE_ORDER})} style={{marginLeft: '8px'}}>
                        {t('services.summary.button.dismissOrder')}
                    </CustomButton>
                    <CustomButton 
                        color="primary" 
                        onClick={() => history.push('/services/units/summary')} 
                        style={{marginLeft: '8px'}}
                        disabled={parseInt(currentOrderState.units.price) === 0}
                    >
                        {t('services.summary.button.proceedToSummary')}
                    </CustomButton>
                </div>
            </Box>
        </div>
    );
}

export default ServicesSelectedUnits;