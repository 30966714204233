import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import 'moment/min/locales.js'

import csLocales from './Locales/cs/common.json'
import enLocales from './Locales/en/common.json'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
	.init({
		fallbackLng: 'en',
		react: {},

		// have a common namespace used around the full app
		ns: ['common'],
		defaultNS: 'common',
		lng: 'en',

		debug: process.env.NODE_ENV !== 'production',

		backend: {
			loadPath: '/Locales/{{lng}}/{{ns}}.json',
		},

		resources: {
			cs: {
				common: csLocales
			},
			en: {
				common: enLocales
			},
		},

		interpolation: {
			escapeValue: false, // not needed for react
			formatSeparator: ',',
			//format: function(value, format/*, lng*/) {
				//if (format === 'uppercase') return value.toUpperCase();
				//return value;
			//},
		},
	});

export default i18n;