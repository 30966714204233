import React from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
/* Components */
import CustomButton from '../../lib/CustomButton/CustomButton';

/* Styles */
const styles = {
    bottom: {
        paddingBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-end'
        }
    },
    typography: {
        fontWeight: 600,
        '@media (max-width: 767px)': {
            padding: '16px 0'
        }
    }
};

const CustomerButtons = ({ customer, error, handleSubmit }) => {

    const { t } = useTranslation();

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sx={styles.bottom}>   
                <Typography sx={styles.typography} variant="subtitle1" color="error">
                    {error !== "" && t('error.' + error)}
                </Typography>
                
                <div style={{display: 'flex'}}>
                    {/* Cancel button */}
                    <CustomButton color="secondary" onClick={() => window.history.back()} style={{marginRight: '8px'}}>
                        {t('customers.settings.button.cancel')}
                    </CustomButton>

                    {/* Save changes for existing customer */}
                    <CustomButton color="primary" onClick={handleSubmit}>
                        {customer ? t('customers.settings.button.saveChanges') : t('customers.settings.button.add')}
                    </CustomButton>
                </div>
            </Grid>
        </Grid>
    );

}

export default CustomerButtons;