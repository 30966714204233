import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
/* Material UI */
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
/* Components */
import AppBar from '../AppBar/AppBar';
import Drawer from '../Drawer/Drawer';
import CustomButton from '../lib/CustomButton/CustomButton';

/* Styles */
const styles = {
    root: {
        display: 'flex',
        maxWidth: '100%'
    },
    content: {
        flexGrow: 1,
        padding: '24px',
        marginBottom: '3rem',
        maxWidth: '100%',
        //overflow: 'auto',
        '@media (max-width: 480px)': {
            padding: '12px',
            marginBottom: '3.5rem',
        },
    },
    "@keyframes up-arrow": {
        "0%": {
            "-webkit-transform": "translateY(2px)",
        },
        "100%": {
            "-webkit-transform": "translateY(-2px)",
        }
      },
    scrollToTop: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        width: 50,
        height: 50,
        zIndex: 999,
        minWidth: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        boxShadow: 'rgba(133, 133, 133, 1) 0px 0px 15px 2px !important', // secondary.main
        backgroundColor: "#101010",
        opacity: ".85",
        transition: "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            backgroundColor: "#101010",
            opacity: "1",
        }
    },
    icon: {
        fontSize: 44,
        animation: `$up-arrow 1s alternate ease-in-out infinite`,
        transformOrigin: "50% 50%"
    }
};

const Layout = ({ children }) => {

    const [visible, setVisible] = useState(false)
    
    //console.log("[LAYOUT]");
    
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        } 
        else if (scrolled <= 300){
            setVisible(false)
        }
    };
      
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <Box sx={styles.root}>
            
            <CssBaseline />
            <AppBar />
            <Drawer />
            
            <Box sx={styles.content}>
                <Toolbar />
                {children}
            </Box>
            
            <CustomButton color="secondary" sx={styles.scrollToTop} style={{display: visible ? 'flex' : 'none'}} onClick={scrollToTop}>
                <Icon sx={styles.icon}>keyboard_arrow_up</Icon>
            </CustomButton>

        </Box>
    );

}

export default withRouter(React.memo(Layout));