import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Global state */
import { OrderContext } from '../../../Context/OrderContext';

/* Styles */
const styles = {
    unitRow: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        padding: '0 12px'
    },
    unitName: {
        fontWeight: 600
    },
    divider: {
        marginTop: '8px',
        marginBottom: '24px',
        height: '2px'
    },
};


const UnitsSummaryRow = ({ orderItem }) => {

    const { t } = useTranslation();

    const currentOrderState = useContext(OrderContext);

    return (
        <>
            <Box sx={styles.unitRow}>
                <div style={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', paddingRight: '8px'}}>
                    <Typography sx={styles.unitName} variant="h6" color="primary">
                        {orderItem.unit_name.toUpperCase()}
                    </Typography>
                    &nbsp;
                    <Typography variant="subtitle1">
                        ({t('services.unitsTable.label.serialNumberAbbr')}: {orderItem.unit_serial})
                    </Typography>
                </div>
                <Typography variant="h6">
                    {(Math.round((orderItem.services[0].months * currentOrderState.units[currentOrderState.units.used_price_per_one]) * 100) / 100).toFixed(2)}&nbsp;€
                </Typography>
            </Box>
            <ul>
                <li>{orderItem.services[0].service_name + ": " + orderItem.services[0].months + " " + t('services.unitsTable.label.smallMonths')}</li>
            </ul>
            <Divider sx={styles.divider} />
        </>
    );

}

export default UnitsSummaryRow;