import React, { useState, useRef, useCallback, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import { useSetRecoilState } from 'recoil';
/* Material UI */
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
/* Global state */
import { updateServerTimeState } from '../Context/globalState';
/* Utils */
import useUnitsSearch from '../Utils/useUnitsSearch';
/* Components */
import DashboardGrid from '../components/Dashboard/DashboardGrid';
import DashboardRows from '../components/Dashboard/DashboardRows';
import CustomSearchField from '../components/lib/CustomSearchField/CustomSearchField';
import CustomSpinner from '../components/lib/CustomSpinner/CustomSpinner';
import UnitInfoConfigModal from '../components/modals/UnitInfoConfigModal/UnitInfoConfigModal';
import CustomButton from '../components/lib/CustomButton/CustomButton';

/* Styles */
const styles = {
    topBar: {
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: '16px',
        padding: '0 6px'
    },
    iconButton: {
        padding: '4px'
    },
};

const Dashboard = () => {

    const { t } = useTranslation();

    const [gridMode, setGridMode] = useState(JSON.parse(localStorage.getItem('gridMode')) ?? true);
    const [searchQuery, setSearchQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [unitInfoConfigModalOpen, setUnitInfoConfigModalOpen] = useState(false);

    const updateServerTime = useSetRecoilState(updateServerTimeState);

    const {
        error,
        hasMore,
        loading,
        units,
    } = useUnitsSearch(searchQuery, pageNumber);

    /**
     * Search handling 
     */
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
        setPageNumber(0);
    }

    /**
     * Observer - When observer is reached, new request is created
     * https://github.com/WebDevSimplified/React-Infinite-Scrolling/blob/master/src/App.js
     */
    const observer = useRef();
    const lastUnitRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        }, {rootMargin: "600px"})
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    useEffect(() => {
        localStorage.setItem('gridMode', JSON.stringify(gridMode));
    }, [gridMode]);
    
    /**
     * Updating server time every 60 seconds
     */
    useEffect(() => {
        
        updateServerTime(); // Initial server time
        const intervalId = setInterval(() => {
            updateServerTime();
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    /**
     * displayMode switch button - grid or rows
     */
     let displayMode = (
        <IconButton sx={styles.iconButton} aria-label="Rows icon" onClick={() => setGridMode(false)}>
            <Icon>list</Icon>
        </IconButton>
    );
    if (!gridMode) {
        displayMode = (
            <IconButton sx={styles.iconButton} aria-label="Grid icon" onClick={() => setGridMode(true)}>
                <Icon>apps</Icon>
            </IconButton>
        );
    }

    return (
        <div>
            {/* Topbar */}
            <Box sx={styles.topBar}>
                
                {displayMode}
                
                <div style={{display: 'flex'}}>
                    <CustomButton
                        startIcon={<Icon>settings</Icon>}
                        onClick={() => setUnitInfoConfigModalOpen(true)}
                    >
                        {t('dashboard.button.configuration')}
                    </CustomButton>
                    &nbsp;&nbsp;
                    <CustomSearchField
                        searchQuery={searchQuery} 
                        handleInputChange={handleInputChange} 
                    />
                </div>
            </Box>

            {/* Show units in grid or rows, if there are some */}
            {((units.length === 0 && !loading) || !units) 
                ? t('dashboard.label.noUnitsWereFound') 
                : gridMode ? <DashboardGrid units={units} /> : <DashboardRows units={units} />
            }

            {/* Spinner */}
            <div ref={lastUnitRef}>
                {(hasMore || loading) ? <CustomSpinner /> : null}
            </div>

            {/* Error */}
            {error ? t('error.somethingWentWrong') : null}

            {/* Unit info configuration dialog */}
            {unitInfoConfigModalOpen ? (
                <UnitInfoConfigModal
                    handleClose={() => setUnitInfoConfigModalOpen(false)}
                    open={unitInfoConfigModalOpen}
                />
            ): null}
    
        </div>
    );

}

export default Dashboard;