import React from 'react';
import {useTranslation} from "react-i18next";
import {DropzoneArea} from 'react-mui-dropzone'
/* Material UI */
import Box from '@mui/material/Box';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
/* Styles */
import { styles } from './CustomDropzone.styles';


const CustomDropzone = ({
    handleDelete,
    handleLogoChange,
}) => {

    const { t } = useTranslation();

    return (
        <Box sx={styles.container}>
            <DropzoneArea
                acceptedFiles={['image/*']}
                dropzoneText={t('browsers.settings.label.DragAndDropOrClick')}
                filesLimit={1}
                Icon={ImageOutlinedIcon}
                onChange={handleLogoChange}
                onDelete={handleDelete}
                showAlerts={false}
                showPreviews={false}
                showPreviewsInDropzone={true}
                useChipsForPreview={true}
            />
        </Box>
    );
}

export default CustomDropzone;