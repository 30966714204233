import { useState, useEffect, useContext } from 'react';
import { useRecoilValue } from 'recoil';
/* Global state */
import { OrderContext, OrderActionsContext } from '../Context/OrderContext';
import { DELETE_ORDER_ITEM, UPDATE_ORDER } from '../Context/reducers/orderReducer';
/* Utils */
import { briefUnitsState, briefBrowsersState } from '../Context/globalState';

/**
 * 
 * @param {*} a 
 * @param {*} b 
 * @param {*} orderBy 
 * @returns 
 */
 const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "remote_management") { /* Remote management - date to */
        if (new Date(b.services[0].date_to) > new Date(a.services[0].date_to)) return -1;
        if (new Date(b.services[0].date_to) < new Date(a.services[0].date_to)) return 1;
    }
    else if (orderBy === "browser") { /* Browsers - date to */
        if (new Date(b.date_to) > new Date(a.date_to)) return -1;
        if (new Date(b.date_to) < new Date(a.date_to)) return 1;
    }
    else { /* Name for units, title for browsers */
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
    }
    return 0;
}

/**
 * 
 * @param {*} order 
 * @param {*} orderBy 
 * @returns 
 */
export const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => -descendingComparator(a, b, orderBy) : (a, b) => descendingComparator(a, b, orderBy);
}

/**
 * 
 * @param {*} array 
 * @param {*} comparator 
 * @returns 
 */
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const useOrder = (category, searchQuery) => {

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');

    const currentOrderState = useContext(OrderContext);
    const dispatchOrder = useContext(OrderActionsContext);
    const briefUnits = useRecoilValue(briefUnitsState);
    const briefBrowsers = useRecoilValue(briefBrowsersState);
    const briefItems = (category === "units") ? briefUnits : briefBrowsers;

    let selectedArray = [];
    if (category === "units")
        currentOrderState.units.order.map(order => selectedArray.push(order.unit_id));
    else
        currentOrderState.browsers.order.map(order => selectedArray.push(order.browser_id));

    const [items, setItems] = useState(briefItems);
    const [selected, setSelected] = useState(selectedArray);

    /**
     * Update value in OrderContext
     * @param {*} item
     */
     const dispatchUpdateValue = (item) => {
        dispatchOrder({
            type: UPDATE_ORDER,
            category: category,
            orderItem: (category === "units") ? 
                {
                    unit_id: item.id,
                    unit_name: item.name,
                    unit_serial: item.serial_number,
                    services: [
                        {
                            service_name: "Remote management",
                            months: 1,
                        }
                    ] 
                } : 
                /* (category === "browsers") */
                {
                    browser_id: item.id,
                    browser_name: item.title,
                    date_to: item.date_to,
                    services: [
                        {
                            service_name: "Browser",
                            months: 1,
                        }
                    ] 
                }
        })
    }
    
    /**
     * Delete Order Item
     * @param {*} itemId
     */
    const dispatchDeleteOrderItem = (itemId) => {
        dispatchOrder({
            type: DELETE_ORDER_ITEM,
            category: category,
            id: itemId
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, item) => {
        const selectedIndex = selected.indexOf(item.id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, item.id);
          dispatchUpdateValue(item);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
          dispatchDeleteOrderItem(item.id);
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
          dispatchDeleteOrderItem(item.id);
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
          dispatchDeleteOrderItem(item.id);
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = items.map((item) => {dispatchUpdateValue(item); return item.id; });
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    /**
     * If search query changes
     */
     useEffect(() => {
        if(briefItems.length > 0) {
            const resultArray = (category === "units") 
                ? briefItems.filter(briefItem => briefItem.name.includes(searchQuery.trimEnd().toUpperCase()) || briefItem.serial_number.includes(searchQuery.trimEnd().toUpperCase()))
                : briefItems.filter(briefItem => briefItem.title.toUpperCase().includes(searchQuery.trimEnd().toUpperCase()))
            setItems(resultArray);
        }
        else {
            setItems([]);
        } 
    }, [searchQuery, briefItems])

    return { order, orderBy, getComparator, stableSort, isSelected, selected, setSelected, handleSelectAllClick, handleClick, handleRequestSort, items };
}

export default useOrder;