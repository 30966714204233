import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
/* Material UI */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
/* Components */
import CustomButton from '../CustomButton/CustomButton';
/* Styles */
import { styles } from './CustomDialog.styles';

const CustomDialog = ({
    buttons,
    content,
    handleAction,
    handleClose,
    open,
    title
}) => {
    
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            sx={styles.dialog}
        >
            <DialogTitle>
                <strong>
                    {title}
                </strong>
            </DialogTitle>

            <Divider />
            
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            
            <DialogActions sx={styles.dialogActions}>
                <CustomButton 
                    color="secondary" 
                    onClick={handleClose}
                    style={{width: '100%'}}
                >
                    {t('customers.dialog.button.cancel')}
                </CustomButton>
                {buttons.map(btn => (
                    <CustomButton
                        autoFocus
                        color="primary"
                        key={btn.label}
                        onClick={btn.action}
                        style={{width: '100%'}}
                    >
                        {btn.label}
                    </CustomButton>
                ))}
            </DialogActions>
        </Dialog>
    );
}

CustomDialog.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    content: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default CustomDialog;