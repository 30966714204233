import React  from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

/* Styles */
const styles = {
    expired: {
        color: 'common.red',
    },
    active: {
        color: 'common.green',
    },
    beforeExpiration: {
        color: 'common.orange',
    },
    row: {
        '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
        }
    },
    selectedRow: {
      backgroundColor: 'primary.light',
      '&:hover': {
        cursor: 'pointer',
    }
    },
};

const AllUnitsTableRow = ({handleClick, isItemSelected, labelId, unit, innerRef = null }) => {

    const { t } = useTranslation();

    const sevenDaysFromToday = new Date();
    sevenDaysFromToday.setDate(sevenDaysFromToday.getDate() + 7);

    return (
        <TableRow
            onClick={(event) => handleClick(event, unit)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={unit.id}
            sx={Object.assign({}, isItemSelected ? styles.selectedRow : styles.row)}
            ref={innerRef}
        >
            {/* CHECKBOX */}
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </TableCell>
            
            {/* UNIT NAME WITH SERIAL NUMBER */}
            <TableCell>
                <Typography color="primary" gutterBottom>
                    <Box fontWeight="fontWeightBold">
                        {unit.name}
                    </Box>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {t('services.unitsTable.label.serialNumberAbbr')}: {unit.serial_number}
                </Typography>
            </TableCell>

            {/* STATE */}
            <TableCell align='center'>
                {(unit.services && Object.entries(unit.services).length !== 0) ? new Date() > new Date(unit.services[0].date_to) ? 
                    <Typography sx={styles.expired} gutterBottom>
                        <Box fontWeight="fontWeightBold">
                            {t("services.common.label.expired")}
                            <br />
                            ({t("services.common.label.since")} {unit.services[0].date_to})
                        </Box>
                    </Typography>
                    : 
                    <Typography
                        gutterBottom
                        sx={new Date(unit.services[0].date_to) > sevenDaysFromToday ? styles.active : styles.beforeExpiration}
                    >
                        <Box fontWeight="fontWeightBold">
                            {t("services.common.label.active")}
                            <br />
                            ({t("services.common.label.until")} {unit.services[0].date_to})
                        </Box>
                    </Typography>
                : null}
            </TableCell>
        </TableRow>              
    );
}

export default React.memo(AllUnitsTableRow);