import React, { createContext, useState, useMemo } from 'react';

export const DrawerContext = createContext();

const DrawerProvider = ({ children }) => {

    const [drawerState, toggleDrawer] = useState(false);
    const drawerProviderValue = useMemo(() => ({drawerState, toggleDrawer}), [drawerState, toggleDrawer])

    return <DrawerContext.Provider value={drawerProviderValue}>
        {children}
    </DrawerContext.Provider>
};

export default DrawerProvider;