import React from 'react';
/* Material UI */
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
/* Styles */
import { styles } from './CustomSpinner.styles';


const CustomSpinner = ({...otherProps}) => {

    return (
        <Box sx={styles.spinnerWrapper} data-testid="spinner" {...otherProps}>
            <CircularProgress />
        </Box>
    );
}

export default CustomSpinner;