export const styles = {
    modal: {
        '& .MuiDialog-paperScrollPaper': {
            minWidth: '500px',
            maxWidth: '600px',
            minHeight: '240px',
            '@media (max-width: 480px)': {
                minWidth: '300px',
                maxWidth: '100vw',
            }
        },
    },
    title: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 600,
        gap: '12px',
        '@media (max-width: 767px)': {
            gap: '8px'
        }
    },
    searchBox: {
        height: '32px',
        position:'absolute',
        right: 24,
        top: 16,
    },
    descriptionContent: {
        overflow: 'visible !important',
        padding: '0 24px'
    },
    mainContent: {
        paddingTop: '0px',
        paddingBottom: '0px',
        margin: '8px 0 20px' // because of overflowing the padding when overflow-y set to auto/scroll (e.g. in UnitsListModal)
    },
    errorContent: {
        overflow: 'hidden',
        '& > .MuiTypography-root': {
            fontWeight: 600,
            '@media (max-width: 767px)': {
                padding: '16px 0'
            }
        }
    },
    dialogActions: {
        display: 'flex',
        '&.MuiDialogActions-root>:not(:first-of-type)': {
            '@media (max-width: 767px)': {
                margin: '0 !important',
            }
        },
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            gap: '4px'
        }
    },
};