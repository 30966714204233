import React from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CustomButton from '../../lib/CustomButton/CustomButton';

/* STYLES */
const styles = {
    bottom: {
        paddingBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-end'
        }
    },
    typography: {
        fontWeight: 600,
        '@media (max-width: 767px)': {
            padding: '16px 0'
        }
    }
};

const BrowserButtons = ({ error, handleSubmit, newBrowser = false }) => {

    const { t } = useTranslation();

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sx={styles.bottom}>
                
                <Typography sx={styles.typography} variant="subtitle1" color="error">
                    {error !== "" && t('error.' + error)}
                </Typography>
                
                <div style={{display: 'flex'}}>
                    <CustomButton color="secondary" onClick={() => window.history.back()} style={{marginRight: '8px'}}>
                        {t('browsers.settings.button.cancel')}
                    </CustomButton>
                    <CustomButton color="primary" onClick={handleSubmit}>
                        {newBrowser ? t('browsers.settings.button.add') : t('browsers.settings.button.saveChanges')}
                    </CustomButton>
                </div>

            </Grid>
        </Grid>
    );
}

export default BrowserButtons;