import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
/* Material UI */
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';
import CustomSpinner from '../components/lib/CustomSpinner/CustomSpinner';
import CustomButton from '../components/lib/CustomButton/CustomButton';
import ACLWrapper from '../Containers/ACLWrapper';
import { UserContext } from '../Context/UserContext';

export const styles = {
  formControl: {
    width: '100%'
  },
  title: {
    fontWeight: 600,
    mb: '16px',
    '@media (max-width: 480px)': {
        fontSize: '1.25rem'
    }
},
};

const Logs = () => {

  const [unitName, setUnitName] = useState("");
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfRecords, setNumberOfRecord] = useState(1000);
  const [to, setTo] = useState(moment());
  const [adminFilter, setAdminFilter] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { user } = useContext(UserContext);

  const unitId = window.location.pathname.split('/')[2];

  const getUnitInfo = () => {
    const onSuccess = (response) => {
      setUnitName(response.data.name);
    }
    const onError = (error) => {
        console.log(`/unit/${unitId}`);
        if (error.status === 401 || error.status === 403) {
            localStorage.removeItem("token");
            history.push({ pathname: '/login', state: { showSnack: true }});
        }
    }
    
    BackendRequest(
      "get",
      `/unit/${unitId}`,
      null,
      onSuccess,
      onError
    );
  }

  const handleFilter = () => {
    setLogs([]);
    setIsLoading(true);
    setError(null);

    const onSuccess = (response) => {
      // If there are only "secret" logs for admin, show "There are no logs available."
      if (response.data.find((log => log.time))) {
        setLogs(response.data);
      }
      setIsLoading(false);
    }
    const onError = (error) => {
      if (user.type === "admin") {
        console.log(`/unit/${unitId}/log?records=${numberOfRecords}&to=${to.format('YYYY-MM-DDTHH:mm:ss')}&admin_filter=`, error);
      } else {
        console.log(`/unit/${unitId}/log?records=${numberOfRecords}&to=${to.format('YYYY-MM-DDTHH:mm:ss')}`, error);
      }
        
      if (error.status === 401 || error.status === 403) {
          localStorage.removeItem("token");
          history.push({ pathname: '/login', state: { showSnack: true }});
      }
      else {
        setError(error.data.error);
        setIsLoading(false);
      }
    }
    
    if (user.type === "admin") {
      BackendRequest(
        "get",
        `/unit/${unitId}/log?records=${numberOfRecords}&to=${to.format('YYYY-MM-DDTHH:mm:ss')}&admin_filter=${adminFilter}`,
        null,
        onSuccess,
        onError
      );
    } else {
      BackendRequest(
        "get",
        `/unit/${unitId}/log?records=${numberOfRecords}&to=${to.format('YYYY-MM-DDTHH:mm:ss')}`,
        null,
        onSuccess,
        onError
      );
    }
    
  }

  useEffect(() => {
    getUnitInfo();
    handleFilter();
  }, []);

  if (!unitName) {
    return null;
  }

  let content;
  if (logs.length === 0 && isLoading) {
    content = <CustomSpinner />;
  } else if (error) {
    content = <Typography sx={{ mt: '16px' }}>{error}</Typography>;
  } else if (logs.length === 0 && !isLoading) {
    content = <Typography sx={{ mt: '16px' }}>There are no logs available.</Typography>;
  } else {
    content = (
      <table style={{ borderSpacing: '8px' }}>
        <tbody>
          {logs.map((log, i) => {
            const dateTime = moment(log.time * 1000).format('YYYY-MM-DD HH:mm:ss');
            return (
              <tr key={i}>
                <td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>
                  <strong>{dateTime}</strong>
                </td>
                <td>
                  {log.msg}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <ACLWrapper requiredPermission={'ACL_RESELLER_ADMIN'}>
      <Typography sx={styles.title} variant="h5">
        {`${unitName.toUpperCase()} (ID: ${unitId})`}
      </Typography>
      <Box sx={{
        display: 'flex',
        columnGap: '8px',
        rowGap: '16px',
        alignItems: 'center',
        mt: '16px',
        mb: '8px',
        flexWrap: 'wrap'
      }}>
        <DateTimePicker
          label="TO"
          name="to"
          ampm={false}
          format={'YYYY-MM-DD HH:mm'}
          timezone='system'
          value={to}
          onChange={setTo}
          renderInput={(params) => <TextField {...params} />}
          DialogProps={{
              PaperProps: {
                  sx: { "& *:focus": { outline: "none" } }
              }
          }}
        />
        <FormControl component="fieldset" sx={{ padding: { xs: '8px 8px 0', md: '0 12px'} }}>
          <FormLabel id="number-of-records" style={{ fontSize: '12px', marginTop: '-8px' }}>NUMBER OF RECORDS</FormLabel>
            <RadioGroup
              aria-label="number-of-records"
              name="number-of-records"
              value={numberOfRecords}
              onChange={(event) => setNumberOfRecord(event.target.value)}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
                <FormControlLabel value={10} control={<Radio />} label='10' />
                <FormControlLabel value={100} control={<Radio />} label='100' />
                <FormControlLabel value={1000} control={<Radio />} label='1K' />
                <FormControlLabel value={10000} control={<Radio />} label='10K' />
            </RadioGroup>
        </FormControl>
        <ACLWrapper requiredPermission={'ACL_ADMIN'}>
          <FormControlLabel
            control={
                <Checkbox
                    checked={adminFilter}
                    onChange={(e) => { setAdminFilter(e.target.checked) }}
                    name="adminFilter"
                />
            }
            label="Admin filter"
          />
        </ACLWrapper>
        <CustomButton color="primary" onClick={handleFilter} sx={{ mt: { xs: '-8px', md: '0'} }}>
            Filter
        </CustomButton>
      </Box>
      <Divider />
      {content}
    </ACLWrapper>
  );
}

export default Logs;