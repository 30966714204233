import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
/* Material UI */
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
/* Global state */
import { serverTimeState, updateUnitState } from '../../../Context/globalState';
/* Utils */
import { calculateNextWakeupTime, getAccessMode } from '../../../Utils/PWRModeHandler';
import BackendRequest from '../../../Utils/BackendRequest';

/* Styles */
const styles = {
    listItemText: {
        margin: '8px 0',
        width: 'max-content',
        '& > .MuiTypography-body1': {
            lineHeight: 1.25
        }
    },
    switch: {
        width: '50px', 
        height: '30px', 
        padding: '9px',
        '& > .MuiButtonBase-root': {
            padding: '5px'
        },
        '& .Mui-disabled+.MuiSwitch-track': {
            backgroundColor: 'black !important',
        },
        '& .Mui-disabled': {
            color: '#bdbdbd !important',
        },
        '& .Mui-checked.Mui-disabled': {
            color: '#bdbdbd !important',
        },
        '@media (max-width: 480px)': {
            width: '44px',
            height: '28px', 
            '& > .MuiButtonBase-root': {
                padding: '6px'
            },
            '& .MuiSwitch-thumb': {
                width: '16px',
                height: '16px',
            }
        }
    },
    typography: {
        color: 'black',
        fontSize: 15,
        '@media (max-width: 480px)': {
            fontSize: 13
        }
    },
    typographyCaption: {
        fontSize: 12,
        '@media (max-width: 480px)': {
            fontSize: 11
        }
    }
};

const UnitRowSwitch = ({ unit, expired }) => {

    const history = useHistory();
    const { t } = useTranslation();

    const serverTime = useRecoilValue(serverTimeState);
    const updateUnit = useSetRecoilState(updateUnitState);

    const [ switchState, setSwitchState ] = useState(expired ? false : unit.stay_online);
    const accessMode = getAccessMode({pwr_mode: unit.pwr_mode, online: unit.online});

    /**
     * Stay online switch
     * @param {*} event 
     */
     const handleSwitchChange = (event) => {
        
        let stayOnline = !switchState

        const onSuccess = (response) => {
            updateUnit({
                serial_number: unit.serial_number,
                data: {stay_online: stayOnline}
            })
            setSwitchState(stayOnline);
        }
        const onError = (error) => {
            console.log('error in /unit/<unit-id>');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/unit/" + unit.id, {stay_online: stayOnline.toString()}, onSuccess, onError);
    }

    /**
     * Switch state according to websocket change
     */
    useEffect(() => {
        setSwitchState(unit.stay_online); 
    }, [unit.stay_online])

    return (
        <Grid item xs={2}>
            {unit.pwr_mode === "powersave" ?
                <>
                    <ListItemText
                        sx={styles.listItemText} 
                        primary={
                            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Typography sx={styles.typography} style={{fontSize: 16}} color="secondary">
                                    <strong>{t('dashboard.unit.label.stayOnline')}</strong>:
                                </Typography>
                                <Switch
                                    sx={styles.switch}
                                    checked={switchState}
                                    onChange={handleSwitchChange}
                                    name="stayOnline"
                                    color="primary"
                                    disabled={expired}
                                />
                            </div>
                        }           
                    />
                    <ListItemText
                        sx={styles.listItemText} 
                        primary={
                            <div>
                                {(switchState && (accessMode === "wakeUpAt" || accessMode === "waitingForWakeUp")) ? 
                                    <Typography variant="caption" sx={styles.typographyCaption}>
                                        {t(`dashboard.unit.label.${accessMode}`)}
                                        &nbsp;
                                        {calculateNextWakeupTime(
                                            {timezone: unit.timezone, version_hw: unit.version_hw, wakeup_times: unit.wakeup_times}, 
                                            serverTime
                                        )}
                                    </Typography>
                                    : null
                                }
                            </div> 
                        }           
                    />
                </>
                : null
            }
        </Grid>
    )
}

export default React.memo(UnitRowSwitch);
