import React, { useState, useContext } from 'react';
import {useTranslation} from "react-i18next";
import { useHistory } from 'react-router';
/* Material UI */
import Paper from '@mui/material/Paper';
/* Components */
import ACLWrapper from '../Containers/ACLWrapper';
import UserForm from '../components/Customers/Settings/UserForm';
import UserUnits from '../components/Customers/Settings/UserUnits';
import Buttons from '../components/Customers/Settings/Buttons';
import FormTitle from '../components/Forms/FormTitle';
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    }
};

const CustomerCreate = () => {
 
    const history = useHistory();
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);
    const [ newUserState, setNewUserState ] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        phone: "",
        vat_identificator: "",
        vat_state: "NOT SET",
        type: "customer",
        resseler_id: null,
        password: "",
        units: [],
        units_id: []
    })
    const [ error, setError ] = useState("");

    /**
     * Handle input change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setNewUserState({...newUserState, [event.target.name]: event.target.value});
    }

    /**
     * Add unit to user units
     * @param {*} newUnit 
     */
    const addUnitToState = (newUnit) => {
        let newUserUnits = [...newUserState.units];
        let newUserUnitsIds = [...newUserState.units_id];
        newUserUnits.push({"id": newUnit.id, "name": newUnit.name, "serial": newUnit.serial_number});
        newUserUnitsIds.push(newUnit.id);
        // setSnack({ message: t('snackbar.message.unitAdded'), type: "info", open: true});
        setNewUserState({...newUserState, units: newUserUnits, units_id: newUserUnitsIds});
    }

    /**
     * Delete unit from user units
     * @param {*} unitId 
     */
    const deleteUnitFromState = (unitId) => {
        let newUserUnits = [...newUserState.units];
        let newUserUnitsIds = [...newUserState.units_id];
        let removeUnitsIndex = newUserState.units.findIndex(unit => unit.id === unitId);
        let removeUnitsIdIndex = newUserState.units_id.findIndex(unit => unit === unitId);  
        newUserUnits.splice(removeUnitsIndex, 1);
        newUserUnitsIds.splice(removeUnitsIdIndex, 1);
        // setSnack({ message: t('snackbar.message.unitRemoved'), type: "info", open: true});
        setNewUserState({...newUserState, units: newUserUnits, units_id: newUserUnitsIds});
    }

    /**
     * Validation of first name, username, email and phone format
     * @returns 
     */
    const validate = () => {
        if (newUserState.first_name === "") {
            setError("missingFirstName")
            return false;
        }
        else if (newUserState.username === ""){
            setError("missingUsername")
            return false;
        }
        else if (newUserState.email !== null && newUserState.email !== "") {
            if (!/\S+@\S+\.\S+/.test(newUserState.email)) {
                setError("invalidEmailAddress");
                return false;
            }
        }
        else if (newUserState.phone !== null && newUserState.phone !== "") {
            if((!/^\+?[\d]{3}[1-9][0-9]{8}$/.test(newUserState.phone.replace(/\s+/g, '')))
                && (!/^[0-9]{9}$/.test(newUserState.phone.replace(/\s+/g, '')))) {
                setError("invalidPhoneNumber");
                return false;
            }
        }
        
        setError("");
        return true;
    }

    /**
     * Handle submit and create new customer
     * @param {*} event 
     * @returns 
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        /* Validate */
        if (!validate()) return; 
    
        const onSuccess = (response) => {
            setSnack({ message: t('snackbar.message.newCustomerAdded'), type: "info", open: true});
            window.history.back();
        }
        const onError = (error) => {
            if (error.status === 406)
                setSnack({ message: t('snackbar.message.loginAlreadyExists'), type: "error", open: true});
            console.log('error in /browser', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/user", newUserState, onSuccess, onError);
    }

    return (
        <ACLWrapper requiredPermission={'ACL_RESELLER_ADMIN'}>
            <form noValidate autoComplete="off">
                <Paper sx={styles.paper} elevation={3}>
                    
                    {/* Title */}
                    <FormTitle icon="account_circle" title={t("customers.settings.title.newCustomer")} />
                    
                    {/* User form */}
                    <UserForm 
                        newCustomer 
                        customer={newUserState} 
                        error={error} 
                        handleChange={handleChange} 
                    />

                    <br /><br />

                    {/* User units */}
                    <UserUnits 
                        newCustomer
                        customer={newUserState} 
                        addUnit={addUnitToState} 
                        deleteUnit={deleteUnitFromState}
                    />

                    <br /><br />

                    {/* Buttons */}
                    <Buttons customer={null} error={error} handleSubmit={handleSubmit} />

                </Paper>
            </form>
        </ACLWrapper>
    );

}

export default CustomerCreate;