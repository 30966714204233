export const styles = {
    paper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    inputBase: {
        marginLeft: '8px',
        flex: 1,
        transition: 'width .3s',
        width: '180px',
        '&.Mui-focused': {
            width: '220px',
        },
        '@media (max-width: 480px)': {
            width: '72px',
            '&.Mui-focused': {
                width: '84px',
            },
        }
    },
};