import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useHistory } from 'react-router';
/* Material UI */
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
/* Components */
import FormTitle from '../components/Forms/FormTitle';
import CustomizationForm from '../components/Customization/CustomizationForm';
import CustomButton from '../components/lib/CustomButton/CustomButton';
/* Global state */
import { UserContext } from '../Context/UserContext';
import { SnackbarContext } from '../Context/SnackbarContext';
import { UPDATE_USER } from '../Context/UserContext';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';
import { getTokenFromLocalStorage } from '../Utils/localStorage';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
        maxWidth: '100%'
    },
    bottom: {
        paddingBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-end'
        }
    },
    typography: {
        fontWeight: 600,
        '@media (max-width: 767px)': {
            padding: '16px 0'
        }
    }
};

const Customization = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const { user, setUser } = useContext(UserContext);
    const { setSnack } = useContext(SnackbarContext);
    const [ error, setError ] = useState("");

    const parsedConfig = (user && user.config) ? user.config : "";
    //console.log("parsed", parsedConfig);

    const [state, setState] = useState({
        title: parsedConfig.title ? parsedConfig.title : "",
        colorPrimary: parsedConfig.colorPrimary ? parsedConfig.colorPrimary : "#83ce22",
        language: parsedConfig.language ? parsedConfig.language : "en",
        hide_all: parsedConfig.hide_all ? parsedConfig.hide_all : false,
        logo: parsedConfig.logo ? parsedConfig.logo : "",
    });

    //console.log("[Customization] initState", state);

    /**
     * Logo upload
     * @returns 
     */
     const uploadLogo = async () => {      
        const formData = new FormData();
        formData.append('file', state.logo);
        let filename = uuidv4() + state.logo.name.slice(Math.max(0, state.logo.name.lastIndexOf(".")));

        /* Not used BackendRequest because of different Content-Type */
        await axios.post("https://api.bixion.com/is/files/upload?name=" + filename +"&type=other", formData, {headers: { //URL
            'Content-Type': 'image/png', 
            'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
        }})
        .then(function (response) {
            console.log("Logo uploaded.", response.data.file);
        })
        .catch(function (error) {
            console.log('error in /files/upload?...')
            return null;
        });

        return filename;
    }

    /**
     * Validation of color format
     * @returns 
     */
     const validate = () => {
        if((!/^(#[0-9a-f]{3,6}|rgb\(\d{1,3}?(,\s?\d{1,3}?){2}\))$/.test(state.colorPrimary))) {
            setError("wrongColorFormat");
            return false;
        }
        else {
            setError("");
            return true;
        }
    }

    /**
     * Save configuration
     * @param {*} filename 
     */
     const saveConfig = (filename) => {

        const data = {
            ...state,
            logo: filename !== null ? filename : state.logo,
        }

        //console.log("state", state);
        //console.log("data", data);

        var configString = JSON.stringify(data);

        console.log("configString", configString);
    
        const onSuccess = (response) => {
            //console.log("resp", response.data);
            setUser({
                type: UPDATE_USER,
                user: { config: data }
            })
            setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            window.location.replace("/");
        }
        const onError = (error) => {
            console.log('error in /user/<user-id>');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/user/" + user.id, { config: configString }, onSuccess, onError);
    }

    /**
     * Submit configuration
     * @param {*} event 
     * @returns 
     */
    const submit = async (event) => {
        
        event.preventDefault();

        /* Color format control */
        if (!validate()) return;
        
        let filename = null;
        if (state.logo !== parsedConfig.logo && state.logo !== "") {
            filename = await uploadLogo();
        }

        //console.log("filename", filename)

       saveConfig(filename);
    }

    return (
        <form noValidate autoComplete="off">
            <Paper sx={styles.paper} elevation={3}>
                
                <FormTitle icon="label_important" title={t("customization.title.customization")} />

                <CustomizationForm state={state} setState={setState} />

                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} sx={styles.bottom}>
                        
                        <Typography sx={styles.typography} variant="subtitle1" color="error">
                            {error !== "" && t('error.' + error)}
                        </Typography>
                        
                        <div>
                            <CustomButton color="secondary" onClick={() => window.history.back()} style={{marginRight: '8px'}}>
                                {t('profile.button.back')}
                            </CustomButton>
                            <CustomButton color="primary" onClick={e => {e.preventDefault(); submit(e)}} type="submit">
                                {t('profile.button.saveChanges')}
                            </CustomButton>
                        </div>
                    </Grid>
                </Grid>

            </Paper>
        </form>

    );

}

export default Customization;