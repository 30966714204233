import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
/* Material UI */
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
/* Components */
import BrowserForm from '../components/Browsers/Settings/BrowserForm';
import BrowserUnits from '../components/Browsers/Settings/BrowserUnits';
import BrowserButtons from '../components/Browsers/Settings/BrowserButtons';
import Spinner from "../components//lib/CustomSpinner/CustomSpinner";
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';
import { Form, useBrowserForm } from '../Utils/useBrowserForm';

/* Styles */
const styles = {
    divider: {
        marginTop: '8px',
        marginBottom: '24px',
        height: '2px'
    },
    iconWrapper: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '4px',
        marginRight: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'primary.main',
        borderRadius: '8px',
    },
    icon: {
        fontSize: '2.5rem',
        color: 'rgba(0,0,0,.75)'
    },
    title: {
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '@media (max-width: 480px)': {
            fontSize: '1.25rem'
        }
    },
    subtitle: {
        wordBreak: 'break-word',
        '@media (max-width: 480px)': {
            fontSize: '.75rem'
        }
    },
};

const BrowserSettings = () => {

    const history = useHistory();  
    const location = useLocation();
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);
    const [browserLogo, setBrowserLogo] = useState(null);
    const [originalBrowser, setOriginalBrowser] = useState(null); // Used for comparison - added/deleted browser units or logo change
    let browserId = location.pathname.split('/')[3];
    const updatedUnitsArrayForServer = [];

    const {
        browserState,
        setBrowserState,
        error,
        setError,
        handleChange,
        handleColorChange,
        handleLogoChange,
        handleLogoDelete,
        addUnitToState,
        deleteUnitFromState,
        editUnitInState,
        validate,
        deleteBrowserUnit,
        updateBrowserUnit,
        uploadLogo
    } = useBrowserForm(null, false); // false indicates that it is not new browser

    /* Fetch browser from API */
    useEffect(() => {
        const onSuccess = (response) => {

            setOriginalBrowser({
                ...response.data, 
                config: JSON.parse(response.data.config), 
            })

            setBrowserState({
                ...response.data,
                config: JSON.parse(response.data.config),
                password: null,
                password_confirmation: null,
                ...response.data.password !== null ? { password_remove: false } : {},
                new_units: [],
            }); 
            
            if (response.data.logo !== null) {
                setBrowserLogo("https://api.bixion.com/is/files/" + response.data.logo + "?" + new Date().getTime()); //URL
            }
        }

        const onError = (error) => {
            console.log('error in /browser/<browser-id>', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/browser/" + browserId, null, onSuccess, onError);
    }, [])

    useEffect(() => {
        console.log("browserState", browserState)
    }, [browserState])

    /**
     * Add browser unit to the server and to the global state
     * @param {*} new_unit 
     */
    const addBrowserUnit = (new_unit) => {
        
        let data = {
            unit_id: new_unit.unit_id,
            browser_id: browserState.id,
            timeshift: new_unit.timeshift,
            name: new_unit.browser_unit_name,
        }
        
        const onSuccess = (response) => {
            let dispatchData = {
                "id_browser_unit": parseInt(response.data.id),
                "unit_id": parseInt(new_unit.unit_id), 
                "unit_name": new_unit.unit_name, 
                "unit_serial": new_unit.unit_serial,
                "browser_unit_name": new_unit.browser_unit_name,
                "timeshift": 0,
            }

            updatedUnitsArrayForServer.push(dispatchData);
        }
        const onError = (error) => {
            console.log('error in /browser/unit', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/browser/unit", data, onSuccess, onError);
    }

    /**
     * CHeck changes in browser units
     * @param {*} _callback 
     */
    const checkUnits = (_callback) => {      
        
        /* Deleting browser units from server */
        originalBrowser.units.map(unit => {
            // Comparing original browser units with state browser units. If original browser unit is not in listed in state browser units array, it should be deleted.
            if (!browserState.units.includes(unit)) {
                deleteBrowserUnit(unit.id_browser_unit);
            }
            else {
                updatedUnitsArrayForServer.push(unit);
                updateBrowserUnit(unit);
            }  
        })
        
        // Post/add new browser units to the server and then to the global state
        browserState.new_units.map(new_unit => {
            addBrowserUnit(new_unit);
        })

        _callback(); // callback call -> updateBrowser()
    }

    /**
     * Update browser
     * @param {*} filename 
     */
    const updateBrowser = (filename) => {

        const passwordRemove = browserState.password_remove;

        delete browserState.password_remove;
        delete browserState.password_confirmation;
        if (browserState.password === null || browserState.password === '') {
            delete browserState.password;
        }

        const data = {
            ...browserState,
            logo: filename !== null ? filename : browserState.logo,
            config: JSON.stringify(browserState.config),
            units: updatedUnitsArrayForServer,
            ...passwordRemove ? { password: null } : {},
        }
    
        const onSuccess = (response) => {
            setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            window.history.back();
        }
        const onError = (error) => {
            if (error.status === 406)
                setSnack({ message: t('snackbar.message.urlAlreadyExists'), type: "error", open: true});
            console.log('error in /browser', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/browser/" + browserState.id, data, onSuccess, onError);
    }

     /**
     * Handle submit and update browser
     * @param {*} event 
     * @returns 
     */
      const handleSubmit = async (event) => {
        event.preventDefault();

        /* Validation */
        if (!validate()) return; 

        let filename = null;
        if (browserState.logo !== originalBrowser.logo && browserState.logo !== null) {
            filename = await uploadLogo();
        }

        // Firstly, check if there are some changes in units. If yes, tell it to the server.
        checkUnits(() => {
            // Then, update whole browser with new logo and alternatively with new/deleted units.
            updateBrowser(filename)
        })
    }

    if (browserState !== null) {

        //console.log("...");

        return (
            <Form onSubmit={handleSubmit}>
                    
                {/* Title */}
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{height: '2.5rem', width: '3.5rem', marginRight: '8px', display: 'flex', alignItems: 'center'}}>
                    {browserLogo !== null 
                        ? <img style={{maxHeight: '100%', maxWidth: '100%'}} src={browserLogo} alt="logo" /> 
                        : <Box sx={styles.iconWrapper}><Icon sx={styles.icon}>insert_photo</Icon></Box>
                    }
                    </div>
                    <Container style={{padding: 0, margin: 0, width: 'calc(100% - 4rem)'}}>
                        <Typography sx={styles.title} variant="h5" color="secondary">
                            {browserState.title.toUpperCase()}
                        </Typography>
                        <Typography sx={styles.subtitle} variant="subtitle2" color="secondary">
                            https://time.bixion.com/{browserState.url_path}
                        </Typography>
                    </Container>
                </div>
                
                {/* Divider */}
                <Divider sx={styles.divider} />

                {/* Browser form */}
                <BrowserForm 
                    browser={browserState} 
                    error={error} 
                    handleChange={handleChange} 
                    handleColorChange={handleColorChange}
                    handleLogoChange={handleLogoChange}
                    handleLogoDelete={handleLogoDelete}
                />

                <br /><br />

                {/* Browsers units */}
                <BrowserUnits 
                    browser={browserState} 
                    addUnit={addUnitToState} 
                    deleteUnit={deleteUnitFromState}
                    editUnit={editUnitInState}
                />

                <br /><br />

                {/* Buttons */}
                <BrowserButtons
                    error={error} 
                    handleSubmit={handleSubmit} 
                />
                    
            </Form>
        );
    }
    else {
        return <Spinner />;
    }
}

export default BrowserSettings;