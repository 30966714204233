import React, { useState, useContext, useEffect } from 'react';
/* Material UI */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
/* Components */
import UnitsTableRow from './UnitsTableRow';
import UnitsTableHead from './UnitsTableHead';
import UnitsTableFirstRow from './UnitsTableFirstRow';
/* Global state */
import { OrderContext } from '../../../Context/OrderContext';

/* Styles */
const styles = {
    table: {
        minWidth: 700,
        '& .MuiTableCell-root': {
            padding: '4px'
        }
    }
};

const UnitsTable = ({ units }) => {
  
    const [ selectedAll, setSelectedAll ] = useState(false);
    const [ selectedList, setSelectedList ] = useState([])
    const [ noLimitAll, setNoLimitAll ] = useState(false);
    const [ counter, setCounter ] = useState(0); 

    const currentOrderState = useContext(OrderContext);

    //console.log("[UnitsTable]");

    /**
     * INCREMENT COUNTER(S) FOR SELECTED UNITS
     */
    const handleIncrement = () => {
        if (counter < 99) {
            setCounter(counter + 1);
        }  
    }

    /**
     * DECREMENT COUNTER(S) FOR SELECTED UNITS
     */
    const handleDecrement = () => {
        if (counter > 0) {
            setCounter(counter - 1);
        } 
    }

    /**
     * HANDLE INPUT VALUE FOR COUNTER(S) OF SELECTED UNITS
     */
    const handleInputChange = (event) => {
        if (parseInt(event.target.value) || event.target.value === "0" || event.target.value === ""){
            let inputValue = !(event.target.value === "0" || event.target.value === "") ? parseInt(event.target.value) : 0;
            if (inputValue > 99)
                inputValue = 99;
            setCounter(inputValue);
        }
        else {
            return;
        }
    }

    /**
     * FOR CHECKBOX, SELECT ALL UNITS AND THEN SET THEM
     */
    const selectAll = () => {
        setSelectedList(units);
        setSelectedAll(true);
    }

    /**
     * ADD UNIT TO THE LIST AND THEN SET VALUES
     * @param {*} unit 
     */
    const addToList = (unit) => {
        console.log(unit);
        const newList = [...selectedList];
        newList.push(unit);
        //console.log("selectedList", newList);
        setSelectedList(newList);
    }

    /**
     * REMOVE UNIT FROM THE LIST OF SET UNITS
     * @param {*} unitId 
     */
    const removeFromList = (unitId) => {
        const newList = [...selectedList];
        let removeUnitIndex = newList.findIndex(unit => unit.unit_id === unitId);
        newList.splice(removeUnitIndex, 1);
        setSelectedList(newList);
    }

    /**
     * CHECK FOR DISMISS ORDER
     */
    useEffect(() => {
        if (currentOrderState.units.price === 0){
            setCounter(0);
            setNoLimitAll(false);
        }
    }, [currentOrderState.units]);

    /**
     * IF ALL UNITS ARE REMOVED FROM THE LIST
     */
    useEffect(() => {
        if (selectedList.length === 0) {
            setNoLimitAll(false);
            setCounter(0);
        }
    }, [selectedList]);

    return (
        <TableContainer component={Paper}>
            <Table sx={styles.table} aria-label="units table">
                
                {/* TABLE HEAD */}
                <UnitsTableHead />

                {/* TABLE BODY */}
                <TableBody>

                    {/* FIRST ROW */}
                    <UnitsTableFirstRow
                        selectedAll={selectedAll}
                        setSelectedAll={setSelectedAll}
                        selectedList={selectedList}
                        setSelectedList={setSelectedList}
                        counter={counter}
                        setCounter={setCounter}
                        noLimitAll={noLimitAll}
                        setNoLimitAll={setNoLimitAll}
                        selectAll={selectAll}
                        handleDecrement={handleDecrement}
                        handleIncrement={handleIncrement}
                        handleInputChange={handleInputChange}
                    />

                    {/* UNITS ROWS */}
                    {units.map(unit => {

                        return <UnitsTableRow 
                            key={unit.unit_id}
                            unit={unit} 
                            value={counter} 
                            selectedList={selectedList} 
                            addToList={addToList} 
                            removeFromList={removeFromList}
                        />
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default React.memo(UnitsTable);