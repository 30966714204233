import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
/* Material UI */
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';

/* Styles */
const styles = {
    iconButton: {
        padding: '4px'
    },
};

const WakeupItem = ({ wakeup, index, removeWakeup, handleUploadChange, handleAtChange }) => {

    const { t } = useTranslation();

    const zfill = (num, len) => {return (Array(len).join("0") + parseInt(num)).slice(-len)}
    const [value, setValue] = useState(moment(`2022-01-01T${zfill(wakeup.time / 3600, 2) + ":" + zfill((wakeup.time % 3600) / 60, 2)}:00.000Z`));

    //console.log("wakeup", `2018-01-01T${zfill(wakeup.time / 3600, 2) + ":" + zfill((wakeup.time % 3600) / 60, 2)}:00.000Z`);

    useEffect(() => {
        const time = value.toISOString().substring(11, 16);
        handleAtChange(time, index);
    }, [value])

    useEffect(() => {
        setValue(moment(`2022-01-01T${zfill(wakeup.time / 3600, 2) + ":" + zfill((wakeup.time % 3600) / 60, 2)}:00.000Z`))
    }, [wakeup])


    return (
        <TableRow>
            <TableCell padding="checkbox" style={{paddingLeft: '16px'}}>
                <Checkbox
                    checked={wakeup.upload}
                    onChange={() => handleUploadChange(index)}
                    color="primary"
                />
            </TableCell>
            <TableCell size="medium" style={{padding: '14px 8px'}}>
                <MobileTimePicker
                    ampm={false}
                    value={value}
                    onChange={setValue}
                    renderInput={(params) => <TextField {...params} />}
                    DialogProps={{
                        PaperProps: {
                          sx: { "& *:focus": { outline: "none" } }
                        }
                    }}
                />
            </TableCell>
            <TableCell size="small" align="right">
                <Tooltip title={t('customers.overview.tooltip.delete')}>
                    <IconButton sx={styles.iconButton} aria-label="delete" onClick={() => removeWakeup(index)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>                     
    );
}

export default WakeupItem;