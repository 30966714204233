/**
 * @param {*} timestamp 
 * @returns 
 */
const calculateUptime = (timestamp) => {
    var ret = "";

    if (timestamp !== "") {
    var hours = (Math.floor(timestamp / 3600));
    if (hours < 10)
        hours = ("0" + hours).slice(-2);

    var minutes = ("0" + Math.floor((timestamp - (hours * 3600)) / 60)).substr(-2);

    ret = isNaN(hours) ? "" : hours + ":" + minutes;
    }

    return ret;
}

/**
 * @param {*} unit 
 * @param {*} item 
 * @returns 
 */
export const getUnitInfo = (unit, item) => {

    switch(item) {
        
        case "files_from_cam":
            return {
                title: 'dashboard.unit.tooltip.shotPictures',
                icon: "photo_camera",
                value: unit.files_from_cam ? unit.files_from_cam : "-"
            }
            
        case "files_uploaded":
            return {
                title: 'dashboard.unit.tooltip.uploadedPictures',
                icon: "backup",
                value: unit.files_uploaded ? unit.files_uploaded : "-"
            }

        case "fails_shot": 
            return {
                title: 'dashboard.unit.tooltip.failShotFailUploaded',
                icon: "warning",
                value: (unit.fails_shot ? unit.fails_shot : "-") + "/" + (unit.fails_upload ? unit.fails_upload : "-")
            }

        case "uptime":
            return {
                title: 'dashboard.unit.tooltip.unitUptime',
                icon: "watch_later",
                value: unit.uptime ? calculateUptime(unit.uptime) : "-"
            }

        case "temperature": 
            return {
                title: 'dashboard.unit.tooltip.unitTemperature',
                icon: "thermostat",
                value: unit.temperature ? unit.temperature + " °C" : "-"
            }

        case "voltage": 
            return {
                title: 'dashboard.unit.tooltip.unitVoltage',
                icon: "flash_on",
                value: unit.voltage ? unit.voltage + " V" : "-"
            }

        case "humidity": 
            return {
                title: 'dashboard.unit.tooltip.unitHumidity',
                icon: "water_drop",
                value: unit.humidity ? unit.humidity + " %" : "-",
                color: unit.humidity ? unit.humidity >= 70 ? "rgb(255,17,0)" : unit.humidity >= 40 ? "rgb(255,105,0)" : "" : ""
            }

        case "local_storage": 
            return {
                title: 'dashboard.unit.tooltip.localStorage',
                icon: "storage",
                value: unit.local_storage ? parseInt(unit.local_storage) + " %" : "-",
                color: unit.local_storage ? parseInt(unit.local_storage) === 100 ? "rgb(255,17,0)" : parseInt(unit.local_storage) >= 90 ? "rgb(255,105,0)" : "" : ""
            }

        case "rssi": 

            let value = "-";
            if (unit.rssi_lte) {
                if (unit.rssi_lte >= -70)
                    value = "Excellent";
                else if (unit.rssi_lte >= -85)
                    value = "Good";
                else if (unit.rssi_lte >= -100)
                    value = "Fair";
                else if (unit.rssi_lte >= -110)
                    value = "Poor";
                else
                    value = "Unknown";
            }
            else if (unit.rssi_wifi) {
                if (unit.rssi_wifi >= -55)
                    value = "Excellent";
                else if (unit.rssi_wifi >= -65)
                    value = "Good";
                else if (unit.rssi_wifi >= -75)
                    value = "Fair";
                else if (unit.rssi_wifi >= -110)
                    value = "Poor";
                else
                    value = "Unknown";
            }

            return {
                title: 'dashboard.unit.tooltip.rssi',
                icon: "rss_feed",
                value: value,
                color: value === "Poor" ? "rgb(255,105,0)" : ""
            }
            
        default:
            return {
                title: '',
                icon: "",
                value: "-"
            }
    }
}

/**
 * @param {*} unit 
 * @returns 
 */
export const showAllUnitInfo = (unit) => {

    const allUnitItems = ["files_from_cam", "files_uploaded", "fails_shot", "uptime", "temperature", "voltage", "humidity", "local_storage", "rssi_lte", "rssi_wifi"];

    const unitInfo = {};

    for (let i = 0; i < allUnitItems.length; i++) {
        const { title, icon, value } = getUnitInfo(unit, allUnitItems[i]);
        unitInfo[title] = value;
    }

    return unitInfo;
}