import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
/* Components */
import CustomDialog from '../../lib/CustomDialog/CustomDialog';
/* Utils */
import { getTokenFromLocalStorage } from '../../../Utils/localStorage';


const ConnectionDialog = ({
    handleClose,
    open,
    unit
}) => {
  
    const { t } = useTranslation();
    const history = useHistory();

    /**
     * Handle connect in normal and powersave mode
     */
    const handleConnect = () => {

        ConnectionDialog.openWindow = null;
                                           
        const url = `https://remote.bixion.com/?goto=${unit.serial_number}&token=${getTokenFromLocalStorage()}`;

        if (ConnectionDialog.openWindow !== null) {
            ConnectionDialog.openWindow.close();
        }

        let owindow = window.open("", 'Unit', null, false);

        if (owindow != null)
        {
            let html = "";
            html += "<html><head></head><body><form id='formid' method='post' action='" + url +"'>";
            html += "<input type='hidden' name='s' value='" + unit.secure_id + "'/>";
            html += "<input type='hidden' name='vg' value='" + unit.vpn_ip + "'/>";
            html += "</form><script type='text/javascript'>document.getElementById(\"formid\").submit()</script></body></html>";
            owindow.document.write(html);
        }

        ConnectionDialog.openWindow = owindow;
    }

    return (
        <CustomDialog
            buttons={[
                {
                    action: handleConnect,
                    label: t('dashboard.unit.dialog.button.connectToTheUnit')
                },
                {
                    action: () => history.push('/remote-connection/' + unit.id),
                    label: t('dashboard.unit.dialog.button.changeParamsOffline')
                }
            ]}
            content={t('dashboard.unit.dialog.label.unitIsCurrentlyInPowersaveMode')}
            handleClose={handleClose}
            open={open}
            title={t(
                'dashboard.unit.dialog.title.connectToTheUnit',
                { unit_name: unit.name.toUpperCase() },
            )}
        />
    );
}

export default ConnectionDialog;