import React, { createContext, useReducer, useMemo } from "react";

export const UserContext = createContext();
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";

const userReducer = (state, action) => {

    console.log("userReducer", action);

    switch (action.type) {
        
        case FETCH_USER:
            return {
                ...action.user
            }
        
        /* User state can be updated in My Account -> there is no need for more than one initial request */
        case UPDATE_USER:
            return {
                ...state, 
                ...action.user
            }
        
        default:
            return state;
    }
}

const UserProvider = ({ children }) => {

    const [user, setUser] = useReducer(userReducer, null);
    const userProviderValue = useMemo(() => ({user, setUser}), [user, setUser])

    //console.log("[UserProvider]");

    return <UserContext.Provider value={userProviderValue}>
        {children}
    </UserContext.Provider>;
}

export default UserProvider;