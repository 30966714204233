import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
/* Global state */
import { OrderContext } from '../Context/OrderContext';
import { serverTimeState, updateBriefBrowserState } from '../Context/globalState';
/* Components */
import ServicesSummary from '../components/Services/Summary/ServicesSummary';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';

const ServicesBrowsersSummary = () => {

    const history = useHistory();
    const currentOrderState = useContext(OrderContext);
    const serverTime = useRecoilValue(serverTimeState);
    const updateBriefBrowser = useSetRecoilState(updateBriefBrowserState);

    const dateToDMY = (date) => {
        let d = date.getDate();
        let m = date.getMonth() + 1; // Month from 0 to 11
        let y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    /**
     * Process the order when payment succeeds
     * @param {*} payment 
     */
    const processOrder = payment => {

        /* Update all set browsers */
        currentOrderState.browsers.order.map(orderItem => {
            const data = {
                date_to: new Date(orderItem.date_to) < new Date(dateToDMY(new Date(serverTime*1000)))
                ?
                dateToDMY(new Date(new Date(new Date(serverTime*1000)).setDate(new Date(serverTime*1000).getDate() + (orderItem.services[0].months*31))))
                :
                dateToDMY(new Date((new Date(orderItem.date_to)).setDate((new Date(orderItem.date_to).getDate()) + (orderItem.services[0].months*31))))
            }

            const onSuccess = (response) => {
                /* Update brief browsers */
                updateBriefBrowser({ 
                    browser_id: orderItem.browser_id, 
                    days: orderItem.services[0].months*31 
                })
            }
            
            const onError = (error) => {
                console.log('error in /browser/' + orderItem.browser_id, error);
                if (error.status === 401 || error.status === 403) {
                    localStorage.removeItem("token");
                    history.push({ pathname: '/login', state: { showSnack: true }});
                }
            }
            
            BackendRequest("post", "/browser/" + orderItem.browser_id, data, onSuccess, onError);
        })
    }

    if (currentOrderState.browsers.order.length !== 0){
        return <ServicesSummary category="browsers" processOrder={processOrder} />
    }
    else {
        return <Redirect to="/services/browsers" />
    }

}

export default ServicesBrowsersSummary;