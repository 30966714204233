import React, { createContext, useState } from 'react';
/* Material UI */
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const SnackbarContext = createContext();

/* Styles */
const styles = {
    info: {
        '& .MuiSnackbarContent-message': {
            color: 'primary.main'
        }
    },
    warning: {
        '& .MuiSnackbarContent-message': {
            color: 'yellow'
        }
    },
    error: {
        '& .MuiSnackbarContent-message': {
            color: 'red',
            fontWeight: 600,
        }
    }
};

const SnackbarProvider = ({ children }) => {

    const [snack, setSnack] = useState({
        message: '',
        type: '',
        open: false,
    });

    const handleClose = () => {
        setSnack({ message: '', open: false})
    };

    return <SnackbarContext.Provider value={{setSnack}}>
        <Snackbar
            sx={snack.type === "info" ? styles.info : (snack.type === "warning" ? styles.warning : styles.error)}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={snack.open}
            autoHideDuration={4000}
            onClose={handleClose}
            message={snack.message}
            action={
                <>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </>
            }
        />
        {children}
    </SnackbarContext.Provider>
};

export default SnackbarProvider;