import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* Material UI */
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
/* Components */
import FormTitle from '../components/Forms/FormTitle';
import RemoteConnectionButtons from '../components/RemoteConnection/Buttons';
import CustomButton from '../components/lib/CustomButton/CustomButton';
import IntervalItem from '../components/RemoteConnection/IntervalItem/IntervalItem';
import WakeupItem from '../components/RemoteConnection/WakeupItem';
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
        width: '100%',
    },
    list: {
        width: '100%',
        overflowX: 'auto'
    },
    tableHead: {
        position: 'sticky', 
        top: '64px', 
        zIndex: 1200,
    }
};


const RemoteConnection = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const { setSnack } = useContext(SnackbarContext);

    const location = useLocation();
    const unitId = location.pathname.split('/')[2];

    const [unit, setUnit] = useState(null);
    const [intervals, setIntervals] = useState("");
    const [wakeups, setWakeups] = useState("");
    const [error, setError] = useState("");

    const submitInterval = (intervalData, index) => {
        const intervalsCopy = [...intervals];
        intervalsCopy[index] = {...intervalData}
        setIntervals(intervalsCopy);
    }

    const handleSubmit = () => {
        
        /* Validation */
        if (intervals.length === 0) {
            setError("atLeastOneInterval");
            return;
        }
        else {
            setError("");
        }

        const newIntervals = [...intervals];
        for (let i = 0; i < newIntervals.length; i++) {

            if (typeof newIntervals[i].ifrom === "string")
                newIntervals[i].ifrom = parseInt(newIntervals[i].ifrom.slice(0,2)) * 3600 + parseInt(newIntervals[i].ifrom.slice(3,5)) / 60 * 3600;
            if (typeof newIntervals[i].ito === "string")
                newIntervals[i].ito = parseInt(newIntervals[i].ito.slice(0,2)) * 3600 + parseInt(newIntervals[i].ito.slice(3,5)) / 60 * 3600;
            newIntervals[i].period = newIntervals[i].timeUnit === "mins" ?  parseInt(newIntervals[i].period) * 60 : parseInt(newIntervals[i].period);
            delete newIntervals[i].timeUnit;
        }

        const data = {
            intervals: [...newIntervals],
            wakeups: [...wakeups]
        }

        const onSuccess = (response) => {
            //console.log(response.data);
            setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            history.push('/dashboard');
        }

        const onError = (error) => {
            console.log('error in /unit/<unit-id>', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/unit/" + unitId + "/powersave_config", data, onSuccess, onError);
    }

    const addInterval = () => {
        if (wakeups.length + intervals.length >= 64) {
            setSnack({ message: t('dashboard.unit.warning.maxNumberOfRecordsReached'), type: "error", open: true});
            return;
        }
        const intervalsCopy = [...intervals];
        const splittedVersionSW = unit.versionSW ? unit.versionSW.split(".") : "1.2.19".split("."); //in case unit.versionSW is null
        /* Version 3.0.92 and higher */
        if (parseInt(splittedVersionSW[0]) >= 3 && parseInt(splittedVersionSW[2]) >= 92) {
            intervalsCopy.push({ 
                ifrom: 21600, 
                ito: 64800, 
                period: 600, 
                wdays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
                suffix: "",
                upload: true
            })
        }
        else {
            intervalsCopy.push({ 
                ifrom: 21600, 
                ito: 64800, 
                period: 600, 
                wdays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
            })
        }
        setIntervals(intervalsCopy);
    }

    const removeInterval = (index) => {
        const intervalsCopy = [...intervals];
        intervalsCopy.splice(index, 1);
        setIntervals(intervalsCopy);
    }

    const addWakeup = () => {
        if (wakeups.length + intervals.length >= 64) {
            setSnack({ message: t('dashboard.unit.warning.maxNumberOfRecordsReached'), type: "error", open: true});
            return;
        }
        const wakeupsCopy = [...wakeups];
        wakeupsCopy.push({ time: 21600, upload: false })
        setWakeups(wakeupsCopy);
    }

    const removeWakeup = (index) => {
        const wakeupsCopy = [...wakeups];
        wakeupsCopy.splice(index, 1);
        setWakeups(wakeupsCopy);
    }

    const handleUploadChange = (index) => {
        const wakeupsCopy = [...wakeups];
        wakeupsCopy[index].upload = !wakeupsCopy[index].upload;
        setWakeups(wakeupsCopy);
    }

    const handleAtChange = (timeValue, index) => {
        const wakeupsCopy = [...wakeups];
        wakeupsCopy[index].time = parseInt(timeValue.slice(0,2)) * 3600 + parseInt(timeValue.slice(3,5)) / 60 * 3600;
        setWakeups(wakeupsCopy);
    }

    /**
     * Get unit information
     */
     const getUnitInfo = () => {
        const onSuccess = (response) => {
            setUnit(response.data);
        }

        const onError = (error) => {
            console.log('error in /unit/<unit-id>', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/unit/" + unitId, null, onSuccess, onError);
    }

    /**
     * Get unit configuration
     */
    const getUnitConfig = () => {
        const onSuccess = (response) => {
            setIntervals([...response.data.intervals]);
            setWakeups([...response.data.wakeups]);
        }

        const onError = (error) => {
            console.log('error in /unit/<unit-id>/powersave_config', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/unit/" + unitId + "/powersave_config", null, onSuccess, onError);
    }

    useEffect(() => {
        getUnitInfo();
        getUnitConfig();
    }, [])

    return (
        <>
            <form noValidate autoComplete="off">
                <Paper sx={styles.paper} elevation={3}>
                    
                    {/* Title */}
                    <FormTitle icon="tune" title={`Change params offline (${unit ? unit.name.toUpperCase() : ""})`} />

                    <Grid container>
                        <Grid item xs={12} xl={10} xxl={8}>
                            <div style={{display: 'flex', justifyContent: 'space-between', position: 'sticky', top: '64px', padding: '8px 0', backgroundColor: 'white', zIndex: 1200}}>
                                <Typography variant="h6"><strong>Timelapse intervals</strong></Typography>
                                <CustomButton color="primary" style={{textTransform: 'none'}} onClick={addInterval}>
                                    + Add
                                </CustomButton>
                            </div>
                            
                            <List sx={styles.list}>
                                {unit && intervals && intervals.map((interval, index) => 
                                    <IntervalItem unitSWVersion={unit.versionSW} interval={interval} index={index} removeInterval={removeInterval} submitInterval={submitInterval}/>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                    
                    <br /><br />

                    <Grid container>
                        <Grid item xs={12} xl={10} xxl={8}>
                            <Table style={{borderCollapse: 'separate'}}>
                                <TableHead sx={styles.tableHead}>
                                    
                                    <TableRow style={{backgroundColor: 'white'}}>
                                        <TableCell size="medium" style={{width: '40%', padding: '8px 0'}} padding="normal">
                                            <Typography variant="h6"><strong>Actualization times</strong></Typography>
                                        </TableCell>
                                        <TableCell size="medium" style={{width: '40%', padding: '8px 0'}} padding="normal"/>
                                        <TableCell size="medium" align="right" style={{width: '20%', padding: '8px 0'}} padding="normal">
                                            <CustomButton color="primary" style={{textTransform: 'none'}} onClick={addWakeup}>
                                                + Add
                                            </CustomButton>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow style={{backgroundColor: 'rgb(240,240,240)'}}>
                                        <TableCell size="medium" style={{width: '40%', padding: '8px'}} padding="normal">
                                            <strong>Upload pictures</strong>
                                        </TableCell>
                                        <TableCell size="medium" style={{width: '40%', padding: '8px'}} padding="normal">
                                            <strong>at</strong>
                                        </TableCell>
                                        <TableCell size="medium" align="right" style={{width: '20%', padding: '8px'}} padding="normal"/>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {wakeups && wakeups.map((wakeup, index) => 
                                        <WakeupItem 
                                            wakeup={wakeup} 
                                            index={index} 
                                            removeWakeup={removeWakeup} 
                                            handleUploadChange={handleUploadChange} 
                                            handleAtChange={handleAtChange}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    
                    <br /><br />

                    {/* Buttons */}
                    <RemoteConnectionButtons error={error} handleSubmit={handleSubmit} />

                </Paper>
            </form>
        </>
    );
}

export default RemoteConnection;