import React, { useState } from 'react';
import { withRouter} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/* Material UI */
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
/* Components */
import ACLWrapper from '../../../../Containers/ACLWrapper';
import DrawerListItem from '../DrawerListItem/DrawerListItem';


const DrawerCollapsibleListItem = ({ item }) => {

    const { t } = useTranslation();
    
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    
    return (
        <ACLWrapper requiredPermission={item.acl}>
            <ListItem 
                button 
                onClick={handleClick}
            >
                <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText>
                    {t(item.title)}
                </ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
            >
                <List disablePadding>
                    {item.children.map((child, index) => <DrawerListItem key={index} item={child} />)}
                </List>
            </Collapse>
        </ACLWrapper>
    );
}

export default withRouter(React.memo(DrawerCollapsibleListItem));