import React, {
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { NavLink, withRouter} from "react-router-dom";
/* Material UI */
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
/* Components */
import ACLWrapper from '../Containers/ACLWrapper';
import SearchBox from '../components//lib/CustomSearchField/CustomSearchField';
import Spinner from '../components//lib/CustomSpinner/CustomSpinner';
import CustomButton from '../components/lib/CustomButton/CustomButton';
import CustomDialog from '../components/lib/CustomDialog/CustomDialog';
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import useCustomersSearch from '../Utils/useCustomersSearch';
import BackendRequest from '../Utils/BackendRequest';

const FIRST_COLUMN = 320;
const SECOND_AND_THIRD_COLUMN = 270;
const FOURTH_COLUMN = 170;
const FIFTH_COLUMN = 120;
const TABLE_WIDTH = FIRST_COLUMN + 2*SECOND_AND_THIRD_COLUMN + FOURTH_COLUMN + FIFTH_COLUMN;

/* Styles */
const styles = {
    paper: {
        width: `${TABLE_WIDTH}px`,
        maxWidth: '100%',
    },
    topBar: {
        display: 'flex', 
        marginBottom: '16px',
        justifyContent: 'space-between',
    },
    table: {
        width: `${TABLE_WIDTH}px`,
    },
    tableHead: {
        backgroundColor: 'secondary.main',
        '& .MuiTableCell-head': {
            fontWeight: 600,
        }
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(133,133,133,.05)',
        },
    },
    column: {
        paddingRight: '20px',
        wordBreak: 'break-all'
    },
    firstColumn: {
        width: `${FIRST_COLUMN}px`
    },
    secondAndThirdColumn: {
        width: `${SECOND_AND_THIRD_COLUMN}px`
    },
    fourthColumn: {
        width: `${FOURTH_COLUMN}px`
    },
    fifthColumn: {
        width: `${FIFTH_COLUMN}px`
    },
    iconButton: {
        padding: '8px'
    },
};


const Customers = () => {
     
    const [searchQuery, setSearchQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [removeDialog, setRemoveDialog] = useState(null);

    const history = useHistory();
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);

    const { loading, error, customers, deleteCustomer, hasMore } = useCustomersSearch(searchQuery, pageNumber);

    /**
     * Handle search, on change of search query is called custom hook useCustomersSearch
     * @param {*} event 
     */
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
        setPageNumber(0);
    }

    /**
     * Delete user
     * @param {*} event 
     */
     const handleDelete = (customerId) => {

        const onSuccess = (response) => {
            setSnack({ message: t('snackbar.message.userDeleted'), type: "info", open: true});
            setRemoveDialog(null);
            deleteCustomer(customerId);
        }
        const onError = (error) => {
            console.log('error in delete /user/<user-id>')
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }

        BackendRequest("delete", "/user/" + customerId, null, onSuccess, onError);
    };

    /* Observer - When observer is reached, new request is created */
    /* https://github.com/WebDevSimplified/React-Infinite-Scrolling/blob/master/src/App.js */
    const observer = useRef();
    const lastCustomerRef = useCallback(node => {
        console.log(loading, pageNumber, node);
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        }, {rootMargin: "600px"})
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (
        <ACLWrapper requiredPermission={'ACL_RESELLER_ADMIN'}>
            {/* Top bar */}
            <Box sx={styles.topBar}>
                <CustomButton color="primary" style={{textTransform: 'none', marginRight: '8px'}} component={NavLink} to={'customers/add'}>
                    + {t('customers.overview.button.addCustomer')}
                </CustomButton>

                <SearchBox 
                    searchQuery={searchQuery} 
                    handleInputChange={handleInputChange} 
                />
            </Box>
            
            {/* Table */}
            {(customers.length === 0 && !loading) ? t('customers.overview.label.noCustomersWereFound') :
            <TableContainer
                component={Paper}
                sx={styles.paper}
            >
                <Table sx={styles.table} aria-label="customers table">
                    <TableHead sx={styles.tableHead}>
                        <TableRow>
                            <TableCell sx={[styles.column, styles.firstColumn]}>
                                {t('customers.overview.title.firstAndLastName')}
                            </TableCell>
                            <TableCell sx={[styles.column, styles.secondAndThirdColumn]}>
                                {t('customers.overview.title.username')}
                            </TableCell>
                            <TableCell sx={[styles.column, styles.secondAndThirdColumn]}>
                                Email
                            </TableCell>
                            <TableCell sx={[styles.column, styles.fourthColumn]}>
                                {t('customers.overview.title.phone')}
                            </TableCell>
                            <TableCell sx={[styles.fifthColumn]}/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {customers.length > 0 && customers.map((customer) => (
                            <TableRow sx={styles.row} key={customer.id}>
                                <TableCell sx={[styles.column, styles.firstColumn]}>
                                    {customer.first_name + " " + customer.last_name}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.secondAndThirdColumn]}>
                                    {customer.username}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.secondAndThirdColumn]}>
                                    {customer.email}
                                </TableCell>
                                <TableCell sx={[styles.column, styles.fourthColumn]}>
                                    {customer.phone}
                                </TableCell>
                                <TableCell 
                                    align="right"
                                    sx={[styles.fifthColumn, { padding: '8px' }]}
                                >
                                    <Tooltip title={t('customers.overview.tooltip.edit')}>
                                        <IconButton 
                                            component={NavLink}
                                            sx={styles.iconButton} 
                                            to={'customers/edit/' + customer.id}
                                        >
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('customers.overview.tooltip.delete')}>
                                        <IconButton
                                            onClick={() => setRemoveDialog(customer)}
                                            sx={styles.iconButton}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}

            {/* Reference of observer - Spinner */}
            <div ref={lastCustomerRef} style={{ width: `${TABLE_WIDTH}px` }}>
                {hasMore && <Spinner />}
            </div>

            {error && t('error.somethingWentWrong')}

            {/* Customer remove dialog */}
            {removeDialog != null ? <CustomDialog
                buttons={[
                    {
                        action: () => handleDelete(removeDialog.id),
                        label: t('button.remove')
                    },
                ]}
                content={t('customers.dialog.label.doYouReallyWantToRemoveThisCustomer')}
                handleClose={() => setRemoveDialog(null)}
                open={removeDialog != null}
                title={t(
                    'customers.dialog.title.removeCustomer',
                    { customer: removeDialog.first_name + " " + removeDialog.last_name }
                )}
            /> : null}
        </ACLWrapper>
    );
}

export default withRouter(Customers)