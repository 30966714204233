import moment from "moment";

/**
 * Get access mode
 * @param {*} unit 
 * @returns 
 */
export const getAccessMode = (unit) => {
    let mode = "undefined";

    if (unit.pwr_mode === 'powersave' && (unit.online != null && unit.online.online === false)) {
        mode = "wakeUpAt";
    }
    else if (unit.pwr_mode === 'powersave' && (unit.online != null && unit.online.online === true)) {
        mode = "online";
    }
    else if (unit.pwr_mode === 'powersave' && unit.online == null) {
        mode = "waitingForWakeUp";
    }

    if (unit.pwr_mode === 'normal' && (unit.online != null && unit.online.online === true)) {
        mode = "";
    }
    else if (unit.pwr_mode === 'normal' && (unit.online != null && unit.online.online === false)) {
        mode = "";
    }
    else if (unit.pwr_mode === 'normal' && unit.online == null) {
        mode = "";
    }
    return mode;
}

/**
 * Get time in timezone (only for new units > 3.0)
 * @param {*} unit 
 * @param {*} actTime_timestamp 
 * @returns 
 */
const getTime = (unit, actTime_timestamp) => {
    let ret = null;

    if (unit.version_hw >= "3.0") {
      ret = moment((actTime_timestamp * 1000)).tz(unit.timezone);
    }

    return ret
}

/**
 * Calculate next wakeup time (only for new units > 3.0)
 * @param {*} unit 
 * @param {*} actTime_timestamp 
 * @returns 
 */
export const calculateNextWakeupTime = (unit, actTime_timestamp) => {
    let ret = "";
    let dat = getTime(unit, actTime_timestamp)

    if (dat != null) {
        let actSec = parseInt(dat.format("HH")) * 60 * 60 + parseInt(dat.format("mm")) * 60;
        let wTimes = [...unit.wakeup_times];

        wTimes.sort(function (a, b) { return a - b });

        for (let i = 0; i < wTimes.length; i++) {
            let item = wTimes[i];

            if (item > actSec) {
                ret = moment.unix((dat.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000 + item));
                break;
            }
        }

        if (ret === "") {
            ret = wTimes[0]
            ret = moment.unix((dat.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000 + wTimes[0] + 86400));
        }

        ret = ret.tz(unit.timezone).format("DD-MM-YYYY HH:mm");
    }

    return ret
}

/**
 * Get time in timezone
 * @param {*} timestamp 
 * @param {*} timezone 
 * @returns 
 */
 export const getTimeInTimezone = (timestamp, timezone) => {
    const date = new Date(timestamp * 1000);
    date.setMinutes(date.getMinutes() + timezone);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).substr(-2);
    const day = ("0" + date.getUTCDate()).substr(-2);
    const hour = ("0" + date.getUTCHours()).substr(-2);
    const min = ("0" + date.getUTCMinutes()).substr(-2);
    const b = day + '-' + month + '-' + year + ' ' + hour + ':' + min;

    return isNaN(year) ? "" : b;
}