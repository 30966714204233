import React from 'react';
/* Material UI */
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

/* Styles */
const styles = {
    titleIconWrapper: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '4px',
        marginRight: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'primary.main',
        borderRadius: '8px',
    },
    titleIcon: {
        fontSize: '2.5rem',
        color: 'rgba(0,0,0,.75)'
    },
    title: {
        fontWeight: 600,
        wordBreak: 'break-word',
        '@media (max-width: 480px)': {
            fontSize: '1.75rem'
        }
    },
    divider: {
        marginTop: '8px',
        marginBottom: '24px',
        height: '2px'
    },
};

const FormTitle = ({ icon, title }) => {

    return (
        <>    
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Box sx={styles.titleIconWrapper}>
                    <Icon sx={styles.titleIcon}>{icon}</Icon>
                </Box>
                <Container style={{padding: 0, margin: 0}}>
                    <Typography sx={styles.title} variant="h5" color="secondary">
                        {title}
                    </Typography>
                </Container>
            </div>
            
            <Divider sx={styles.divider} />
        </>
    );

}

export default FormTitle;