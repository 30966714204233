import React from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
/* Components */
import Counter from '../Counter';
import ACLWrapper from '../../../Containers/ACLWrapper';

/* Styles */
const styles = {
    row: {
        backgroundColor: 'secondary.medium',
    },
    selectedAllRow: {
        backgroundColor: 'primary.medium',
    }
};


const UnitsTableFirstRow = ({ selectedAll, setSelectedAll, selectedList, setSelectedList, counter, setCounter, noLimitAll, setNoLimitAll, selectAll, handleDecrement, handleIncrement, handleInputChange }) => {
  
    const { t } = useTranslation();


    return (
        <TableRow sx={selectedAll ? styles.selectedAllRow : styles.row}>
            <TableCell component="th" scope="row">
                <Checkbox
                    color="primary"
                    checked={selectedAll} 
                    onChange={() => {
                        if (selectedAll) {
                            setSelectedList([]);
                            setSelectedAll(false);
                            setNoLimitAll(false);
                            setCounter(0);
                        }
                        else {
                            selectAll();
                        }   
                    }}
                />       
            </TableCell>
            <TableCell>
                {selectedList.length > 0 ? // SELECTED SOME OF THE UNITS
                        <Typography style={{display: 'flex'}} variant="body2">
                            {t('services.unitsTable.label.numberOfUnits') + ":"}
                            &nbsp;
                            <Box fontWeight="fontWeightBold">
                                {selectedList.length}
                            </Box>
                        </Typography> 
                    :
                    <Typography variant="body2">
                        <Box fontWeight={600}>
                            {t('services.unitsTable.label.selectAllUnits')}
                        </Box>
                    </Typography>}
            </TableCell>
            <TableCell align="center">
                <Counter 
                    value={counter}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    handleInputChange={handleInputChange}
                    disabled={(!selectedAll && (selectedList.length === 0)) || noLimitAll}
                />
            </TableCell>
            {/*<TableCell align="center">
                <Counter 
                    disabled 
                />
            </TableCell>*/}
            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <TableCell align="center"> 
                    <FormControlLabel
                        control={
                            <Checkbox 
                                disabled={!selectedAll && selectedList.length === 0}
                                checked={noLimitAll}
                                onChange={() => {
                                    if (noLimitAll) {
                                        setNoLimitAll(false);
                                        setCounter(0);
                                    }
                                    else {
                                        setNoLimitAll(true);
                                        setCounter(1200);
                                    }
                                }}
                                name="checkAll"
                                color="primary"
                            />
                        }
                        label={t('services.unitsTable.label.noLimit')}
                    />
                </TableCell>
            </ACLWrapper>
        </TableRow>
    );
}

export default UnitsTableFirstRow;