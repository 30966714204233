export const styles = {
    container: {
        alignItems: 'center',
        display: 'inline-flex',
        flexWrap: 'wrap',
        height: '56px',
        padding: '0px !important',
        width: '224px'
    },
    colorBox: {
        borderRadius: '4px',
        height: '20px', 
        margin: '0 4px',
        width: '20px', 
        '&:hover': {
            cursor: 'pointer'
        }
    },
};