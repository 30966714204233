import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* Material UI */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
/* Components */
import VersionModal from '../../modals/VersionModal/VersionModal';
import CustomDialog from '../../lib/CustomDialog/CustomDialog';
import ACLWrapper from '../../../Containers/ACLWrapper';
/* Global state */
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { FeedActionsContext } from '../../../Context/FeedContext';
import { DELETE_VERSION } from '../../../Context/reducers/feedReducer';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';
/* Styles */
import { styles } from './Version.styles';


const Version = ({ ...version }) => {
  
  const [editVersionModalOpen, setEditVersionModalOpen] = useState(false);
  const [removeVersionDialogOpen, setRemoveVersionDialogOpen] = useState(false);
  
  const { t } = useTranslation();
  const history = useHistory();
  
  const { setSnack } = useContext(SnackbarContext);
  const dispatchFeed = useContext(FeedActionsContext);

  /**
   * Delete version
   * @param {*} event 
   */
   const handleDelete = (event) => {

    event.preventDefault();
    
    const onSuccess = (response) => {
        dispatchFeed({
          type: DELETE_VERSION,
          id: version.id
        });
        setSnack({ message: t('snackbar.message.versionRemoved'), type: "info", open: true});
        setRemoveVersionDialogOpen(false);
    }
    const onError = (error) => {
        console.log(`error in delete /news/versions/${version.id}`)
        if (error.status === 401 || error.status === 403) {
            localStorage.removeItem("token");
            history.push({ pathname: '/login', state: { showSnack: true }});
        }
    }
    
    BackendRequest("delete", `/news/versions/${version.id}`, null, onSuccess, onError);
  };

  return (
    <Box sx={styles.item}>
      <Box sx={styles.version}>
        <Typography variant="h6" style={{fontWeight: 600, marginBottom: '4px', marginRight: '8px'}}>{version.version}</Typography>
        <Chip label={version.release_date} size="small" icon={<CalendarTodayIcon />} sx={styles.chip} />
      </Box>
      <Box style={{width: '100%'}}>
        <ACLWrapper requiredPermission={'ACL_ADMIN'}>
          <Box sx={styles.toolbar}>
            <Tooltip title={t('customers.overview.tooltip.edit')}>
                <IconButton 
                    sx={styles.iconButton} 
                    aria-label="edit"
                    onClick={() => setEditVersionModalOpen(!editVersionModalOpen)}
                >
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title={t('customers.overview.tooltip.delete')}>
                <IconButton 
                    sx={styles.iconButton} 
                    aria-label="delete"
                    onClick={() => setRemoveVersionDialogOpen(!removeVersionDialogOpen)}
                >
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
          </Box>
        </ACLWrapper>
        {version.description.split("\n").map(description => (
          <Typography variant="body1" sx={styles.description}>
            {description || '\u00A0'}
          </Typography>
        ))}
      </Box>

      {editVersionModalOpen && 
        <VersionModal
          version={version}
          handleClose={() => setEditVersionModalOpen(false)}
          open={editVersionModalOpen}
        />
      }
      {removeVersionDialogOpen &&
        <CustomDialog
          buttons={[
            {
              action: handleDelete,
              label: t('button.remove')
            },
          ]}
          content={t('dialog.content.doYouReallyWantToRemoveVersion')}
          handleClose={() => setRemoveVersionDialogOpen(false)}
          open={removeVersionDialogOpen}
          title={t(
            'dialog.title.removeVersion',
            { version: version.version }
          )}
        />
      }
    </Box>
  )
}

export default Version;