import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
/* Material UI */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
/* Components */
import CustomButton from '../CustomButton/CustomButton';
import CustomSearchField from '../CustomSearchField/CustomSearchField';
/* Styles */
import { styles } from './CustomModal.styles';


const CustomModal = ({
    buttons,
    content,
    description,
    error,
    handleClose,
    isInitiallyLoading,
    open,
    search,
    title,
    titleIcon
}) => {

    const { t } = useTranslation();
    
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            sx={styles.modal}
        >
            <DialogTitle>
                <Typography 
                    color="primary"
                    gutterBottom
                    sx={styles.title}
                    variant="h6"
                >
                    {titleIcon ? (
                        <>
                            <Icon>{titleIcon}</Icon>
                            {title}
                        </>
                    ) : title}
                </Typography>
            </DialogTitle>

            {search && (isInitiallyLoading !== undefined && isInitiallyLoading === false) ? (
                <CustomSearchField
                    autoFocus
                    handleInputChange={search.handleInputChange}
                    searchQuery={search.searchQuery}
                    style={styles.searchBox} // Need to be style property, not sx property. Look at CustomSearchField component.
                />
            ) : null}

            {description ? (
                <DialogContent sx={styles.descriptionContent}>
                    {description}
                </DialogContent>
            ) : null}

            <DialogContent sx={description && styles.mainContent}>
                {content}
            </DialogContent>

            {error ? (
                <DialogContent sx={styles.errorContent}>
                    <Typography variant="subtitle1" color="error">
                        {error}
                    </Typography>
                </DialogContent>
            ) : null}

            <DialogActions sx={styles.dialogActions}>
                <CustomButton 
                    color="secondary" 
                    onClick={handleClose}
                    style={{width: '100%'}}
                >
                    {t('customers.dialog.button.cancel')}
                </CustomButton>
                {buttons.map(btn => (
                    <CustomButton
                        autoFocus
                        color="primary"
                        disabled={btn.disabled ?? false}
                        key={btn.label}
                        onClick={btn.action}
                        style={{width: '100%'}}
                    >
                        {btn.label}
                    </CustomButton>
                ))}
            </DialogActions>
        </Dialog>
    );
}

CustomModal.defaultProps = {
    buttons: [],
    description: null,
    error: null,
    search: null,
    titleIcon: null,
};

CustomModal.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    })),
    content: PropTypes.node.isRequired,
    description: PropTypes.string,
    error: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    search: PropTypes.shape({
        handleInputChange: PropTypes.func.isRequired,
        searchQuery: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    titleIcon: PropTypes.string,
};

export default CustomModal;