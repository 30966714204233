import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
/* Components */
import * as Scene from '../Scenes';
import PrivateRoute from '../Utils/PrivateRoute';
import Layout from '../components/Layout/Layout';

const AppContainer = ({ userType, userConfig, userServicePrice }) => {

    //console.log("[AppContainer]");

    const defaultRoutes =  [
        /* Dashboard */
        <PrivateRoute key="dashboard" path="/dashboard" exact component={Scene.Dashboard} />,
        <PrivateRoute key="logs" path="/unit/:id/logs" exact component={Scene.Logs} />,
        <PrivateRoute key="remote-connection" path="/remote-connection/:id" exact component={Scene.RemoteConnection} />,
        /* Browsers */
        <PrivateRoute key="browsers-edit" path="/browsers/edit/:id" exact component={Scene.BrowserSettings} />,
        <PrivateRoute key="browsers-add" path="/browsers/add" exact component={Scene.BrowserCreate} />,
        <PrivateRoute key="browsers" path="/browsers" exact component={Scene.Browsers} />,
        /* Feed */
        <PrivateRoute key="feed" path="/feed" exact component={Scene.Feed} />,
        /* Cloud */
        <PrivateRoute key="cloud" path="/cloud" exact component={Scene.Cloud} />,
        /* Contact */
        <PrivateRoute key="contact" path="/contact" exact component={Scene.Contact} />,
        /* Help */
        <PrivateRoute key="help" path="/help" exact component={Scene.Help} />,
        /* My account */
        <PrivateRoute path="/my-account" exact component={Scene.MyAccount} />,
        /* Redirect if unknown URL address */
        <Redirect to="/dashboard" />
    ];

    const servicesRoutes = {
        units: [
            <PrivateRoute key="services-units" path="/services/units" exact component={Scene.ServicesAllUnits} />,
            <PrivateRoute key="services-units-order" path="/services/units/order" exact component={Scene.ServicesSelectedUnits} />,
            <PrivateRoute key="services-units-summary" path="/services/units/summary" exact component={Scene.ServicesUnitsSummary} />,
        ],
        browsers: [
            <PrivateRoute key="services-browsers" path="/services/browsers" exact component={Scene.ServicesAllBrowsers} />,
            <PrivateRoute key="services-browsers-order" path="/services/browsers/order" exact component={Scene.ServicesSelectedBrowsers} />,
            <PrivateRoute key="services-browsers-summary" path="/services/browsers/summary" exact component={Scene.ServicesBrowsersSummary} />
        ]
    }
    
    const customerRoutes = [
        <PrivateRoute key="customers-edit" path="/customers/edit/:id" exact component={Scene.CustomerSettings} />,
        <PrivateRoute key="customers-add" path="/customers/add" exact component={Scene.CustomerCreate} />,
        <PrivateRoute key="customers" path="/customers" exact component={Scene.Customers} />
    ]
    
    const customizationRoute = <PrivateRoute path="/customization" exact component={Scene.Customization} />;
    
    /**
     * Routes for admin and reseller admin
     */
    if (userType !== "customer") {
        return (
            <Layout>
                <Switch>
                    {servicesRoutes.units}
                    {servicesRoutes.browsers}
                    {customerRoutes}
                    {customizationRoute}
                    {defaultRoutes}
                </Switch>
            </Layout>
        );
    }
    
    /**
     * Routes for customer with hidden 'Services' 
     */
    else if (userConfig && userConfig.hide_all === true) {
        return (
            <Layout>
                <Switch>
                    {defaultRoutes}
                </Switch>
            </Layout>
        )
    }
    
    /**
     * Routes for customer - all service routes because 'service_price' not set or 'Browser' and 'Remote management' has non zero price
     */
    else if (userServicePrice === null || 
        Object.entries(userServicePrice).length === 0 || 
        (parseInt(userServicePrice["Browser"].price) !== 0 && parseInt(userServicePrice["Remote management"].price) !== 0)) {
        return (
            <Layout>
                <Switch>
                    {servicesRoutes.units}
                    {servicesRoutes.browsers}
                    {defaultRoutes}
                </Switch>
            </Layout>
        )
    }
    
    /**
     * Routes for customer - no service routes because 'Browser' and 'Remote management' has zero price 
     */
    else if (parseInt(userServicePrice["Browser"].price) === 0 && parseInt(userServicePrice["Remote management"].price) === 0) {
        return (
            <Layout>
                <Switch>
                    {defaultRoutes}
                </Switch>
            </Layout>
        );
    }
    
    /**
     * Routes for customer - only service units routes because 'Browser' has zero price 
     */
    else if (parseInt(userServicePrice["Browser"].price) === 0) {
        return (
            <Layout>
                <Switch>
                    {servicesRoutes.units}
                    {defaultRoutes}
                </Switch>
            </Layout>
        )
    }

    /**
     * Routes for customer - only service browser routes because 'Remote management' has zero price 
     */
    else if (parseInt(userServicePrice["Remote management"].price) === 0) {
        return (
            <Layout>
                <Switch>
                    {servicesRoutes.browsers}
                    {defaultRoutes}
                </Switch>
            </Layout>
        )
    }
}

export default React.memo(AppContainer);
