import React from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Components */
import ACLWrapper from '../../../Containers/ACLWrapper';

/* Styles */
const styles = {
    unitCardFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: '13px',
            '@media (max-width: 480px)': {
                fontSize: '12px'
            }
        }
    }
};

const UnitCardFooter = ({ unit }) => {

    const { t } = useTranslation();

    //console.log("[UnitCardFooter]");

    return (
        <>
            <Box sx={styles.unitCardFooter}>
                <Typography gutterBottom>
                    {t('dashboard.unit.label.serialNumberAbbr')}: {unit.serial_number}
                </Typography>
                <Typography gutterBottom>
                    SW: {unit.version_sw ? unit.version_sw : "-"} | HW: {unit.version_hw ? unit.version_hw : "-"}
                </Typography>
            </Box>
            <ACLWrapper requiredPermission={"ACL_ADMIN"}>
                <Box sx={styles.unitCardFooter}>
                    <Typography>
                        IP: {unit.vpn_ip}
                    </Typography>
                    <Typography>
                        ID: {unit.id}
                    </Typography>
                </Box>
            </ACLWrapper>
        </>
    );

}

export default React.memo(UnitCardFooter);