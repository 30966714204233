import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
/* Material UI */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
/* Global state */
import { AuthenticationContext } from '../../../../Context/AuthenticationContext';
import { OrderActionsContext } from '../../../../Context/OrderContext';
import { DELETE_ORDER } from '../../../../Context/reducers/orderReducer';
/* Components */
import DrawerListItem from '../DrawerListItem/DrawerListItem';
/* Utils */
import { localStorage } from '../../../../Utils/localStorage';

const items = [
    {
        title: 'navigation.title.myAccount',
        icon: 'account_circle',
        link: '/my-account',
        component: "MyAccount",
        hiddenByDefault: false,
        acl: "ACL_CUSTOMER"
    },
    /*{
        title: 'navigation.title.contact',
        icon: 'contact_mail',
        link: '/contact',
        component: "Contact",
        hiddenByDefault: false,
        acl: "ACL_CUSTOMER"
    },
    {
        title: 'navigation.title.help',
        icon: 'help',
        link: '/help',
        component: "Help",
        hiddenByDefault: false,
        acl: "ACL_CUSTOMER"
    },*/
];

const DrawerFooter = () => {

    const { t } = useTranslation();
    const history = useHistory();
    
    const { setAuth } = useContext(AuthenticationContext);
    const dispatchOrder = useContext(OrderActionsContext);

    /**
     * Log out - delete whole order, clear storage and reset authentication
     */
    const logOut = () => {
        dispatchOrder({
            type: DELETE_ORDER
        });
        localStorage.removeItem("token");
        setAuth(false);
        history.push('/login')
    }
    
    return (
        <div>
            <Divider />
            <List>
            
                {/* My Account */}
                {items.map(item => <DrawerListItem key={item.title} item={item} />)}
                
                {/* Log out */}
                <ListItem button onClick={() => logOut()}>
                    <ListItemIcon>
                        <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        {t('navigation.title.logOut')}
                    </ListItemText>
                </ListItem>

            </List>
        </div>
    );
}

export default React.memo(DrawerFooter);