import React, { createContext, useContext, useState, useReducer, useEffect } from 'react';

import { orderReducer, UPDATE_PRICES_PER_ONE } from "./reducers/orderReducer";
import { UserContext } from "../Context/UserContext";

export const OrderContext = createContext();
export const OrderActionsContext = createContext();

const UNIT_DEFAULT_PRICE = 5;
const BROWSER_DEFAULT_PRICE = 19;

const OrderProvider = ({ children }) => {

    const { user } = useContext(UserContext);
    
    const orderStructure = {
        credit: 0,
        units: {
            bulk_price_per_one: null,
            bulk_price_duration: null,
            credit_price_per_one: null,
            number_of_months: 0,
            price_per_one: UNIT_DEFAULT_PRICE,
            price: 0,
            order: [],
            used_price_per_one: 'price_per_one',
        },
        browsers: {
            bulk_price_per_one: null,
            bulk_price_duration: null,
            credit_price_per_one: null,
            number_of_months: 0,
            price_per_one: BROWSER_DEFAULT_PRICE,
            price: 0,
            order: [],
            used_price_per_one: 'price_per_one',
        },
    }

    const [currentOrderState, dispatchOrder] = useReducer(orderReducer, orderStructure);

    useEffect(() => {
        if (user) {

            /* VAT rate */
            let vat = 1;
            if (user.vat_rate) {
                vat = 1 + parseInt(user.vat_rate)/100;
            }

            /* Service price */
            if (user.service_price && Object.entries(user.service_price).length !== 0) {
                dispatchOrder({
                    type: UPDATE_PRICES_PER_ONE,
                    // Browsers
                    browserBulkPricePerOne: user.service_price["Browser"] ? user.service_price["Browser"].bulk_price * vat : null,
                    browserBulkPriceDuration: user.service_price["Browser"] ? user.service_price["Browser"].bulk_price_duration : null,
                    browserCreditPricePerOne: user.service_price["Browser"] ? user.service_price["Browser"].price_from_credit * vat : null,
                    browserPricePerOne: user.service_price["Browser"] ? user.service_price["Browser"].price * vat : BROWSER_DEFAULT_PRICE * vat,
                    // Units
                    unitBulkPricePerOne: user.service_price["Remote management"] ? user.service_price["Remote management"].bulk_price * vat : null,
                    unitBulkPriceDuration: user.service_price["Remote management"] ? user.service_price["Remote management"].bulk_price_duration : null,
                    unitCreditPricePerOne: user.service_price["Remote management"] ? user.service_price["Remote management"].price_from_credit * vat : null,
                    unitPricePerOne: user.service_price["Remote management"] ? user.service_price["Remote management"].price * vat : UNIT_DEFAULT_PRICE * vat,
                    // User's credit
                    credit: user.credit,
                })
            }
            else {
                dispatchOrder({
                    type: UPDATE_PRICES_PER_ONE,
                    browserPricePerOne: BROWSER_DEFAULT_PRICE * vat,
                    unitPricePerOne: UNIT_DEFAULT_PRICE * vat,
                })
            }
        } 
    }, [user])

    return (
        <OrderContext.Provider value={currentOrderState}>
            <OrderActionsContext.Provider value={dispatchOrder}>
                {children}
            </OrderActionsContext.Provider>
        </OrderContext.Provider>
    );

}

export default OrderProvider;