export const styles = {
    item: {
      marginBottom: '8px',
      padding: '12px 16px',
      borderRadius: '4px',
      borderWidth: '1px',
      borderStyle: 'solid',
      '@media (max-width: 767px)': {
        padding: '12px',
      }
    },
    info_item: {
      borderColor: 'success.main',
      backgroundColor: 'success.light',
    },
    warning_item: {
      borderColor: 'warning.main',
      backgroundColor: 'warning.light',
    },
    outage_item: {
      borderColor: 'error.main',
      backgroundColor: 'error.light',
    },
    itemHeaderRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '6px',
    },
    itemHeaderItemInfo: {
      display: 'flex',
      flexDirection: 'row',
      '@media (max-width: 767px)': {
        flexDirection: 'column',
      }
    },
    itemHeaderTitle: {
      lineHeight: '28px',
      fontWeight: 600,
      margin: '0 8px',
      '@media (max-width: 767px)': {
        margin: '6px 0',
      }
    },
    divider: {
      marginTop: '8px',
      marginBottom: '16px',
    },
    icon: {
      fontSize: '26px'
    },
    chip: {
      padding: '0 4px',
      width: 'fit-content'
    },
    content: {
      textAlign: 'justify'
    },
    iconButton: {
      padding: '4px'
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'end',
      flexDirection: 'row',
      '@media (max-width: 767px)': {
        flexDirection: 'column',
        marginLeft: '16px'
      }
    }
  }