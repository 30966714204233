import React from 'react';
/* Material UI */
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
/* Styles */
import { styles } from './CustomTextField.styles';

const CustomTextField = ({
    error,
    handleChange,
    label,
    name,
    value,
    ...otherProps
}) => {

    return (
        <TextField 
            error={error}
            InputProps={{
                startAdornment: <InputAdornment />,
            }}
            label={label}
            name={name}
            onChange={handleChange}
            sx={styles.field}
            value={value}
            variant="outlined"
            {...otherProps}
        />
    );
}

export default CustomTextField;