import React from 'react';
/* Material UI */
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
/* Styles */
import { styles } from './CustomColorPalette.styles';

const colors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#83ce22',
    '#00fa9a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ffa07a'
]

const CustomColorPalette = ({
    handleColorChange,
}) => (
    <Container
        maxWidth="sm"
        sx={styles.container}
    >
        {colors.map((color) => 
            <Typography
                component="div"
                sx={styles.colorBox}
                onClick={() => handleColorChange(color)}
                style={{ backgroundColor: color}}
            />
        )}
    </Container>     
);

export default CustomColorPalette;