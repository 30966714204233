import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* Material UI */
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../../lib/CustomSelectField/CustomSelectField';
/* Global state */
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { FeedActionsContext } from '../../../Context/FeedContext';
import { ADD_FEED_ITEM, UPDATE_FEED_ITEM } from '../../../Context/reducers/feedReducer';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';


const FeedItemModal = ({
    feed = null,
    handleClose,
    open,
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    
    const [ state, setState ] = useState(feed ? { ...feed } : {
        title: "",
        type: "info",
        content: ""
    });
    
    const { setSnack } = useContext(SnackbarContext);
    const dispatchFeed = useContext(FeedActionsContext);

    /**
     * Handle change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    /**
     * Handle submit
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        
        event.preventDefault();

        const tzoffset = (new Date()).getTimezoneOffset() * 60000;
        let now = new Date(Date.now() - tzoffset);
        now = now.toISOString().slice(0, -1);
        now = now.replace('T', ' ');
        now = now.substring(0, now.indexOf('.'));
    
        const onSuccess = (response) => {
            if (feed) {
                dispatchFeed({
                    type: UPDATE_FEED_ITEM,
                    id: feed.id,
                    updatedFeedItem: { ...state, ...response.data }
                });
                setSnack({ message: t('snackbar.message.changesSaved'), type: "info", open: true});
            }
            else {
                dispatchFeed({
                    type: ADD_FEED_ITEM,
                    newFeedItem: { ...state, publish_date: now, ...response.data }
                });
                setSnack({ message: 'News feed item added.', type: "info", open: true});
            };
            setState({
                title: "",
                type: "info",
                content: ""
            });
            handleClose();
        }
        const onError = (error) => {
            console.log('error in /news/feed');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        if (feed) {
            BackendRequest("put", `/news/feed/${feed.id}`, state, onSuccess, onError);
        }
        else {
            BackendRequest("post", '/news/feed', { ...state, publish_date: now }, onSuccess, onError);
        }
    }

    const content = (
        <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Title */}
                <CustomTextField
                    autoFocus 
                    name="title"
                    value={state.title}
                    handleChange={handleChange}
                    label="TITLE"
                    type="text"
                    InputProps={{
                        startAdornment: <InputAdornment />,
                    }}
                />
            </Grid>

            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Type */}
                <CustomSelectField
                    name="type"
                    value={state.type}
                    handleChange={handleChange}
                    label="TYPE"
                >
                    <MenuItem value="info">Info</MenuItem>
                    <MenuItem value="warning">Warning</MenuItem>
                    <MenuItem value="outage">Outage</MenuItem>
                </CustomSelectField>
            </Grid>

            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Content */}
                <CustomTextField
                    multiline
                    maxRows={10}
                    minRows={4}
                    name="content"
                    label="CONTENT"
                    type="text"
                    value={state.content}
                    onChange={handleChange}
                    placeholder="Add content..."
                    style={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}
                />
            </Grid>
        </Grid>
    );
    
    return (
        <CustomModal
            buttons={[
                {
                    action: handleSubmit,
                    label: t('button.save')
                },
            ]}
            content={content}
            handleClose={handleClose}
            open={open}
            title={feed ? feed.title : "Add new feed item"}
        />
    );
}

export default FeedItemModal;