export const styles = {
    listItem: {
        minWidth: '100%',
        width: 'fit-content',
        padding: '8px',
        backgroundColor: 'secondary.light',
        borderRadius: '4px',
        marginBottom: '4px',
        zIndex: '999 !important', //not working
        display: 'flex',
        justifyContent: 'space-between',
        '&:hover': {
            //cursor: 'pointer',
            backgroundColor: 'secondary.lightMedium',
        }
    },
    iconButton: {
        padding: '4px',
    },
    chip: {
        zIndex: '1 !important',
    },
    uploadChip: {
        paddingLeft: '4px'
    }
};