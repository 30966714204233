const breakpoints = {
	xs: 0,
	sm: 480,
	md: 767,
	lg: 992,
	xl: 1320,
	xxl: 1720,
};

export const defaultTheme = {
	breakpoints: {
		values: breakpoints,
	},
	typography: {
		fontFamily: ['Montserrat','sans-serif',].join(','),
	},
	palette: {
		primary: {
			main: 'rgba(131, 206, 34, 1)',
			medium: 'rgba(131, 206, 34, 0.45)',
			light: 'rgba(131, 206, 34, 0.2)'
        },
		secondary: {
			main: 'rgba(133, 133, 133, 1)',
			darker: 'rgba(133, 133, 133, 0.7)',
			medium: 'rgba(133, 133, 133, 0.35)',
			lightMedium: 'rgba(133, 133, 133, 0.25)',
			light: 'rgba(133, 133, 133, 0.2)',
			ultraLight: 'rgba(133, 133, 133, 0.1)'
		},
		error: {
			main: 'rgba(240, 52, 52, 1)',
			light: 'rgba(240, 52, 52, .1)'
		},
		success: {
			main: 'rgba(131, 206, 34, 1)',
			light: 'rgba(131, 206, 34, .1)'
		},
		warning: {
			main: 'rgba(245, 171, 53, 1)',
			light: 'rgba(245, 171, 53, .1)'
		},
		background: {
			light: '#f7f7f7'
		},
		common: {
			darkBlack: 'rgba(0, 0, 0, 0.87)',
			lightBlack: 'rgba(0, 0, 0, 0.54)',
			darkWhite: 'rgba(255, 255, 255, 0.87)',
			lightWhite: 'rgba(255, 255, 255, 0.54)',
			orange: 'rgba(245, 171, 53, 1)',
			red: 'rgba(240, 52, 52, 1)',
			green: 'rgba(131, 206, 34, 1)'
		},
		text: {
			primaryLight: 'rgba(0, 0, 0, .67)',
			secondaryDark: 'rgba(0, 0, 0, .76)',
		}
	},
	dimensions: {
		drawerBreakpoint: 991,
		xsBreakpoint: 480,
	},
	components: {
		MuiFormControl: {
			root: {
				flexGrow: 1,
			}
		},
		MuiPaper: {
			elevation: 3
		}
	},
};

const HexToRGBA = (hexColor, opacity) => {
	let c;
	c = hexColor.substring(1).split('');
	if(c.length === 3){
		c= [c[0], c[0], c[1], c[1], c[2], c[2]];
	}
	c = '0x'+c.join('');
	return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+`, ${opacity})`;
}

const RGBToRGBA = (rgbColor, opacity) => {
	let rgbaColor = rgbColor.replace(/rgb/i, "rgba");
	return rgbaColor.replace(/\)/i,`,${opacity})`);
}

export const colorToRGBAFormat = (color, opacity) => {
	
	if (!color) {
		color = "rgb(131, 206, 34)";
	}

	if(color[0] === "#"){
		return HexToRGBA(color, opacity);
	}
	else {
		return RGBToRGBA(color, opacity);
	}
}
