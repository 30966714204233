import React, { useState, useEffect, useContext } from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
/* Components */
import Counter from "../Counter";
import ACLWrapper from '../../../Containers/ACLWrapper';
/* Global state */
import { OrderContext, OrderActionsContext } from '../../../Context/OrderContext';
import { UPDATE_ORDER } from '../../../Context/reducers/orderReducer';

/* Styles */
const styles = {
    row: {
        backgroundColor: 'action.hover',
    },
    selectedRow: {
        backgroundColor: 'primary.light',
    },
    unitName: {
        fontWeight: 600
    },
};


const UnitsTableRow = ({ value, unit, selectedList, addToList, removeFromList }) => {
    
    const { t } = useTranslation();
    const currentOrderState = useContext(OrderContext);
    const dispatchOrder = useContext(OrderActionsContext);
    const [ counter, setCounter ] = useState(
        currentOrderState.units.order.find(orderItem => orderItem.unit_id === unit.unit_id) ? currentOrderState.units.order.find(orderItem => orderItem.unit_id === unit.unit_id).services[0].months : 0);
    const [ selected, setSelected ] = useState(false);
    const [ noLimit, setNoLimit ] = useState(false);

    //console.log("[UnitRow] unit", unit);

    const milisecondsToDays = (miliseconds) => {
        return miliseconds / 1000 / 60 / 60 / 24;
    }

    /**
     * Update value in OrderContext
     * @param {*} value 
     */
    const dispatchValue = (value) => {
        dispatchOrder({
            type: UPDATE_ORDER,
            category: "units",
            orderItem: {
                unit_id: unit.unit_id,
                unit_name: unit.unit_name,
                unit_serial: unit.unit_serial,
                services: [
                    {
                        service_name: "Remote management",
                        months: (value),
                    }
                ] 
            }
        })
    }
    
    /**
     * INCREMENT VALUE FOR REMOTE MANAGEMENT
     */
    const handleIncrement = () => {
        if (counter < 99) {
            dispatchValue(counter + 1);
            setCounter(counter + 1);
        }  
    }

    /**
     * DECREMENT VALUE FOR REMOTE MANAGEMENT
     */
    const handleDecrement = () => {
        if (counter > 0) {
            dispatchValue(counter - 1);
            setCounter(counter - 1);
        } 
    }

    /**
     * HANDLE INPUT VALUE FOR COUNTER
     * @param {*} event 
     * @returns 
     */
    const handleInputChange = (event) => {
        if (parseInt(event.target.value) || event.target.value === "0" || event.target.value === ""){
            let inputValue = !(event.target.value === "0" || event.target.value === "") ? parseInt(event.target.value) : 0;
            if (inputValue > 99)
                inputValue = 99;
            dispatchValue(inputValue);
            setCounter(inputValue);
        }
        else {
            return;
        }
    }

    /**
     * CHECK IF IS THE UNIT IS IN THE LIST FOR SETTING MULTIPLE UNITS
     */
    useEffect(() => {
        setSelected(selectedList.find(listUnit => listUnit.unit_id === unit.unit_id));
    }, [selectedList]);

    /**
     * HANDLING NOLIMIT FOR MULTIPLE UNITS AND EVERY CHANGE OF VALUE WHICH LEADS TO UDPATE OF ORDER CONTEXT
     */
    useEffect(() => {
        if (selected) {
            dispatchValue(value);
            setCounter(value);
        }
        if ((counter === 1200 && selected) || (counter === 1200 && !noLimit))
            setNoLimit(true);
        else
            setNoLimit(false);
    }, [value])

    /**
     * CHECK FOR DISMISS ORDER
     */
    useEffect(() => {
       if (currentOrderState.units.price === 0 && currentOrderState.units.order.length === 0) {
            setCounter(0);
            setNoLimit(false);
       }
    }, [currentOrderState.units])

    return (
        <TableRow sx={selected ? styles.selectedRow : styles.row} key={unit.unit_id}>
            {/* CHECKBOX */}
            <TableCell scope="row">
                <Checkbox
                    color="primary"
                    checked={selected} 
                    onChange={() => {
                        if (!selected){
                            addToList({unit_id: unit.unit_id, unit_name: unit.unit_name, unit_serial: unit.unit_serial}); 
                            setSelected(true);
                            setCounter(value);
                            dispatchValue(value);
                        }
                        else {
                            removeFromList(unit.unit_id);
                            setSelected(false);
                            setCounter(0);
                            dispatchValue(0);
                        }
                    }}
                />       
            </TableCell>
            
            {/* UNIT NAME WITH SERIAL NUMBER */}
            <TableCell>
                <Typography sx={styles.unitName} color="primary" gutterBottom>
                    {unit.unit_name.toUpperCase()}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {t('services.unitsTable.label.serialNumberAbbr')}: {unit.unit_serial}
                </Typography>
            </TableCell>

            {/* REMOTE MANAGEMENT */}
            <TableCell align="center">
                <Counter 
                    disabled={noLimit || selected} 
                    value={counter}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    handleInputChange={handleInputChange}
                />
            </TableCell>

            {/* MONITORING */}
            {/*<TableCell align="center">
                <Counter 
                    disabled 
                />
                <Typography variant="subtitle2" color="secondary">
                    <Box fontWeight="fontWeightBold">
                        {t('services.unitsTable.label.notAvailable')}
                    </Box>
                </Typography>
            </TableCell>*/}
            
            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <TableCell align="center"> 
                    <FormControlLabel
                        control={
                            <Checkbox 
                                disabled={selected}
                                checked={noLimit || counter === 1200} 
                                onChange={() => {
                                    if (noLimit) {
                                        setNoLimit(false);
                                        setCounter(0);
                                        dispatchValue(0);
                                    }
                                    else {
                                        setNoLimit(true);
                                        setCounter(1200);
                                        dispatchValue(1200);
                                    }
                                }}
                                name="checkAll" 
                                color="primary"
                            />
                        }
                        label={t('services.unitsTable.label.noLimit')}
                    />
                </TableCell>
            </ACLWrapper>
        </TableRow>
    );
}

export default React.memo(UnitsTableRow);