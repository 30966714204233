import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
/* Material UI */
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
/* Components */
import FeedOverview from '../components/Feed/FeedOverview';
import VersionsOverview from '../components/Feed/VersionsOverview';
import BackendRequest from '../Utils/BackendRequest';
/* Global state */
import { UserContext } from '../Context/UserContext';
import { FeedContext } from '../Context/FeedContext';
import { FeedActionsContext } from '../Context/FeedContext';
import { FETCH_FEED_ITEMS, FETCH_VERSIONS } from '../Context/reducers/feedReducer';

/* Styles */
const styles = {
    page: {
        position: 'relative',
        paddingBottom: '70px'
    },
    tabs: {
        borderBottom: 1,
        borderColor: 'divider',
        marginBottom: '16px',
    },
    tab: {
        fontWeight: 600
    }
};

const TabPanel = ({ children, value, index, ...other }) => {
    return(
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>{children}</Box>
            )}
        </Box>
    );
}

const Feed = () => {

    const [tab, setTab] = useState(0);
    const history = useHistory();

    const { t } = useTranslation();
    const feed = useContext(FeedContext);
    const dispatchFeed = useContext(FeedActionsContext);

    const getFeed = () => {
        const onSuccess = (response) => {
            dispatchFeed({
                type: FETCH_FEED_ITEMS,
                feedItems: response.data
            })
        }
        const onError = (error) => {
            console.log("error in /feed/?page=0&page_size=12", error);
            if ((error.status === 401 || error.status === 403)) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/news/feed?page=0&page_size=12", null, onSuccess, onError);
    };

    const getVersions = () => {
        const onSuccess = (response) => {
            dispatchFeed({
                type: FETCH_VERSIONS,
                versions: response.data
            })
        }
        const onError = (error) => {
            console.log("error in /versions", error);
            if ((error.status === 401 || error.status === 403)) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/news/versions", null, onSuccess, onError);
    };

    useEffect(() => {
        getFeed();
        getVersions();
    }, [])

    const handleTabChange = (e, t) => setTab(t);

    return (
        <Box sx={styles.page}>
            <Box sx={styles.tabs}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label={t("feed.title.feed")} value={0} sx={styles.tab} />
                    <Tab label={t("feed.title.versions")} value={1} sx={styles.tab}/>
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <FeedOverview feed={feed.feedItems} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <VersionsOverview versions={feed.versions} />
            </TabPanel>
        </Box>
    );
}

export default Feed;