export const localStorage = window.localStorage;

export const setToken = (value, rememberMe = false) => {
    const localStorage = window.localStorage;
    
	const now = new Date();
    const TTL_DAY = 86400000; // 24 hours = 86 400 000 milliseconds */
	const TTL_WEEK = 604800000; // 168 hours = 604 800 000 milliseconds */

	const item = {
		value: value,
		expiry: rememberMe ? now.getTime() + TTL_WEEK : now.getTime() + TTL_DAY
	};
	localStorage.setItem("token", JSON.stringify(item));
}

export const getTokenFromLocalStorage = () => {
    const localStorage = window.localStorage;
    const itemStr = localStorage.getItem("token");

	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr);
	
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem("token");
		return null;
	};

	return item.value;
}
