import React from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
/* Components */
import CustomButton from '../lib/CustomButton/CustomButton';

/* Styles */
const styles = {
    root: {
        display: 'inline-flex', 
        margin: '8px 0px'
    },
    button: {
        padding: '8px',
        width: 'fit-content'
    },
    buttonText: {
        color: 'black',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: 1
    },
    field: {
        width: '75px',
        marginLeft: '8px',
        marginRight: '8px'
    },
    nonZeroField: {
        '& > label': {
            color: 'primary.main'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main'
        }
    }
};

const NO_LIMIT = 1200; // FOR SERVER: 1200 months = 100 years


const Counter = ({ disabled, value=0, handleIncrement, handleDecrement, handleInputChange }) => {
    
    const { t } = useTranslation();

    return (
        <Box sx={styles.root}>
            {/* - button */}
            <CustomButton
                color="secondary"
                disabled={disabled && true}
                onClick={handleDecrement}
                style={styles.button}
            >
                <Typography sx={styles.buttonText}>-</Typography>
            </CustomButton>
            {/* Number value */}
            <TextField
                data-testid="counter-value"
                label={t('services.unitsTable.label.months')}
                id="outlined-size-small"
                value={value !== NO_LIMIT ? value : "∞"}
                onChange={handleInputChange}
                variant="outlined"
                size="small"
                inputProps={{style: { textAlign: 'center' }}}
                sx={Object.assign({}, styles.field, value !== 0 && styles.nonZeroField)}
                disabled={disabled && true}
            />
            {/* + button */}
            <CustomButton 
                color="secondary"
                disabled={disabled && true}
                onClick={handleIncrement}
                style={styles.button}
            >
                <Typography sx={styles.buttonText}>+</Typography>
            </CustomButton>
        </Box>

    );
}

export default Counter;