//import "./wdyr";

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import moment from 'moment-timezone';
/* Material UI */
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
/* Context providers */
import LanguageProvider from './Context/LanguageContext';
import UserProvider from './Context/UserContext';
import DrawerProvider from "./Context/DrawerContext";
/* Own */
import App from './App/App';
/* CSS */
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <UserProvider>
            <LanguageProvider>
              <DrawerProvider>
                <RecoilRoot>
                  <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                    <App />
                  </LocalizationProvider>
                </RecoilRoot>
              </DrawerProvider>
            </LanguageProvider>
          </UserProvider>
        </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();