import React, { useContext } from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
/* Global state */
import { UserContext } from '../../../Context/UserContext';
/* Components */
import ACLWrapper from '../../../Containers/ACLWrapper';

/* Styles */
const styles = {
    unitCardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: '4px',
        width: 'fit-content',
        maxWidth: '100%',
        '@media (max-width: 480px)': {
            fontSize: 20
        }
    },
    icon: {
        color: 'secondary.main',
    },
    subtitle: {
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 480px)': {
            fontSize: 14
        }
    },
    badge: {
        backgroundColor: '#a1a1a1',
        width: 50,
        height: 50,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    badgeSleepColor: {
        '& > .MuiBadge-badge': {
            backgroundColor: 'common.orange'
        }
    },
    badgeOnlineColor: {
        '& > .MuiBadge-badge': {
            backgroundColor: 'common.green'
        }
    },
    badgeOfflineColor: {
        '& > .MuiBadge-badge': {
            backgroundColor: 'common.red'
        }
    },
    badgePlacementNotCustomer: {
        position: 'absolute', 
        right: '16px', 
        top: '66px',
    },
    badgePlacementCustomer: {
        position: 'absolute', 
        right: '16px', 
        top: '48px',
    }
};

const UnitCardHeader = ({
    expired,
    unit,
}) => {

    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    let unitState = "offline";
    if (!unit.version_sw || parseInt(unit.version_sw.charAt(0)) < 3) {
        if (unit.online !== undefined && unit.online !== null && (unit.online.online === true || unit.online === true))
            unitState = "online";
        if (unit.online !== undefined && unit.online !== null && unit.online.online === false && unit.pwr_mode === "powersave")
            unitState = "sleeping";
    }
    else {
        if (unit.unit_state === "unknown")
            unitState = "offline"
        else
            unitState = unit.unit_state
    }

    let userInfo = null;
    if (user.type !== 'customer') {
        if ((unit.users && unit.users.length <= 1) || unit.users === undefined) { // unit.users[0] is the same as unit.user_info
            userInfo = (
                <>
                    <Icon sx={styles.icon} style={{fontSize: '1.35rem'}}>account_circle</Icon>
                    &nbsp;
                    {`${unit.user_info.first_name} ${unit.user_info.last_name}`}
                </>
            );
        } else {
            userInfo = (
                <>
                    <Tooltip  title={unit.users.map((unitUser) => <div>{unitUser.first_name} {unitUser.last_name}</div>)}>
                        <SupervisedUserCircleRoundedIcon color="secondary" />
                    </Tooltip>
                    &nbsp;
                    {`${unit.user_info.first_name} ${unit.user_info.last_name}`}
                </>
            );
        }
    }

    const badge = (
        <Box sx={styles.badge}>
            <Typography variant="h5" style={{fontWeight: '600'}}>
                {unit.pwr_mode === "powersave" ? "P" : "N"}
            </Typography>
        </Box>
    );
    
    return (
        <Box sx={styles.unitCardHeader}>
            <div style={{width: '100%'}}>
                <Tooltip title={unit.name.toUpperCase()} placement="top-start">
                    <Typography sx={styles.title} color="primary" gutterBottom>
                        {unit.name.toUpperCase()}
                    </Typography>
                </Tooltip>
                <ACLWrapper requiredPermission={'ACL_RESELLER_ADMIN'}>
                    <Typography sx={styles.subtitle} gutterBottom>
                        {userInfo}
                    </Typography>
                </ACLWrapper>
            </div>
            <Tooltip title={t('dashboard.unit.tooltip.' + (unit.pwr_mode ? unit.pwr_mode : "normal")) + " " + t('dashboard.unit.tooltip.mode') + (((!expired || user.type === "admin") && (unit.version_hw && parseInt(unit.version_hw.charAt(0)) >= 3))? " (" + t('dashboard.unit.tooltip.' + unitState) + ")" : "")}>
                {unit.version_hw && parseInt(unit.version_hw.charAt(0)) >= 3 ?
                    <Badge 
                        sx={Object.assign({}, 
                            unitState === "sleeping" && (!expired || user.type === "admin") && styles.badgeSleepColor,
                            unitState === "online" && (!expired || user.type === "admin") && styles.badgeOnlineColor,
                            unitState === "offline" && (!expired || user.type === "admin") && styles.badgeOfflineColor,
                            user.type === "customer" ? styles.badgePlacementCustomer : styles.badgePlacementNotCustomer
                        )} 
                        overlap="circular" 
                        badgeContent=""
                    >
                        {badge}
                    </Badge>
                    :
                    <Badge 
                        sx={user.type === "customer" ? styles.badgePlacementCustomer : styles.badgePlacementNotCustomer} 
                        overlap="circular" 
                        badgeContent=""
                    >
                        {badge}
                    </Badge>
                }
            </Tooltip>
        </Box>
    );
}

export default React.memo(UnitCardHeader);