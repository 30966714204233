import React, { useState, useEffect } from "react";
/* Material UI */
import Box from "@mui/material/Box";
import Input from '@mui/material/Input';
import { Typography } from "@mui/material";
/* Components */
import CustomButton from "../../lib/CustomButton/CustomButton";
/* Styles */
import { styles } from './CustomFileInput.styles';

const CustomFileInput = ({ onChange }) => {
  
    const [attachment, setAttachment] = useState();

    const handleChange = (event) => {
        const files = Array.from(event.target.files);
        const [file] = files;
        setAttachment(file);
        if (!!onChange) onChange({ target: { value: file } });
    };

    useEffect(() => {
        if (attachment) {
            let _URL = window.URL || window.webkitURL;
            let img = new Image();
            img.onload = function() {
                console.log("width: " + this.width + "px; height: " + this.height + "px");
            };
            img.onerror = function() {
                alert( "not a valid file: " + attachment.type);
            };
            img.src = _URL.createObjectURL(attachment);
        }
    }, [attachment])

    return (
        <label htmlFor="icon-button-file" style={{display: "flex", flexDirection: "column", alignItems: "start"}}>
            <Input 
                accept="image/*"
                id="icon-button-file"
                onChange={handleChange}
                style={styles.input}
                type="file" 
            />
            <CustomButton 
                color="secondary"
                component="span"
                sx={styles.button}
            >
                Upload Mask
            </CustomButton>

            <Box>
                <Typography
                    sx={styles.typography}
                    variant="body2"
                >
                    {attachment ? attachment.name : ""}
                </Typography>
            </Box>
        </label>
    );
};

export default CustomFileInput;