export const styles = {
    item: {
        padding: '12px 16px',
        marginBottom: '8px',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'secondary.light',
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-start',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            padding: '12px 0',
        },
        '@media (max-width: 480px)': {
            padding: '12px 8px',
        },
    },
    version: {
        width: '120px',
        '@media (max-width: 767px)': {
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '4px',
            marginRight: '72px'
        },
    },
    chip: {
        padding: '0 4px'
    },
    description: {
        paddingLeft: '24px',
        textAlign: 'justify',
        '@media (max-width: 767px)': {
            paddingLeft: 0
        },
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'end',
        '@media (max-width: 767px)': {
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '12px 0',
        },
    },
    iconButton: {
        padding: '4px'
    },
}