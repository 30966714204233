export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_CREDIT = "UPDATE_CREDIT";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_ITEM = "DELETE_ORDER_ITEM";
export const UPDATE_PRICES_PER_ONE = "UPDATE_PRICES_PER_ONE";

export const orderReducer = (state, action) => {

    let number_of_months = 0;

    //console.log("orderReducer", action)
    switch (action.type) {

        case UPDATE_PRICES_PER_ONE:
            return {
                credit: action.credit,
                units: {
                    ...state.units,
                    bulk_price_per_one: action.unitBulkPricePerOne ? (Math.round(action.unitBulkPricePerOne * 100) / 100).toFixed(2) : null,
                    bulk_price_duration: action.unitBulkPriceDuration,
                    credit_price_per_one: action.unitCreditPricePerOne ? (Math.round(action.unitCreditPricePerOne * 100) / 100).toFixed(2) : null,
                    price_per_one: (Math.round(action.unitPricePerOne * 100) / 100).toFixed(2),
                },
                browsers: {
                    ...state.browsers,
                    bulk_price_per_one: action.browserBulkPricePerOne ? (Math.round(action.browserBulkPricePerOne * 100) / 100).toFixed(2) : null,
                    bulk_price_duration: action.browserBulkPriceDuration,
                    credit_price_per_one: action.browserCreditPricePerOne ? (Math.round(action.browserCreditPricePerOne * 100) / 100).toFixed(2) : null,
                    price_per_one: (Math.round(action.browserPricePerOne * 100) / 100).toFixed(2),
                },
            }

        case UPDATE_CREDIT:
            let orderAfterCreditChange;
            let category = "units";

            if (state.units.order.length !== 0) {
                orderAfterCreditChange = [...state.units.order];
            } else if (state.browsers.order.length !== 0) {
                orderAfterCreditChange = [...state.browsers.order];
                category = "browsers";
            } else {
                return {
                    ...state,
                    credit: action.credit,
                }
            }

            /* Total number of months */
            number_of_months = 0;
            for (let i = 0; i < orderAfterCreditChange.length; i++) {
                for (let j = 0; j < orderAfterCreditChange[i].services.length; j++) {
                    number_of_months = number_of_months + orderAfterCreditChange[i].services[j].months;
                }
            }

            if (action.credit && action.credit >= (Math.round((number_of_months * state[category].credit_price_per_one) * 100) / 100).toFixed(2)) {
                return {
                    ...state,
                    credit: action.credit,
                    [category]: {
                        ...state[category],
                        order: orderAfterCreditChange,
                        price: (Math.round((number_of_months * state[category].credit_price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'credit_price_per_one',
                    }
                }
            }
            if (state[category].bulk_price_per_one && state[category].bulk_price_duration && number_of_months >= state[category].bulk_price_duration) {
                return {
                    ...state,
                    credit: action.credit,
                    [category]: {
                        ...state[category],
                        order: orderAfterCreditChange,
                        price: (Math.round((number_of_months * state[category].bulk_price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'bulk_price_per_one',
                    }
                }
            }
            return {
                ...state,
                credit: action.credit,
                [category]: {
                    ...state[category],
                    order: orderAfterCreditChange,
                    price: (Math.round((number_of_months * state[category].price_per_one) * 100) / 100).toFixed(2),
                    number_of_months,
                    used_price_per_one: 'price_per_one',
                }
            }

        case UPDATE_PAYMENT_METHOD:
            const orderPaymentMetohd = [...state[action.category].order];
            /* Total number of months */
            number_of_months = 0;
            for (let i = 0; i < orderPaymentMetohd.length; i++) {
                for (let j = 0; j < orderPaymentMetohd[i].services.length; j++) {
                    number_of_months = number_of_months + orderPaymentMetohd[i].services[j].months;
                }   
            }

            if (action.payment_method === 'credit') {
                if (state.credit && state.credit >= (Math.round((number_of_months * state[action.category].credit_price_per_one) * 100) / 100).toFixed(2)) {
                    return {
                        ...state,
                        [action.category]: {
                            ...state[action.category],
                            order: orderPaymentMetohd,
                            price: (Math.round((number_of_months * state[action.category].credit_price_per_one) * 100) / 100).toFixed(2),
                            number_of_months,
                            used_price_per_one: 'credit_price_per_one',
                        }
                    }
                }
                if (state[action.category].bulk_price_per_one && state[action.category].bulk_price_duration && number_of_months >= state[action.category].bulk_price_duration) {
                    return {
                        ...state,
                        [action.category]: {
                            ...state[action.category],
                            order: orderPaymentMetohd,
                            price: (Math.round((number_of_months * state[action.category].bulk_price_per_one) * 100) / 100).toFixed(2),
                            number_of_months,
                            used_price_per_one: 'bulk_price_per_one',
                        }
                    }
                }
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        order: orderPaymentMetohd,
                        price: (Math.round((number_of_months * state[action.category].price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'price_per_one',
                    }
                }
            }
            else {
                if (state[action.category].bulk_price_per_one && state[action.category].bulk_price_duration && number_of_months >= state[action.category].bulk_price_duration) {
                    return {
                        ...state,
                        [action.category]: {
                            ...state[action.category],
                            order: orderPaymentMetohd,
                            price: (Math.round((number_of_months * state[action.category].bulk_price_per_one) * 100) / 100).toFixed(2),
                            number_of_months,
                            used_price_per_one: 'bulk_price_per_one',
                        }
                    }
                }
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        order: orderPaymentMetohd,
                        price: (Math.round((number_of_months * state[action.category].price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'price_per_one',
                    }
                }
            }
                
        case UPDATE_ORDER:

            /* Select units or browsers order */
            const order = [...state[action.category].order];

            let existingOrderItemIndex = order.length > 0 ? order.findIndex(order => order.unit_id === action.orderItem.unit_id) : -1;
            
            if (action.category === "browsers")
                existingOrderItemIndex = order.length > 0 ? order.findIndex(order => order.browser_id === action.orderItem.browser_id) : -1;
            
            /* Update existing item */
            if (existingOrderItemIndex !== -1) {
                order[existingOrderItemIndex] = action.orderItem;
            }
            /* New order item */
            else {
                /* Add new order item only if not zero value */
                if (parseInt(action.orderItem.services[0].months) !== 0)
                    order.push(action.orderItem);
            }

            /* Total number of months */
            number_of_months = 0;
            for (let i = 0; i < order.length; i++) {
                for (let j = 0; j < order[i].services.length; j++) {
                    number_of_months = number_of_months + order[i].services[j].months;
                }   
            }

            if (state.credit && state.credit >= (Math.round((number_of_months * state[action.category].credit_price_per_one) * 100) / 100).toFixed(2)) {
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        order,
                        price: (Math.round((number_of_months * state[action.category].credit_price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'credit_price_per_one',
                    }
                }
            }
            if (state[action.category].bulk_price_per_one && state[action.category].bulk_price_duration && number_of_months >= state[action.category].bulk_price_duration) {
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        order,
                        price: (Math.round((number_of_months * state[action.category].bulk_price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'bulk_price_per_one',
                    }
                }
            }
            return {
                ...state,
                [action.category]: {
                    ...state[action.category],
                    order,
                    price: (Math.round((number_of_months * state[action.category].price_per_one) * 100) / 100).toFixed(2),
                    number_of_months,
                    used_price_per_one: 'price_per_one',
                }
            }

        case DELETE_ORDER_ITEM:

            /* Select units or browsers order */
            const orderToDeleteFrom = [...state[action.category].order];

            let orderItemIndex = orderToDeleteFrom.length > 0 ? orderToDeleteFrom.findIndex(order => order.unit_id === action.id) : -1;
            
            if (action.category === "browsers")
                orderItemIndex = orderToDeleteFrom.length > 0 ? orderToDeleteFrom.findIndex(order => order.browser_id === action.id) : -1;

            orderToDeleteFrom.splice(orderItemIndex, 1);          
            
            /* Total number of months */
            number_of_months = 0;
            for (let i = 0; i < orderToDeleteFrom.length; i++) {
                for (let j = 0; j < orderToDeleteFrom[i].services.length; j++) {
                    number_of_months = number_of_months + orderToDeleteFrom[i].services[j].months;
                }
            }

            if (state.credit && state.credit >= (Math.round((number_of_months * state[action.category].credit_price_per_one) * 100) / 100).toFixed(2)) {
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        order: orderToDeleteFrom,
                        price: (Math.round((number_of_months * state[action.category].credit_price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'credit_price_per_one',
                    }
                }
            }
            if (state[action.category].bulk_price_per_one && state[action.category].bulk_price_duration && number_of_months >= state[action.category].bulk_price_duration) {
                return {
                    ...state,
                    [action.category]: {
                        ...state[action.category],
                        order: orderToDeleteFrom,
                        price: (Math.round((number_of_months * state[action.category].bulk_price_per_one) * 100) / 100).toFixed(2),
                        number_of_months,
                        used_price_per_one: 'bulk_price_per_one',
                    }
                }
            }
            return {
                ...state,
                [action.category]: {
                    ...state[action.category],
                    order: orderToDeleteFrom,
                    price: (Math.round((number_of_months * state[action.category].price_per_one) * 100) / 100).toFixed(2),
                    number_of_months,
                    used_price_per_one: 'price_per_one',
                }
            }

        case DELETE_ORDER:
            return {
                ...state,
                units: {
                    ...state.units,
                    number_of_months: 0,
                    price: 0,
                    order: []
                },
                browsers: {
                    ...state.browsers,
                    number_of_months: 0,
                    price: 0,
                    order: []
                },
            }
    
        default:
            return state;
    }
}