import React, { useContext } from 'react';
/* Material UI */
import List from '@mui/material/List';
/* Global state */
import { UserContext } from '../../../../Context/UserContext';
/* Components */
import DrawerListItem from '../DrawerListItem/DrawerListItem';
import DrawerCollapsibleListItem from '../DrawerCollapsibleListItem/DrawerCollapsibleListItem';
/* Utils */
import { navigationItems } from '../../../../Config/navigationItems';

const newsFeedItem = {
    title: "navigation.title.feed",
    icon: "feed",
    link: "/feed",
    component: "Feed",
    acl: "ACL_CUSTOMER"
};

const DrawerList = () => {

    const { user } = useContext(UserContext);

    return (
        <List>

            {navigationItems.filter(item => {
                if (user) {
                    if (item.link === "/services" && user.type === "customer" && user.config.hide_all === true)
                        return false; 
                    if (item.link === "/services" && Object.entries(user.service_price).length !== 0) {
                        if (user.service_price["Browser"].price === 0 && user.service_price["Remote management"].price === 0)
                            return false; 
                    }
                }
                return true;
            }).map((item, index) => item.children
                ? <DrawerCollapsibleListItem key={index} item={item} />
                : <DrawerListItem key={index} item={item} />
            )}

            {/* News Feed */}
            {(user.type === "customer" && user.resseler_id !== null) ? "" : <DrawerListItem item={newsFeedItem} />}
            
        </List>
    );
}

export default React.memo(DrawerList);
