import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import 'moment-timezone';
/* Material UI */
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
/* Global state */
import { UserContext } from '../../../Context/UserContext';
/* Components */
import UnitRowButtons from './UnitRowButtons';
/* Utils */
import { getTimeInTimezone } from '../../../Utils/PWRModeHandler';

/* Styles */
const styles = {
    listItemText: {
        margin: '8px 0',
        width: 'max-content',
        '& > .MuiTypography-body1': {
            lineHeight: 1.25
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        maxWidth: '36ch',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden !important',
        '@media (max-width:1800px)': {
            maxWidth: '30ch',
        },
        '@media (max-width:1600px)': {
            maxWidth: '26ch',
        },
        '@media (max-width:990px)': {
            fontSize: 17,
        },
        '@media (max-width: 480px)': {
            fontSize: 16
        }
    },
    typography: {
        color: 'black',
        fontSize: 15,
        '@media (max-width: 480px)': {
            fontSize: 13
        }
    }
};

const UnitRowHeader = ({ unit, expired }) => {
    
    const { t } = useTranslation();

    const { user } = useContext(UserContext);

    /**
     * Unit state - offline, online, sleeping
     */
    let unitState = "offline";
    if (!unit.version_sw || parseInt(unit.version_sw.charAt(0)) < 3) {
        if (unit.online !== undefined && unit.online !== null && (unit.online.online === true || unit.online === true))
            unitState = "online";
        if (unit.online !== undefined && unit.online !== null && unit.online.online === false && unit.pwr_mode === "powersave")
            unitState = "sleeping";
    }
    else {
        if (unit.unit_state === "unknown")
            unitState = "offline"
        else
            unitState = unit.unit_state
    }

    return (
        <Grid item xs={6}>
            
            {/* Name, serial number and button(s) */}
            <ListItemText 
                sx={styles.listItemText}
                primary={
                    <div style={{display: 'flex', alignItems: "center"}}>
                        <Typography sx={styles.title} color="primary">
                            {unit.name.toUpperCase()}
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography csx={styles.typography} color="secondary">
                            ({unit.serial_number})
                        </Typography>
                        &nbsp;&nbsp;
                        <UnitRowButtons unit={unit} expired={expired} />
                    </div>
                }           
            />

            {/* Last update, mode and state */}
            <ListItemText
                sx={styles.listItemText} 
                primary={
                    <div style={{display: 'flex', alignItems: "flex-end"}}>
                        <Typography sx={styles.typography} color="secondary">
                            {t('dashboard.unit.label.lastUpdate')}:&nbsp;
                                <strong>
                                    {unit.version_hw >= "3.0" ? 
                                    <Moment unix format="DD-MM-YYYY HH:mm" tz={typeof unit.timezone === "undefined" || unit.timezone == null ? "" : unit.timezone.toString()}>
                                        {unit.timestamp}
                                    </Moment>
                                    :
                                    getTimeInTimezone(unit.timestamp, unit.timezone) === "" ? t('dashboard.unit.label.notAvailable') : getTimeInTimezone(unit.timestamp, unit.timezone)
                                    }
                                </strong>&nbsp;
                                - {t('dashboard.unit.tooltip.' + (unit.pwr_mode ? unit.pwr_mode : "normal")) + ' ' + t('dashboard.unit.tooltip.mode')}
                                {(!expired || user.type === "admin") && (unit.version_hw && parseInt(unit.version_hw.charAt(0)) >= 3) ?
                                    <strong style={{fontWeight: 600}, 
                                        unitState === "sleeping" ? {color: "rgba(245, 171, 53, 1)"} : 
                                        unitState === "online" ? {color: "rgba(131, 206, 34, 1)"} : {color: "rgba(240, 52, 52, 1)"}}
                                    > ({t('dashboard.unit.tooltip.' + unitState)})</strong>
                                    : null
                                }
                        </Typography>
                    </div>
                    
                }           
            />
        </Grid>
    );
}

export default UnitRowHeader;