import React from 'react';
/* Material UI */
import Grid from '@mui/material/Grid';
/* Components */
import UnitCard from './UnitCard/UnitCard';


const DashboardGrid = ({ units }) => {
    
    //console.log("[DashboardGrid]");

    if (units.length > 0) {
        return (
            <Grid container>
                {units.map(unit => {
                    return <UnitCard key={unit.serial_number} unit={unit} />
                })}
            </Grid>
        );
    }
    else 
        return null;
    
}

export default DashboardGrid;