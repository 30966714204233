import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* Material UI */
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
/* Components */
import FeedItemModal from '../../modals/FeedItemModal/FeedItemModal';
import CustomDialog from '../../lib/CustomDialog/CustomDialog';
import ACLWrapper from '../../../Containers/ACLWrapper';
/* Global state */
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { FeedActionsContext } from '../../../Context/FeedContext';
import { DELETE_FEED_ITEM } from '../../../Context/reducers/feedReducer';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';
/* Styles */
import { styles } from './FeedItem.styles';

const icons = {
  "info": <InfoIcon sx={styles.icon} color="success" />,
  "warning": <WarningIcon sx={styles.icon} color="warning" />,
  "outage": <PowerOffIcon sx={styles.icon} color="error" />,
}

const FeedItem = ({ ...feed }) => {
  
  const [editFeedItemModalOpen, setEditFeedItemModalOpen] = useState(false);
  const [removeFeedItemDialogOpen, setRemoveFeedItemDialogOpen] = useState(false);
  
  const { t } = useTranslation();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const dispatchFeed = useContext(FeedActionsContext);

  /**
   * Delete feed item
   * @param {*} event 
   */
  const handleDelete = (event) => {

    event.preventDefault();
    
    const onSuccess = (response) => {
      dispatchFeed({
          type: DELETE_FEED_ITEM,
          id: feed.id
      });
      setSnack({ message: t('snackbar.message.feedItemRemoved'), type: "info", open: true});
      setRemoveFeedItemDialogOpen(false);
    }
    const onError = (error) => {
      console.log(`error in delete /news/feed/${feed.id}`)
      if (error.status === 401 || error.status === 403) {
          localStorage.removeItem("token");
          history.push({ pathname: '/login', state: { showSnack: true }});
      }
    }
    
    BackendRequest("delete", `/news/feed/${feed.id}`, null, onSuccess, onError);
  };

  
  return (
    <Box sx={{...styles.item, ...styles[feed.type.concat("_item")]}}>
      <Box sx={styles.itemHeaderRow}>
        <Box sx={styles.itemHeaderItemInfo}>
          <Icon>{icons[feed.type]}</Icon>
          <Typography variant="h6" sx={styles.itemHeaderTitle}>{feed.title}</Typography>
          <Chip label={feed.publish_date} size="small" icon={<CalendarTodayIcon />} sx={styles.chip} />
        </Box>
        <ACLWrapper requiredPermission={'ACL_ADMIN'}>
          <Box sx={styles.toolbar}>
            <Tooltip title={t('customers.overview.tooltip.edit')}>
                <IconButton 
                    sx={styles.iconButton} 
                    aria-label="edit"
                    onClick={() => setEditFeedItemModalOpen(!editFeedItemModalOpen)}
                >
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title={t('customers.overview.tooltip.delete')}>
                <IconButton 
                    sx={styles.iconButton} 
                    aria-label="delete"
                    onClick={() => setRemoveFeedItemDialogOpen(!removeFeedItemDialogOpen)}
                >
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
          </Box>
        </ACLWrapper>
      </Box>
      <Divider sx={styles.divider} />
      {feed.content.split("\n").map(content => <Typography variant="body1" sx={styles.content}>{content || '\u00A0'}</Typography>)}

      {editFeedItemModalOpen &&
        <FeedItemModal
          feed={feed}
          handleClose={() => setEditFeedItemModalOpen(false)}
          open={editFeedItemModalOpen}
        />
      }
      {removeFeedItemDialogOpen &&
        <CustomDialog
          buttons={[
            {
              action: handleDelete,
              label: t('button.remove')
            },
          ]}
          content={t('dialog.content.doYouReallyWantToRemoveFeedItem')}
          handleClose={() => setRemoveFeedItemDialogOpen(false)}
          open={removeFeedItemDialogOpen}
          title={t(
            'dialog.title.removeFeedItem',
            { feedItem: feed.title }
          )}
        />
      }
    </Box>
  )
}

export default FeedItem;