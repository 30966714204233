import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
/* Material UI */
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
/* Global state */
import { DrawerContext } from '../../Context/DrawerContext';
/* Components */
import DrawerList from './_components/DrawerList/DrawerList';
import DrawerFooter from './_components/DrawerFooter/DrawerFooter';
/* Styles */
import { styles } from './Drawer.styles';


const CustomDrawer = () => {

    const drawerBreakpoint = useMediaQuery({ query: '(min-width: 991px)' });
    const { drawerState, toggleDrawer } = useContext(DrawerContext);

    return (
        <Drawer
            open={drawerState}
            onClose={() => toggleDrawer(false)}
            sx={styles.drawer}
            variant={drawerBreakpoint ? 'permanent' : 'temporary'}
        >
            <Toolbar />
            <Box sx={styles.drawerContainer}>
                <DrawerList />
                <DrawerFooter />
            </Box>
        </Drawer>
    )
}

export default React.memo(CustomDrawer);