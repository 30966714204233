import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import moment from 'moment'
/* Material UI */
import { ThemeProvider } from '@mui/material/styles'
/* Global state */
import { AuthenticationContext } from '../Context/AuthenticationContext';
import OrderProvider from '../Context/OrderContext';
import WebSocketProvider from '../Context/WebSocketContext';
import SnackbarProvider from '../Context/SnackbarContext';
/* Components */
import AppContainer from './AppContainer';
import ProtectedLogin from '../Utils/ProtectedLogin';
import { Login } from '../Scenes';
/* Utils */
import useUserFetch from '../Utils/useUserFetch';
import FeedProvider from '../Context/FeedContext';

const App = () => {

  moment.tz.setDefault('Etc/UTC');
  
  const [auth, setAuth] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const { user, theme } = useUserFetch(auth, setAuth, setPermissions);

  //console.log("[App]", auth, permissions, user, theme);
  
  if (auth !== null) {
    return (  
      <AuthenticationContext.Provider value={{auth, setAuth, permissions}}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <FeedProvider>
              <WebSocketProvider>
                
                  {auth ? 
                    <OrderProvider>
                      {user ?
                        <AppContainer
                          userType={user.type}
                          userConfig={user.config}
                          userServicePrice={user.service_price}
                        /> : null
                      }
                    </OrderProvider>
                    : 
                    <Switch>
                      <ProtectedLogin exact path="/login" component={Login} auth={auth} />
                      <Redirect to="/login" />
                    </Switch>
                  }
                
              </WebSocketProvider>
            </FeedProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </AuthenticationContext.Provider>
    );
  }
  else {
    return null;
  }
}

export default App;
