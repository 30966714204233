import React from 'react';
/* Material UI */
import Box from '@mui/material/Box';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
/* Components */
import CustomFileInput from '../components/lib/CustomFileInput/CustomFileInput';
import CustomButton from '../components/lib/CustomButton/CustomButton';

const styles = {
    textbox: {
        display: 'flex',
        flexDirection: 'column'
    }
}

const Contact = () => {
  return (
    <Box>
        <Typography variant="h6">In case of any problem or question, please contact David Gargulák via this form.</Typography>
        <br/>
        <Box sx={styles.textbox}>
            <FormLabel>YOUR QUESTION</FormLabel>
            <TextareaAutosize 
                placeholder=""
                minRows={3}
                style={{minWidth: '200px', maxWidth: '800px'}}
            />
        </Box>
        <br/>
        <CustomFileInput />
        <CustomButton>Send</CustomButton>
    </Box>
  )
}

export default Contact;