import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
/* Material UI */
import { Box, Divider, Grid, MenuItem, Typography } from '@mui/material';
/* Components */
import { countries } from '../../../Config/countries';
import ACLWrapper from '../../../Containers/ACLWrapper';
import CustomButton from '../../lib/CustomButton/CustomButton';
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../../lib/CustomSelectField/CustomSelectField';
import TopUpCreditModal from '../../modals/TopUpCreditModal/TopUpCreditModal';
/* Global state */
import { UserContext } from '../../../Context/UserContext';
import { SnackbarContext } from '../../../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../../../Utils/BackendRequest';

/* Styles */
const styles = {
    smallerDivider: {
        marginTop: '8px',
        marginBottom: '8px',
        height: '2px'
    },
};

const stripePromise = loadStripe("pk_live_gsnXMJ0Nv8h8QlUh9jDXt1uo00zf2paHpp");
// const stripePromise = loadStripe("pk_test_HFg1FnXLTeRJ1eb7yEAXD6b700W2aWGyrV");

const UserForm = ({ customer, error, handleChange, createNewPassword, setCreateNewPassword, newCustomer = null }) => {

    const history = useHistory();
    const { t } = useTranslation();

    const [resellers, setResellers] = useState(null);
    const [isTopUpCreditModalOpen, setIsTopUpCreditModalOpen] = useState(false);
    const [credit, setCredit] = useState(customer.credit ?? 0);
    
    const { user } = useContext(UserContext);
    const { setSnack } = useContext(SnackbarContext);

    const handleCreditTopUp = (amount) => {
        const onSuccess = (response) => {
            setCredit((prevCredit) => prevCredit + amount);
            setSnack({ message: t('snackbar.message.creditHasBeenToppedUp'), type: "info", open: true});
            setIsTopUpCreditModalOpen(false);
        }
        const onError = (error) => {
            console.log('error in /pays/credit-topup');
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/pays/credit-topup", { value: amount, user_id: customer.id }, onSuccess, onError);
    }

    /**
     * Get all resellers - only for admin
     */
    const getAllResellers = () => {
        
        const onSuccess = (response) => {
            setResellers(response.data);
        }
        const onError = (error) => {
            console.log('error in /user/admin/resellers', error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("get", "/user/admin/resellers", null, onSuccess, onError);
    }

    useEffect(() => {
        if (user.type === "admin") {
            getAllResellers();
        }
    }, [])

    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={6}>
                {/* First name */}
                <CustomTextField 
                    name="first_name"
                    error={error === "missingFirstName"}
                    value={customer.first_name}
                    handleChange={handleChange}
                    label={t('customers.settings.label.firstName').toUpperCase()}
                    required
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {/* Last name */}
                <CustomTextField 
                    name="last_name"
                    value={customer.last_name}
                    handleChange={handleChange}
                    label={t('customers.settings.label.lastName').toUpperCase()}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {/* Email */}
                <CustomTextField 
                    name="email"
                    error={error === "invalidEmailAddress"}
                    value={customer.email}
                    handleChange={handleChange}
                    label={"EMAIL"}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {/* Phone */}
                <CustomTextField 
                    name="phone"
                    error={error === "invalidPhoneNumber"}
                    value={customer.phone}
                    handleChange={handleChange}
                    label={t('customers.settings.label.phone').toUpperCase()}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {/* VAT ID */}
                <CustomTextField 
                    name="vat_identificator"
                    value={customer.vat_identificator}
                    handleChange={handleChange}
                    label={t('customers.settings.label.vatId').toUpperCase()}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {/* Country */}
                <CustomSelectField
                    name="vat_state"
                    value={customer.vat_state}
                    handleChange={handleChange}
                    label={t('customers.settings.label.country').toUpperCase()}
                >
                    {countries.map((country, index) => {
                        return <MenuItem key={index} value={country.label}>{country.label}</MenuItem>
                    })}
                    <MenuItem value={"NOT SET"}></MenuItem>
                </CustomSelectField>
            </Grid>

            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <Grid item xs={12} sm={6}>
                    {/* User type */}
                    <CustomSelectField
                        name="type"
                        value={customer.type}
                        handleChange={handleChange}
                        label={t('customers.settings.label.userType').toUpperCase()}
                    >
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"reseller"}>Reseller</MenuItem>
                        <MenuItem value={"resellerAdmin"}>Reseller admin</MenuItem>
                        <MenuItem value={"customer"}>Customer</MenuItem>
                        <MenuItem value={"demo"}>Demo</MenuItem>
                    </CustomSelectField>
                </Grid>
            </ACLWrapper>

            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <Grid item xs={12} sm={6}>
                    {/* RESELLER ID */}
                    {customer.type === "customer" && 
                        <CustomSelectField
                            name="resseler_id"
                            value={customer.resseler_id}
                            handleChange={handleChange}
                            label="RESELLER"
                        >
                            {resellers && resellers.map((reseller, index) => {
                                return <MenuItem key={index} value={reseller.id}>
                                    {reseller.first_name + " " + reseller.last_name + " (ID: " + reseller.id +")"}
                                </MenuItem>
                            })}
                            <MenuItem value={null}>
                                Select reseller
                            </MenuItem>
                        </CustomSelectField>
                    }
                </Grid>
            </ACLWrapper>

            <Grid item xs={12} md={6}>
                {/* Login */}
                <CustomTextField 
                    name="username"
                    error={error === "missingUsername"}
                    value={customer.username}
                    handleChange={handleChange}
                    label={"LOGIN"}
                    required
                />
            </Grid>

            {/* Only for creating new customers */}
            {newCustomer ? 
                <Grid item xs={12} md={6}>
                {/* Password */}
                {customer ? 
                    <CustomTextField 
                        name="password"
                        value={customer.password}
                        handleChange={handleChange}
                        label={t('customers.settings.label.password').toUpperCase()} 
                        type="password"
                    /> : null
                }
                </Grid> : null
            }

            {!newCustomer ?
                <>
                    <Grid item xs={12} style={{margin: '8px 0 16px'}}>
                        <CustomButton color="primary" onClick={setCreateNewPassword}>
                            CREATE NEW PASSWORD
                        </CustomButton>
                    </Grid>
                </> : null
            }

            {!newCustomer && createNewPassword &&
                <>
                    <Grid item xs={12} md={6}>
                        <CustomTextField 
                            name="newPassword"
                            value={customer.newPassword}
                            handleChange={handleChange}
                            label={t('customers.settings.label.newPassword').toUpperCase()}  
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField 
                            name="newPasswordConfirmation"
                            value={customer.newPasswordConfirmation}
                            handleChange={handleChange}
                            label={t('customers.settings.label.newPasswordConfirmation').toUpperCase()} 
                            type="password"
                        />
                    </Grid>
                </>
            }

            {!newCustomer && customer.service_price['Remote management'].price !== 0 && customer.service_price['Browser'].price !== 0 && (
                <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                    <Grid item xs={12} sx={{ mt: '16px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
                            <Typography variant="subtitle1" color="secondary"  sx={{ fontSize: '20px', fontWeight: 600, lineHeight: 1 }}>
                                Credit
                            </Typography>
                            <CustomButton color="primary" onClick={() => setIsTopUpCreditModalOpen(true)} style={{textTransform: 'none', padding: '2px 12px'}}>
                                + Top up credit
                            </CustomButton>
                        </Box>
                        <Divider sx={styles.smallerDivider} />
                        <Typography variant="subtitle1">
                            Current credit:&nbsp;<strong>{(Math.round(credit * 100) / 100).toFixed(2)}&nbsp;€</strong>
                        </Typography>
                    </Grid>
                    {isTopUpCreditModalOpen && (
                        <Elements stripe={stripePromise}>
                            <TopUpCreditModal
                                open={isTopUpCreditModalOpen}
                                handleClose={() => setIsTopUpCreditModalOpen(false)}
                                onSuccess={handleCreditTopUp}
                                onError={() => console.log('error')}
                            />
                        </Elements>
                        
                    )}
                </ACLWrapper>
            )}
        </Grid>
    );
}

export default UserForm;