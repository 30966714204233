export const styles = {
    dialog: {
        '& .MuiPaper-root': {
            minWidth: '500px',
            maxWidth: '700px',
            '@media (max-width:992px)': {
                minWidth: 'initial',
                maxWidth: '90vw'
            }
        },
    },
    dialogActions: {
        display: 'flex',
        '&.MuiDialogActions-root>:not(:first-of-type)': {
            '@media (max-width: 767px)': {
                margin: '0 !important',
            }
        },
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            gap: '4px'
        }
    }
};