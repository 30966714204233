import React, { useContext } from 'react';
/* Material UI */
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/* Global state */
import { OrderContext } from '../../../Context/OrderContext';

/* Styles */
const styles = {
    browserRow: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        padding: '0 12px'
    },
    browserName: {
        fontWeight: 600,
        paddingRight: '6px'
    },
    divider: {
        marginTop: '8px',
        marginBottom: '24px',
        height: '2px'
    },
};

const BrowsersSummaryRow = ({ orderItem }) => {

    const currentOrderState = useContext(OrderContext);

    return (
        <>
            <Box sx={styles.browserRow}>
                <Typography sx={styles.browserName} variant="h6" color="primary">
                    {orderItem.browser_name.toUpperCase()}
                </Typography>
                <Typography variant="h6">
                    {(Math.round((orderItem.services[0].months * currentOrderState.browsers[currentOrderState.browsers.used_price_per_one]) * 100) / 100).toFixed(2)}&nbsp;€
                </Typography>
            </Box>
            <ul>
                <li>{orderItem.services[0].service_name + ": " + orderItem.services[0].months + " months"}</li>
            </ul>
            <Divider sx={styles.divider} />     
        </>     
    );

}

export default BrowsersSummaryRow;